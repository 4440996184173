import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.scss'
import reportWebVitals from './reportWebVitals'

import { RouterProvider } from 'react-router-dom'
import routers from './routers'
import { App, ConfigProvider } from 'antd'
import zhCN from 'antd/es/locale/zh_CN'
import store from './store'
import { Provider } from 'react-redux'
import 'dayjs/locale/zh-cn';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
)

root.render(
  <React.StrictMode>
    <ConfigProvider componentSize="middle" locale={ zhCN } theme={{ cssVar: true }}>
      <Provider store={ store }>
        <App>
          <RouterProvider router={ routers }/>
        </App>
      </Provider>
    </ConfigProvider>
  </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
