import { Divider } from 'antd'

import Styles from './index.module.scss'

function PageFooter ({ theme = 'light' }) {

  const getClassName = (type: string) => {
    if (type === 'light') {
      return [Styles.text_theme, Styles.light, 'mt-2'].join(' ')
    } else {
      return [Styles.text_theme, Styles.block, 'mt-2'].join(' ')
    }
  }

  return (
    <div className={'text-center'}>
      <div className={ getClassName(theme) }>
        <span>贵州省版权中心</span>
        <Divider type="vertical" style={{ borderColor: 'rgb(98, 98, 98)' }}/>
        <span>中移数藏</span>
        <Divider type="vertical" style={{ borderColor: 'rgb(98, 98, 98)' }}/>
        <span>中国移动贵州创新研究院</span>
        <Divider type="vertical" style={{ borderColor: 'rgb(98, 98, 98)' }}/>
        <span>在铭知识产权服务</span>
      </div>
      <div className={ Styles.introduce }>BSN联盟链</div>
      <div className={ getClassName(theme) }>深圳市树蛙软件开发有限公司</div>
      <div className={ getClassName(theme) }>创巢文化技术支持 @2020-2024版权所有</div>
    </div>
  )
}

export default PageFooter
