export type FileType = 'pdf' | 'zip' | 'excel' | 'docx'
export type FileTypeMap = 'zip' | '7z' | 'rar' | 'pdf' | 'excel' | 'docx' | 'png' | 'jpg' | 'gif' | 'jpeg'
export type FileUrlDataType = {
  url: any;
  name: any;
  status: string;
  uid: string;
}

const dateRegx = /^\d{4}\/\d{1,2}\/\d{1,2}\//g

const fileTypeMap = {
  'zip': 'application/zip',
  '7z': 'application/x-7z-compressed',
  'rar': 'application/x-rar-compressed',
  'pdf': 'application/pdf;charset=utf-8',
  'excel': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; application/octet-stream',
  'docx': 'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'png': 'image/png',
  'jpg': 'image/jpeg',
  'gif': 'image/gif',
  'jpeg': 'image/jpeg'
}

export const base64ToArrayBuffer = (base64: string) => {
  const binaryString = window.atob(base64)
  const binaryLen = binaryString.length
  const bytes = new Uint8Array(binaryLen)
  for (let i = 0; i < binaryLen; i++) {
    let ascii = binaryString.charCodeAt(i)
    bytes[i] = ascii
  }
  return bytes
}

export const getUid = () => {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
    const r = Math.random() * 16 | 0
    const v = c === 'x' ? r : (r & 0x3 | 0x8)
    return v.toString(16)
  })
}

export const strEncryption = (str: string, startIndex: number, length: number) => {
  return str?.replace(str.slice(startIndex, startIndex + length), '*'.repeat(length))
}

const getBlobType = (fileType: FileTypeMap) => {
  return fileTypeMap[fileType]
}

export const downloadFile = (data: any, fileName: string, fileType: FileType) => {
  let blob: Blob = new Blob([data], {
    type: getBlobType(fileType)
  })
  let objectUrl = URL.createObjectURL(blob)
  let a = document.createElement('a')
  a.href = objectUrl
  a.download = `${ fileName }.${ fileType }`
  a.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }))
  // @ts-ignore
  window.URL.revokeObjectURL(blob)
}

export const downloadBlob = (blob: any, fileName: string, fileType: any) => {
  let objectUrl = URL.createObjectURL(blob)
  let a = document.createElement('a')
  a.href = objectUrl
  a.download = `${ fileName }.${ fileType }`
  a.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }))
  // @ts-ignore
  window.URL.revokeObjectURL(blob)
}

export const getFileFormat = (fileMimeList: string[]) => {
  return fileMimeList?.map((item: string) => {
    return item.replace('image/', '')
      .replace('application/', '')
      .replace('x-', '')
      .replace('-compressed', '')
  }).join('/')
}

export const fileNameFormat = (fileItemList: FileUrlDataType[]) => {
  return fileItemList.map(item => {
    const str = item.url.replace(dateRegx, '')
    const lastDotIndex = str.lastIndexOf('.')
    const fileType = str.slice(lastDotIndex)
    const lastLineIndex = str.lastIndexOf('-')
    return {
      ...item,
      name: str.slice(0, lastLineIndex) + fileType
    }
  })
}

export const fileNameStrFormat = (fileItemList: string[]) => {
  return fileItemList?.map(path => {
    if (path) {
      const str = path.replace(dateRegx, '')
      const lastDotIndex = str.lastIndexOf('.')
      const fileType = str.slice(lastDotIndex)
      const lastLineIndex = str.lastIndexOf('-')
      return {
        value: path,
        label: str.slice(0, lastLineIndex) + fileType
      }
    } else {
      return {
        value: '',
        label: ''
      }
    }
  })
}

export const throttle = (func: Function, delay?: number) => {
  delay = delay || 1000
  let timer: any = null
  return (args: any) => {
    if (!timer) {
      timer = setTimeout(() => {
        func.call(this, args)
        timer = null
      }, delay)
    }
  }
}
