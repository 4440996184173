import { Layout } from 'antd'
import Sider from 'antd/es/layout/Sider'
import { Outlet } from 'react-router-dom'
import { ReactNode, useEffect, useState } from 'react'
import { CopyrightOutlined, SolutionOutlined } from '@ant-design/icons'

import router from '../../routers'

import Styles from './index.module.scss'
import { ReactComponent as Coupon } from '../../assets/icons/coupon.svg'
import { ReactComponent as IdentitySuccess } from '../../assets/icons/identity-success.svg'
import { ReactComponent as UserList } from '../../assets/icons/user-list.svg'
import { ReactComponent as Certification } from '../../assets/icons/certification.svg'
import { ReactComponent as Promotion } from '../../assets/icons/promotion.svg'
import AppSelfCenterPageContainer from '../../components/AppSelfCenterPageContainer'
import AppIcon from '../../components/AppIcon'
import { useSelector } from 'react-redux'
import AppUserAvatar from '../../components/AppUserAvatar'

type MenuItem = {
  label: ReactNode | string
  icon: ReactNode | string
  path: string
}

function SelfCenter () {
  const userInfo = useSelector((state: any) => state.userInfo.userInfo)
  const [pathName, setPathName] = useState<string>(window.location.pathname.replace(`/self-center/`, ''))

  useEffect(() => {
    router.subscribe((event) => {
      setPathName(event.location.pathname.replace(`/self-center/`, ''))
    })
  }, [])

  const menuList = [
    {
      label: '个人资料设置',
      icon: <SolutionOutlined/>,
      path: 'self-info-setting'
    },
    {
      label: '版权登记管理',
      icon: <CopyrightOutlined/>,
      path: 'copyright-registration-management'
    },
    // {
    //   label: '数字藏品管理',
    //   icon: <AppIcon svg={ ElectricCollection }/>,
    //   path: 'electric-collection-management'
    // },
    {
      label: '实名认证',
      icon: <AppIcon svg={ Certification }/>,
      path: 'certification'
    },
    {
      label: '作者管理',
      icon: <AppIcon svg={ UserList }/>,
      path: 'identity-management'
    },
    {
      label: '优惠券管理',
      icon: <AppIcon svg={ Coupon }/>,
      path: 'coupon-management'
    },
    {
      label: '推广',
      icon: <AppIcon svg={ Promotion }/>,
      path: 'promotion'
    }
  ]

  const getMenuItemClass = (active: boolean) => {
    if (active) {
      return [Styles.menu_item, Styles.is_active].join(' ')
    } else {
      return Styles.menu_item
    }
  }

  const navigatorTo = (item: MenuItem) => {
    router.navigate(`/self-center/${ item.path }`)
  }

  const generateMenuList = (list: MenuItem[]) => {
    return list?.map(item => {
      return (
        <div key={ item.path } className={ getMenuItemClass(item.path === pathName) } onClick={ navigatorTo.bind(this, item) }>
          <div>{ item.icon }</div>
          <div className={ 'ml-2' }>{ item.label }</div>
        </div>
      )
    })
  }

  return (<AppSelfCenterPageContainer>
    <div className={ Styles.app_container }>
      <div
        className={'text-white d-flex align-items-center justify-content-center'}
        style={{ fontSize: 24, margin: '30px 0 70px' }}
      >个人中心</div>
      <div className={ 'app-card' } style={{
        margin: '0 auto',
        width: 'calc(100vw - 200px)'
      }}>
        <Layout style={ { minHeight: 600 } }>
          <Sider style={ { width: 220, background: '#F6F9FE', paddingInline: 30 } }>
            <div className={ Styles.user_info_wrap }>
              <div className={ Styles.user_logo }>
                <AppUserAvatar></AppUserAvatar>
              </div>
              <div className={ 'mt-2 font-weight-bold' }>{ userInfo.userName }</div>
            </div>
            <div>
              { generateMenuList(menuList) }
            </div>
          </Sider>
          <Layout style={ { padding: '20px 36px', background: '#ffffff' } }>
            <Outlet/>
          </Layout>
        </Layout>
      </div>
    </div>
  </AppSelfCenterPageContainer>)
}

export default SelfCenter
