import { Tabs } from 'antd'
import IdentityList from './IdentityList'

function IdentityManagement () {
  const tabItems = [
    {
      key: 'personal',
      label: '个人作者',
      children: <IdentityList authType={'1'}></IdentityList>
    },
    {
      key: 'company',
      label: '企业作者',
      children: <IdentityList authType={'2'}></IdentityList>
    }
  ]

  return (<div>
    <Tabs items={tabItems}></Tabs>
  </div>)
}

export default IdentityManagement
