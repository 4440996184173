import { Spin } from 'antd'
import { ReactNode } from 'react'

type Props = {
  pageStatus: string
  text?: string
  height?: string | number
  children?: ReactNode
}

function PageStatus ({ pageStatus, height, text, children }: Props) {
  const generatePageStatus = (status: string):ReactNode => {
    let content
    switch (status) {
      case '':
      case 'loading':
        content = (<>
          <Spin spinning={ status === 'loading' } tip={ text }>
            { children }
          </Spin>
        </>)
        break
      case 'loading_only':
        content = (<>
          <Spin spinning={ true } tip={ text }>
            <div style={ { height: height } }></div>
          </Spin>
        </>)
        break
      default:
        content = children
    }

    return content
  }

  return (
    <div>
      { generatePageStatus(pageStatus) }
    </div>
  )
}

export default PageStatus
