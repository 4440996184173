import { getNationList } from '../../common/request'
import { ResponseCode } from '../../common/response-code'
import { updateNationInfo } from '../../store/slice/nation-info-slice'
import { Modal } from 'antd'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

function NationList () {
  const dispatch = useDispatch()

  useEffect(() => {
    getNationListAction()
  }, [])

  const getNationListAction = () => {
    getNationList().then((res: any) => {
      if (res.code === ResponseCode.success) {
        dispatch(updateNationInfo({
          nationList: res.data.map((item: any) => {
            return {
              value: item.code,
              label: item.name
            }
          })
        }))
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch((err: any) => {
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询民族字典数据失败'
      })
    })
  }

  return (
    <div></div>
  )
}

export default NationList
