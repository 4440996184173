import { Form, Input, Modal, notification, Spin } from 'antd'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { updateEmail } from '../../common/request'
import { ResponseCode } from '../../common/response-code'
import EventBus from '../../plugins/events'
import { useSelector } from 'react-redux'

export interface UserEmailModalModalRefProps {
  showModal: () => void
}

type Props = {}

const UpdateUserEmailModal = forwardRef<UserEmailModalModalRefProps>((props: Props, ref) => {
  const [form] = Form.useForm()
  const userInfo = useSelector((state: any) => state.userInfo.userInfo)
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      form.setFieldsValue({
        mail: userInfo.mail
      })
    }
  }, [modalVisible])

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const onOk = () => {
    form.validateFields().then(fields => {
      setLoading(true)
      updateEmail({
        mail: fields.mail
      }).then((res: any) => {
        setLoading(false)
        if (res.code === ResponseCode.success) {
          notification.open({
            type: 'success',
            message: '操作成功',
            description: '邮箱修改成功'
          })
          hideModal()
          EventBus.emit('updateUserInfo')
        }
      }).catch((err: any) => {
        setLoading(false)
        console.error(err)
        Modal.error({
          title: '错误',
          content: '网络错误，修改邮箱失败'
        })
      })
    }).catch(err => {
      console.error(err)
    })
  }

  return (<Modal
    title={ '修改邮箱' }
    open={ modalVisible }
    onCancel={ hideModal }
    onOk={ onOk }
    confirmLoading={ loading }
  >
    <Form
      className={ 'my-4' }
      form={ form }
      labelCol={ {
        span: 6
      } }
      wrapperCol={ {
        span: 14
      } }
    >
      <Form.Item
        label={ '邮箱' }
        name={ 'mail' }
        rules={ [{ required: true }] }
      >
        <Input placeholder={ '输入新邮箱' }></Input>
      </Form.Item>
    </Form>

    <Spin spinning={ loading } tip={ '正在保存···' } fullscreen/>
  </Modal>)
})

export default UpdateUserEmailModal
