import React, { ReactNode, useRef } from 'react'
import { Col, Row, Button } from 'antd'
import { ReactComponent as User } from '../../assets/icons/user.svg'

import Styles from './index.module.scss'
import { CloseOutlined, PlusCircleFilled } from '@ant-design/icons'
import SelectUserModal, { ModalRefProps } from '../SelectUserModal'
import AppIcon from '../AppIcon'

type UserItem = {
  name: string
  idCard: string | number
  id: string | number
  companyName: string
  busLicNum: string
}

type Props = {
  value?: UserItem[]
  addText?: string
  modalTitle: string
  children?: ReactNode
  onChange?: any
  emptyText?: string
  authType: number
}

const SelectUserFormItem = ({ value = [], authType, modalTitle, addText, emptyText, children, onChange }: Props) => {
  const selectUserModalRef = useRef<ModalRefProps>(null)

  const deleteUserItem = (user: any) => {
    onChange?.(value.filter((userItem) => {
      return authType === 1 ? userItem.id !== user.id : userItem.busLicNum !== user.busLicNum
    }))
  }

  const generateUserList = (userList: UserItem[] | undefined): ReactNode => {
    if (userList && userList.length > 0) {
      return userList.map(user => {
        return (
          <div key={user.id} className={ Styles.app_user_list_item }>
            <div className={ 'd-flex align-items-center' }>
              <AppIcon className={ 'mr-1' } svg={ User }></AppIcon>
              <div>{ authType === 1 ? user.name : user.companyName }</div>
            </div>
            <Button
              type={ 'text' }
              danger
              icon={ <CloseOutlined/> }
              onClick={ deleteUserItem.bind(this, user) }
            ></Button>
          </div>
        )
      })
    } else {
      return <div className={ [Styles.app_user_list_item, 'text-center'].join(' ') }>{ emptyText || '暂无数据' }</div>
    }
  }

  const showUserModal = () => {
    selectUserModalRef.current?.showModal(value)
  }

  const handleSave = (selectedItems: any[]) => {
    onChange?.(selectedItems)
  }

  return (<>
    <Row gutter={ 30 }>
      <Col span={ 12 }>
        <div>
          <div
            className={ Styles.app_add_user_button }
            onClick={ showUserModal }>
            <PlusCircleFilled className={ Styles.app_add_user_button_icon }/>
            <span>{ addText }</span>
          </div>
          <div className={ Styles.app_user_list_wrap }>
            { generateUserList(value) }
          </div>
        </div>
      </Col>
      <Col span={ 12 }>
        { children }
      </Col>
    </Row>

    <SelectUserModal
      ref={ selectUserModalRef }
      modalTitle={ modalTitle }
      handleSave={ handleSave }
      authType={ authType }
    ></SelectUserModal>
  </>)
}

export default SelectUserFormItem
