import { Form, Input, Modal, notification, Spin } from 'antd'
import { forwardRef, useImperativeHandle, useState } from 'react'
import { confirmPasswordValidate, passwordValidate } from '../../plugins/validates'
import { updatePassword } from '../../common/request'
import { ResponseCode } from '../../common/response-code'
import EventBus from '../../plugins/events'

export interface ModalRefProps {
  showModal: () => void
}

type Props = {}

const UpdateUserPasswordModal = forwardRef<ModalRefProps>((props: Props, ref) => {
  const [form] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const onOk = () => {
    form.validateFields().then(fields => {
      setLoading(true)
      updatePassword({
        oldPwd: fields.oldPwd,
        newPwd: fields.newPwd
      }).then((res: any) => {
        setLoading(false)
        if (res.code === ResponseCode.success) {
          notification.open({
            type: 'success',
            message: '操作成功',
            description: '密码修改成功'
          })
          hideModal()
          EventBus.emit('logout')
        } else{
          notification.open({
            type: 'warning',
            message: '操作失败',
            description: res.msg
          })
        }
      }).catch((err: any) => {
        setLoading(false)
        console.error(err)
        Modal.error({
          title: '错误',
          content: '网络错误，修改密码失败'
        })
      })
    }).catch(err => {
      console.error(err)
    })
  }

  return (<Modal
    title={ '修改密码' }
    open={ modalVisible }
    onCancel={ hideModal }
    onOk={ onOk }
    confirmLoading={ loading }
  >
    <Form
      className={ 'my-4' }
      form={ form }
      labelCol={ {
        span: 8
      } }
      wrapperCol={ {
        span: 12
      } }
    >
      <Form.Item
        label={ '旧密码' }
        name={ 'oldPwd' }
        rules={ [{ required: true }] }
      >
        <Input type={ 'password' } placeholder={ '输入旧密码' }></Input>
      </Form.Item>
      <Form.Item
        label={ '新密码' }
        name={ 'newPwd' }
        rules={ [{ validator: passwordValidate }] }
      >
        <Input type={ 'password' } placeholder={ '输入新密码' }></Input>
      </Form.Item>
      <Form.Item
        label={ '确认密码' }
        name={ 'passwordAgain' }
        rules={ [
          { required: true, message: '密码不能为空' },
          confirmPasswordValidate.bind(this, 'newPwd')
        ] }>
        <Input type={ 'password' } placeholder={ '再次输入新密码' }></Input>
      </Form.Item>
    </Form>

    <Spin spinning={ loading } tip={'正在保存···'} fullscreen/>
  </Modal>)
})

export default UpdateUserPasswordModal
