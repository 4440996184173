import { Button, Form, Input, Modal, Space } from 'antd'
import { LockOutlined, MobileOutlined } from '@ant-design/icons'

import Styles from '../../index.module.scss'
import { confirmPasswordValidate, passwordValidate, phoneRegExp, phoneValidate } from '../../../../plugins/validates'
import VerifyCodeFormItem from '../../../../components/VerifyCodeFormItem'
import { resetPassword } from '../../../../common/request'
import { ResponseCode } from '../../../../common/response-code'

type FormFields = {
  phone: string
  code: string
  newPwd: string
  passwordAgain: string
}
type Props = {
  onSetActive: Function
}
const RetrievePassword = ({ onSetActive }: Props)=> {
  const [form] = Form.useForm()

  const validateFields = () => {
    form.validateFields().then(fields => {
      resetPasswordAction(fields)
    }).catch((err: any) => {
      console.error(err)
    })
  }
  const resetPasswordAction = (fields: FormFields) => {
    resetPassword(fields).then((res: any) => {
      if (res.code === ResponseCode.success) {
        Modal.success({
          title: '成功',
          content: '修改密码！'
        })
        form.resetFields()
        onSetActive('login')
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch((err: any) => {
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，修改密码失败'
      })
    })
  }

  return (
    <div className={ Styles.form_wrap }>
      <Form
        className={ 'login-form' }
        form={ form }
      >
        <Form.Item
          name={ 'phone' }
          rules={ [{ validator: phoneValidate }] }>
          <Input
            className={ Styles.login_input }
            placeholder="请输入您的手机号"
            prefix={ <MobileOutlined/> }
          />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={ (prevValues, currentValues) => prevValues.phone !== currentValues.phone }
        >
          {
            ({ getFieldValue }) => {
              const phone = getFieldValue('phone')
              return phoneRegExp.test(phone) ? (
                <Form.Item name={ 'code' } rules={ [{ required: true, message: '验证码不能为空' }] }>
                  <VerifyCodeFormItem phone={ phone }></VerifyCodeFormItem>
                </Form.Item>
              ) : (
                <Form.Item name={ 'code' } rules={ [{ required: true, message: '验证码不能为空' }] }>
                  <Space.Compact style={ { width: '100%' } }>
                    <Input
                      disabled
                      className={ Styles.login_input }
                      placeholder="请输入验证码"
                    />
                    <Button className={ Styles.login_sms_code_button } disabled type={ 'primary' }>获取验证码</Button>
                  </Space.Compact>
                </Form.Item>
              )
            }
          }
        </Form.Item>
        <Form.Item name={ 'newPwd' } rules={ [{ validator: passwordValidate }] }>
          <Input
            type={ 'password' }
            className={ Styles.login_input }
            prefix={ <LockOutlined/> }
            placeholder="请输入密码"
          />
        </Form.Item>
        <Form.Item
          name={ 'passwordAgain' }
          dependencies={ ['pwd'] }
          rules={ [
            { required: true, message: '密码不能为空' },
            confirmPasswordValidate.bind(this, 'newPwd')
          ] }
        >
          <Input
            type={ 'password' }
            prefix={ <LockOutlined/> }
            className={ Styles.login_input }
            placeholder="请再次输入密码"
          />
        </Form.Item>
      </Form>

      <Button
        className={ Styles.form_button }
        block
        type={ 'primary' }
        size={ 'large' }
        onClick={ validateFields }
      >确定</Button>
    </div>
  )
}

export default RetrievePassword
