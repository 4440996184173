import { createSlice } from '@reduxjs/toolkit'

export interface userInfoStateType {
  userInfo: any,
  loginStatus: boolean
}

export const userInfoSlice = createSlice({
  name: 'user-info',
  initialState: {
    userInfo: {},
    loginStatus: false
  },
  reducers: {
    updateUserInfo: (state, { payload }) => {
      state.userInfo = payload.userInfo
    },
    updateLoginStatus: (state, { payload }) => {
      state.loginStatus = payload.loginStatus
    }
  }
})

export const { updateUserInfo, updateLoginStatus } = userInfoSlice.actions

export default userInfoSlice.reducer
