import { ReactNode } from 'react'
import router from '../../routers'

import Styles from './index.module.scss'
import AppHeader from '../AppHeader'

type Props = {
  children: ReactNode
}

function AppSelfCenterPageContainer ({ children }: Props) {

  const handleBack = () => {
    router.navigate('/copyright-service')
  }

  return (
    <div className={ Styles.page_container }>
      <AppHeader handleBack={ handleBack }></AppHeader>
      { children }
    </div>
  )
}

export default AppSelfCenterPageContainer
