import { Modal, Pagination, Transfer, Button } from 'antd'
import { useSelector } from 'react-redux'
import React, { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'

import { authorList } from '../../common/request'
import { ResponseCode } from '../../common/response-code'
import EditIdentifyModal from '../EditIdentifyModal'
import { ModalRefProps as EditIdentifyModalProps } from '../EditIdentifyModal'
import EditCompanyIdentifyModal from '../../views/SelfCenter/tabs/IdentityManagement/EditCompanyIdentifyModal'

export interface ModalRefProps {
  showModal: (params: any) => void;
}

type Props = {
  modalTitle: string
  handleSave: any
  authType: number
}

const SelectUserModal = forwardRef(({ modalTitle, handleSave, authType }: Props, ref) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(10)
  const [listInfo, setListInfo] = useState<any>({ records: [] })
  const [targetKeys, setTargetKeys] = useState<string[]>([])
  const userInfo = useSelector((state: any) => state.userInfo.userInfo)
  const editIdentifyModalRef = useRef<EditIdentifyModalProps>(null)
  const editCompanyIdentifyModalRef = useRef<EditIdentifyModalProps>(null)

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      getUserList()
    }
  }, [currentPage, pageSize, modalVisible])

  const getUserList = () => {
    authorList({
      current: currentPage,
      size: pageSize,
      userId: userInfo.id,
      authType: authType
    }).then((res: any) => {
      if (res.code === ResponseCode.success) {
        setListInfo({
          ...res.data,
          records: res.data?.records.map((item: any) => {
            return {
              ...item,
              name: item.realName,
              key: item.id
            }
          })
        })
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      Modal.error({
        title: '错误',
        content: 'res.msg'
      })
    })
  }

  const showModal = (params: any) => {
    setModalVisible(true)
    setTargetKeys(params?.map((item: any) => item.key) || [])
  }

  const hideModal = () => {
    setModalVisible(false)
    setPageSize(10)
    setCurrentPage(1)
    setTargetKeys([])
  }

  const handleChange = (selectedKeys: any) => {
    setTargetKeys(selectedKeys)
  }

  const handlePagination = (current: number) => {
    setCurrentPage(current)
  }

  const renderFooter = () => {
    return <Pagination
      className={ 'my-1 ' }
      simple
      current={ currentPage }
      pageSize={ 10 }
      total={ listInfo.total }
      onChange={ handlePagination }
    ></Pagination>
  }

  const onOk = () => {
    hideModal()
    handleSave?.(listInfo.records?.filter((item: any) => {
      return targetKeys.includes(item.key)
    }))
  }

  const showEditIdentifyModal = () => {
    if (authType === 1) {
      editIdentifyModalRef.current?.showModal()
    } else {
      editCompanyIdentifyModalRef.current?.showModal()
    }
  }

  return (
    <Modal
      width={ 800 }
      title={ modalTitle }
      open={ modalVisible }
      onCancel={ hideModal }
      onOk={ onOk }
    >
      <div className={'mb-2 d-flex justify-content-end'}>
        <Button type='primary' onClick={ showEditIdentifyModal }>{ modalTitle.replace('选择', '添加') }</Button>
      </div>
      <Transfer
        titles={ ['作者列表', '已选作者列表'] }
        dataSource={ listInfo.records }
        showSearch
        listStyle={ {
          width: '50%',
          height: 300
        } }
        targetKeys={ targetKeys }
        onChange={ handleChange }
        render={ (item: any) => {
          return authType === 1 ? `${ item.realName }-${ item.idCard }` : `${ item.companyName }`
        } }
        footer={ renderFooter }
      />

      <EditIdentifyModal
        ref={ editIdentifyModalRef }
        modalType={ 'create' }
        verifySuccess={ getUserList }
      ></EditIdentifyModal>
      <EditCompanyIdentifyModal
        ref={ editCompanyIdentifyModalRef }
        modalType={ 'create' }
        verifySuccess={ getUserList }
      ></EditCompanyIdentifyModal>
    </Modal>
  )
})

export default SelectUserModal
