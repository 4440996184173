import Styles from '../CopyrightService/index.module.scss'
import PageContainer from '../../components/AppPageContainer'

function Home () {

  return (<>
    <PageContainer>
      <div className={ Styles.copyright_service }>
        Home!
      </div>
    </PageContainer>
  </>)
}

export default Home
