import React, { useEffect } from 'react'
import { Button, Dropdown } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { DownOutlined, UserOutlined } from '@ant-design/icons'

import router from '../../routers'
import Styles from './index.module.scss'
import { updateLoginStatus, updateUserInfo } from '../../store/slice/user-info-slice'
import EventBus from '../../plugins/events'

function AppUserDrawer () {
  const dispatch = useDispatch()
  const userInfo = useSelector((state: any) => state.userInfo.userInfo)
  const loginStatus = useSelector((state: any) => state.userInfo.loginStatus)

  useEffect(() => {
    EventBus.addListener('logout', listenerEvent)

    return () => {
      EventBus.removeListener('logout', listenerEvent)
    }
  }, [])

  const listenerEvent = () => {
    logout()
  }

  const logout = () => {
    localStorage.setItem('access_token', '')
    localStorage.setItem('token_header', '')
    dispatch(updateLoginStatus({ loginStatus: false }))
    dispatch(updateUserInfo({ userInfo: {} }))

    router.navigate('/login')
  }

  const handleClick = (event: any) => {
    switch (event.key) {
      case 'self-center':
        router.navigate('/self-center/self-info-setting')
        break
      case 'logout':
        logout()
        break
    }
  }

  const navigatorToLogin = () => {
    router.navigate(`/login?redirect_url=${ window.encodeURIComponent(window.location.pathname) }`)
  }


  return (<div className={ 'ml-1' }>
    {
      loginStatus
        ? (
          <Dropdown
            placement={ 'bottom' }
            menu={ {
              items: [
                {
                  key: 'self-center',
                  label: '个人中心'
                },
                {
                  key: 'logout',
                  label: '退出'
                }
              ],
              onClick: handleClick
            } }>
            <div className={ Styles.user_dropdown }>
              <div className={ Styles.icon_wrap }>
                <UserOutlined/>
              </div>
              <span className={ 'ml-1' }>{ userInfo.userName }</span>
              <DownOutlined className={ 'ml-2' }/>
            </div>
          </Dropdown>
        )
        : (
          <Button
            type={ 'primary' }
            shape="round"
            onClick={ navigatorToLogin }
          >登录/注册</Button>
        )
    }
  </div>)
}

export default AppUserDrawer
