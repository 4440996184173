import PageContainer from '../../components/AppPageContainer'

function ElectricCollection () {

  return (
    <PageContainer>
      <div>数字藏品</div>
    </PageContainer>
  )
}

export default ElectricCollection
