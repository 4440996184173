export const phoneRegExp = /^1[3-9]\d{9}$/
export const passwordRegExp = /^.*(?=.*[0-9])(?=.*[A-Z])(?=.*[a-z])\w{8,16}/
export const locationNumberRegExp = /(^0[1-9]$)|(^[1-9][0-9]$)/
export const startNumberRegExp = /(^00[1-9]$)|(^0[1-9][0-9]$)|(^[1-9][0-9][0-9]$)/
export const notNumberRegExp = /\D/g

export const phoneValidate = (rule:any, value:any) => {
  const data = value?.trim()

  if (!data) {
    return Promise.reject('手机号不能为空')
  }

  if (!phoneRegExp.test(data)) {
    return Promise.reject('手机号格式错误')
  }
  return Promise.resolve()
}

export const numberValidate = (rule:any, value:any) => {
  const data = value?.trim()

  if (data && notNumberRegExp.test(data)) {
    return Promise.reject('错误，仅支持数字格式')
  }
  return Promise.resolve()
}

export const passwordValidate = (rule:any, value:any) => {
  if (!value) {
    return Promise.reject('密码不能为空')
  }

  if (!passwordRegExp.test(value)) {
    return Promise.reject('密码格式错误，必须包含英文大小写、数字')
  }
  return Promise.resolve()
}

export const confirmPasswordValidate = (fieldName: string, { getFieldValue }: any) => {
  return {
    validator (_: any, value: any) {
      if (!value || getFieldValue(fieldName) === value) {
        return Promise.resolve()
      }
      return Promise.reject(new Error('确认密码与设置密码不一致'))
    }
  }
}

export const locationNumberValidate = (rule:any, value:any) => {
  if (!locationNumberRegExp.test(value)) {
    return Promise.reject('格式错误，范围01~99')
  }
  return Promise.resolve('')
}

export const startNumberValidate = (rule:any, value:any) => {
  if (!startNumberRegExp.test(value)) {
    return Promise.reject('格式错误，范围001~999')
  }
  return Promise.resolve('')
}
