import React, { forwardRef, useImperativeHandle, useState } from 'react'
import { Button, Modal } from 'antd'
import { MehOutlined } from '@ant-design/icons'

type Props = {
  children?: React.ReactNode
  visible?: boolean
  title?: string
}

type ErrorInfo = {
  title: string
  content: string
}

export type ErrorRefModal = {
  showModal: (info: ErrorInfo) => void
}

const ErrorModal = forwardRef((props: Props, ref: any) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [errorInfo, setErrorInfo] = useState({
    title: '',
    content: ''
  })

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  const showModal = (info: ErrorInfo) => {
    setErrorInfo(info)
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  return (
    <Modal
      width={ 750 }
      open={ modalVisible }
      onCancel={ hideModal }
      styles={ {
        header: { background: 'transparent' },
        content: {
          background: `
        linear-gradient(to bottom, #e6f6ff, #ffffff, #e8e5ff)
        `,
          backgroundBlendMode: 'multiply'
        }
      } }
      footer={ null }
    >
      <div className={ 'mt-4 mb-5 d-flex flex-column align-items-center' }>
        <MehOutlined style={ { fontSize: 60, color: 'rgb(27, 186, 215)' } }/>
        <div className={ 'my-2 font-weight-bold' }>{ errorInfo.title }</div>
        <div style={ { color: '#808080' } }>{ errorInfo.content }</div>
      </div>
      <div className={ 'd-flex justify-content-center' }>
        <Button className={ 'px-4' } shape={ 'round' } type={ 'primary' } onClick={ hideModal }>我已知晓</Button>
      </div>
    </Modal>
  )
})

export default ErrorModal
