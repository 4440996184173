export const BASE_URL = process.env.NODE_ENV === 'development' ? 'http://47.106.205.246:1090' : window.location.origin
export const FILE_URL = 'https://dccd.oss-cn-shenzhen.aliyuncs.com/'


export const createNatureOptions = [
  {
    label: '原创',
    value: '原创',
    key: '原创',
    description: '原创是指著作权人未使用其他已有作品创作的作品。'
  },
  {
    label: '改编',
    value: '改编',
    key: '改编',
    description: '改编是指著作权人使用已有作品衍生创作的作品。选择此项时，需在“附件”中上传原作品授权文件。'
  },
  {
    label: '翻译',
    value: '翻译',
    key: '翻译',
    description: '翻译是指著作权人使用已有作品衍生创作的作品。选择此项时，需在“附件”中上传原作品授权文件。'
  },
  {
    label: '汇编',
    value: '汇编',
    key: '汇编',
    description: '汇编是指著作权人使用已有作品衍生创作的作品。选择此项时，需在“附件”中上传原作品授权文件。'
  },
  {
    label: '注释',
    value: '注释',
    key: '注释',
    description: '注释是指著作权人使用已有作品衍生创作的作品。选择此项时，需在“附件”中上传原作品授权文件。'
  },
  {
    label: '整理',
    value: '整理',
    key: '整理',
    description: '整理是指著作权人使用已有作品衍生创作的作品。选择此项时，需在“附件”中上传原作品授权文件。'
  },
  {
    label: '其他',
    value: '其他',
    key: '其他',
    description: '其他情况需要详细说明。'
  },
]

export const autBelMethodOptions = [
  {
    label: '个人作品',
    value: '个人作品',
    key: '个人作品'
  },
  {
    label: '合作作品',
    value: '合作作品',
    key: '合作作品'
  },
  {
    label: '法人作品',
    value: '法人作品',
    key: '法人作品'
  },
  {
    label: '职位作品',
    value: '职位作品',
    key: '职位作品'
  },
  {
    label: '委托作品',
    value: '委托作品',
    key: '委托作品'
  }
]

export const categoryOptions = [
  {
    label: '文字作品',
    value: '文字作品',
    key: '文字作品',
    description: '指小说、诗词、散文、论文等以文字形式表现的作品。'
  },
  {
    label: '音乐作品',
    value: '音乐作品',
    key: '音乐作品',
    description: '指歌曲、交响乐等能够演唱或者演奏的带词或者不带词的作品。'
  },
  {
    label: '口述作品',
    value: '口述作品',
    key: '口述作品',
    description: '指即兴的演说、授课、法庭辩论等以口头语言形式表现的作品。'
  },
  {
    label: '戏剧作品',
    value: '戏剧作品',
    key: '戏剧作品',
    description: '指话剧、歌剧、地方戏等供舞台演出的作品。'
  },
  {
    label: '曲艺作品',
    value: '曲艺作品',
    key: '曲艺作品',
    description: '指相声、快书、大鼓、评书等以说唱为主要形式表演的作品。'
  },
  {
    label: '舞蹈作品',
    value: '舞蹈作品',
    key: '舞蹈作品',
    description: '指通过连续的动作、姿势、表情等表现思想情感的作品。'
  },
  {
    label: '杂技艺术作品',
    value: '杂技艺术作品',
    key: '杂技艺术作品',
    description: '指杂技、魔术、马戏等通过形体动作和技巧表现的作品。'
  },
  {
    label: '美术作品',
    value: '美术作品',
    key: '美术作品',
    description: '指绘画、书法、雕塑等以线条、色彩或者其他方式构成的有审美意义的平面或者立体的造型艺术作品。'
  },
  {
    label: '建筑作品',
    value: '建筑作品',
    key: '建筑作品',
    description: '指以建筑物或者构筑物形式表现的有审美意义的作品。'
  },
  {
    label: '摄影作品',
    value: '摄影作品',
    key: '摄影作品',
    description: '指借助器械在感光材料或者其他介质上记录客观物体形象的艺术作品。'
  },
  {
    label: '电影作品',
    value: '电影作品',
    key: '电影作品',
    description: '指摄制在一定介质上，由一系列有伴音或者无伴音的画面组成，并且借助适当装置放映或者以其他方式传播的作品。'
  },
  {
    label: '类似摄制电影方法创作的作品',
    value: '类似摄制电影方法创作的作品',
    key: '类似摄制电影方法创作的作品',
    description: '指摄制在一定介质上，由一系列有伴音或者无伴音的画面组成，并且借助适当装置放映或者以其他方式传播的作品。'
  },
  {
    label: '工程设计图',
    value: '工程设计图',
    key: '工程设计图',
    description: '暂无说明。'
  },
  {
    label: '地图',
    value: '地图',
    key: '地图',
    description: '暂无说明。'
  },
  {
    label: '模型作品',
    value: '模型作品',
    key: '模型作品',
    description: '指为展示、试验或者观测等用途，根据物体的形状和结构，按照一定比例制成的立体作品。'
  },
  {
    label: '其他作品',
    value: '其他作品',
    key: '其他作品',
    description: '暂无说明。'
  },
  {
    label: '录音制品',
    value: '录音制品',
    key: '录音制品',
    description: '指任何对表演的声音和其他声音的录制品。'
  },
  {
    label: '录像制品',
    value: '录像制品',
    key: '录像制品',
    description: '指电影作品和以类似摄制电影的方法创作的作品以外的任何有伴音或者无伴音的连续相关形象、图像的录制品。'
  },
  {
    label: '产品设计图',
    value: '产品设计图',
    key: '产品设计图',
    description: '暂无说明。'
  },
  {
    label: '示意图',
    value: '示意图',
    key: '示意图',
    description: '暂无说明。'
  }
]

export const applyMethodOptions = [
  {
    label: '由著作权人申请',
    value: '由著作权人申请',
    key: '由著作权人申请',
    description: ''
  },
  {
    label: '由代理人申请',
    value: '由代理人申请',
    key: '由代理人申请',
    description: <span>如您已在<a href="https://www.skyqx.cn/dccd/#/distribution/login" target="_blank">分销系统(https://www.skyqx.cn/dccd/#/distribution/login)</a>进行预付款，并能享受价格优惠，请您选择“由代理人申请”选项。</span>
  }
]

export const autHoldsOptions = [
  {
    label: '发表权',
    value: '发表权',
    key: '发表权',
    description: '即决定作品是否公之于众的权利'
  },
  {
    label: '署名权',
    value: '署名权',
    key: '署名权',
    description: '即表明作者身份，在作品上署名的权利'
  },
  {
    label: '修改权',
    value: '修改权',
    key: '修改权',
    description: '即修改或者授权他人修改作品的权利'
  },
  {
    label: '保护作品完整权',
    value: '保护作品完整权',
    key: '保护作品完整权',
    description: '即保护作品不受歪曲、篡改的权利'
  },
  {
    label: '复制权',
    value: '复制权',
    key: '复制权',
    description: '即以印刷、复印、拓印、录音、录像、翻录、 数字化等方式将作品制作一份或者多份的权利'
  },
  {
    label: '发行权',
    value: '发行权',
    key: '发行权',
    description: '即以出售或者赠与方式向公众提供作品的原件 或者复制件的权利'
  },
  {
    label: '出租权',
    value: '出租权',
    key: '出租权',
    description: '即有偿许可他人临时使用视听作品、计算机软 件的原件或者复制件的权利，计算机软件不是出租的主要标的的除外'
  },
  {
    label: '展览权',
    value: '展览权',
    key: '展览权',
    description: '即公开陈列美术作品、摄影作品的原件或者复 制件的权利'
  },
  {
    label: '表演权',
    value: '表演权',
    key: '表演权',
    description: '即公开表演作品，以及用各种手段公开播送作 品的表演的权利'
  },
  {
    label: '放映权',
    value: '放映权',
    key: '放映权',
    description: '即通过放映机、幻灯机等技术设备公开再现美术、摄影、视听作品等的权利'
  },
  {
    label: '广播权',
    value: '广播权',
    key: '广播权',
    description: '即以有线或者无线方式公开传播或者转播作品，以及通过扩音器或者其他传送符号、声音、图像的类似工具向公众传播广播的作品的权利，但不包括本款第十二项规定的权利'
  },
  {
    label: '信息网络传播权',
    value: '信息网络传播权',
    key: '信息网络传播权',
    description: '即以有线或者无线方式向公众提供作品、表演 或者录音录像制品，使公众可以在其个人选定的时 间和地点获得作品、表演或者录音录像制品的权利'
  },
  {
    label: '摄制权',
    value: '摄制权',
    key: '摄制权',
    description: '即以摄制视听作品的方法将作品固定在载体上的权利'
  },
  {
    label: '改编权',
    value: '改编权',
    key: '改编权',
    description: '即改变作品，创作出具有独创性的新作品的权利'
  },
  {
    label: '翻译权',
    value: '翻译权',
    key: '翻译权',
    description: '即将作品从一种语言文字转换成另一种语言文字的权利'
  },
  {
    label: '汇编权',
    value: '汇编权',
    key: '汇编权',
    description: '即将作品或者作品的片段通过选择或者编排，汇集成新作品的权利'
  },
  {
    label: '其他',
    value: '其他',
    key: '其他',
    description: '应当由著作权人享有的其他权利'
  }
]

export const autAcpMethodOptions = [
  {
    label: '原始',
    value: '原始',
    key: '原始'
  },
  {
    label: '继承',
    value: '继承',
    key: '继承'
  },
  {
    label: '承受',
    value: '承受',
    key: '承受'
  },
  {
    label: '其他',
    value: '其他',
    key: '其他'
  },
]

export const accountTypeMap = {
  1: '微信',
  2: '支付宝',
  4: '民生银行',
  5: '招商银行'
}
