import { Modal, notification, QRCode, Statistic } from 'antd'
import { forwardRef, useImperativeHandle, useState } from 'react'

import Styles from './index.module.scss'
import { getOrderStatus } from '../../common/request'
import { ResponseCode } from '../../common/response-code'
import router from '../../routers'

const { Countdown } = Statistic

export interface ModalRefProps {
  showModal: () => void;
}

type Props = {
  orderInfo: any
};

const WechatPayQrCodeModal = forwardRef<ModalRefProps, Props>(({ orderInfo }, ref) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [time, setTime] = useState(0)
  let timer: any = null

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  const getOrderStatusAction = () => {
    getOrderStatus({ id: orderInfo.orderId }).then((res: any) => {
      if (res.code === ResponseCode.success) {
        if (res.data.payStatus === 1) {
          hideModal()
          notification.open({
            type: 'success',
            message: '支付成功'
          })

          router.navigate('/pay-success')
        }
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch((err: any) => {
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询订单状态失败'
      })
    })
  }

  const showModal = () => {
    setTime(Date.now() + 1000 * 60 * 5)
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const countdownTimeFinish = () => {
    hideModal()
  }

  const countdownTimeChange = (data: any) => {
    if (Math.ceil(data / 1000) % 5 === 0) {
      if (!timer) {
        timer = setTimeout( () => {
          getOrderStatusAction()
          timer = null
        }, 2000)
      }
    }
  }

  return (
    <Modal
      open={ modalVisible }
      footer={ false }
      onCancel={ hideModal }
    >
      <div className={ 'my-4 text-center' }>
        <div className={ 'mb-3 font-weight-bold font-size-large' }>请打开微信扫码支付</div>
        <div className={ 'mb-3' }>
          <span>请您在</span>
          <Countdown
            value={ time }
            format="mm分ss秒"
            onChange={ countdownTimeChange }
            onFinish={ countdownTimeFinish }
            valueStyle={ { color: '#E32F2F' } }
          />
          <span>内完成支付，否则订单将自动取消</span>
        </div>
        <div className={ 'mb-2 d-flex justify-content-center' }>
          <div className={ Styles.qrcode_wrap }>
            <QRCode className={ Styles.qrcode_img } value={ orderInfo.qrCode }/>
          </div>
        </div>
        <div className={ 'd-flex justify-content-center align-items-baseline' }>
          <div>应付金额合计：</div>
          <div className={ 'text-primary' }>
            <span style={ { fontSize: '20px' } }>¥</span>
            <span style={ { fontSize: '24px' } }>{ orderInfo.payAmount }</span>
          </div>
        </div>
      </div>
    </Modal>
  )
})

export default WechatPayQrCodeModal
