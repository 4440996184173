import { useEffect, useState } from 'react'
import { Button, DatePicker, Form, Input, Modal, Popover, Radio, Spin } from 'antd'

import AppDarkPageContainer from '../../components/AppDarkPageContainer'
import UploadImageFormItem from '../../components/UploadImageFormItem'
import AppRadioFormItem from '../../components/AppRadioFormItem'
import AppRadioButton from '../../components/AppRadioButton'
import SelectUserFormItem from '../../components/SelectUserFormItem'
import UploadFileFormItem from '../../components/UploadFileFormItem'
import router from '../../routers'
import {
  applyMethodOptions,
  autAcpMethodOptions,
  autBelMethodOptions,
  autHoldsOptions,
  categoryOptions,
  createNatureOptions
} from '../../common/constant'
import { DownloadOutlined, UploadOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import { useSearchParams } from 'react-router-dom'
import { copyRightApplication, downloadTemplate, getCopyRightApplicationDetail, updateCopyright } from '../../common/request'
import { downloadFile, fileNameFormat, FileUrlDataType, getFileFormat, getUid } from '../../plugins/units'
import { ResponseCode } from '../../common/response-code'
import AppRegionCascader from '../../components/AppRegionCascader'
import Styles from './index.module.scss'

function CopyrightApplication () {
  const [form] = Form.useForm()
  const [form1] = Form.useForm()
  const [searchParams] = useSearchParams()
  const [loading, setLoading] = useState(true)

  const productFileType = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif']
  const attachmentFileType = [
    'application/zip',
    'application/x-7z-compressed',
    'application/x-rar-compressed',
    'application/pdf',
    'image/png',
    'image/jpg',
    'image/jpeg'
  ]

  const authTypeOptions = [
    { label: '个人认证', value: 1 },
    { label: '企业认证', value: 2 }
  ]

  useEffect(() => {
    if (searchParams.get('id')) {
      getCopyRightApplicationDetailAction()
    } else {
      setLoading(false)
    }
  }, [])

  const generateInitFileData = (fileUrlData?: FileUrlDataType | undefined) => {
    if (!fileUrlData) {
      return []
    }

    if (typeof fileUrlData === 'string') {
      return [{
        url: fileUrlData,
        name: fileUrlData,
        status: 'done',
        uid: getUid()
      }]
    }

    if (Array.isArray(fileUrlData)) {
      return fileUrlData.map(item => {
        return {
          url: item,
          name: item,
          status: 'done',
          uid: getUid()
        }
      })
    }

    return []
  }

  const generateInitDayjsData = (data: any) => {
    return data ? dayjs(data) : ''
  }

  const generateInitUserData = (authType: number, data: any) => {
    if (authType === 1) {
      return data?.map((item: any) => {
        return {
          ...item,
          name: item.realName
        }
      })
    } else {
      return data?.map((item: any) => {
        return {
          ...item,
          companyName: item.realName
        }
      })
    }
  }

  const generateFormData = (data: any) => {
    form.setFieldsValue({
      productFile: generateInitFileData(data.productFile),
      name: data.name,
      description: data.description,
      category: [data.category],
      creationTime: generateInitDayjsData(data.creationTime),
      createAddress: data.createAddress ? data.createAddress.split(',') : [],
      createNature: data.createNature,
      publishState: data.publishState,
      publishAddress: data.publishAddress,
      publishTime: generateInitDayjsData(data.publishTime),
      publishAnnex: generateInitFileData(data.publishAnnex),
      createIntention: data.createIntention,
      productOriginal: data.productOriginal,
      coverPic: generateInitFileData(data.coverPic),
      images: generateInitFileData(data.images),
      productPubAnnex: generateInitFileData(data.productPubAnnex)
    })

    form1.setFieldsValue({
      applyMethod: data.applyMethod,
      autBelMethod: data.autBelMethod,
      autBelMethodExp: data.autBelMethodExp,
      autHolds: data.autHolds,
      autHoldsExp: data.autHoldsExp,
      autAcpMethod: data.autAcpMethod,
      autAcpMethodExp: data.autAcpMethodExp,
      signature: data.signature,
      authors: generateInitUserData(data.authType, data.authors),
      authType: data.authType,
      copyRights: generateInitUserData(data.authType, data.copyRights),
      copyrightStatement: fileNameFormat(generateInitFileData(data.copyrightStatement)),
      enclosures: fileNameFormat(generateInitFileData(data.enclosures))
    })
  }

  const getCopyRightApplicationDetailAction = () => {
    setLoading(true)
    getCopyRightApplicationDetail({ id: searchParams.get('id') }).then((res: any) => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        generateFormData(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch((err: any) => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，版权申请失败'
      })
    })
  }

  const getDayjsFormatTime = (dayjsItem: any) => {
    return dayjsItem ? dayjs(dayjsItem).format('YYYY-MM-DD').toString() : ''
  }

  const createCopyright = (params: any) => {
    setLoading(true)
    copyRightApplication(params).then((res: any) => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        router.navigate(`/preview-certificate?id=${ res.data }`)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch((err: any) => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，版权申请失败'
      })
    })
  }

  const updateCopyrightAction = (params: any, fn: any) => {
    setLoading(true)
    updateCopyright(params).then((res: any) => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        fn?.()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch((err: any) => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，版权申请失败'
      })
    })
  }
  const scrollToFirstError = () => {
    document.querySelector('.ant-form-item-has-error')?.scrollIntoView({ behavior: 'auto', block: 'center' });
  }
  const nextStep = () => {
    Promise.all([
      form.validateFields(),
      form1.validateFields()
    ]).then(([fields, fields1]) => {
      const params = {
        ...fields,
        ...fields1,
        category: fields.category[0],
        createTime: getDayjsFormatTime(fields.creationTime),
        productFile: fields.productFile?.filter((item: any) => {
          return item.status === 'done'
        }).map((item: any) => {
          return item.url
        }),
        createAddress: fields.createAddress?.join(','),
        coverPic: fields.coverPic?.[0]?.url,
        publishAnnex: fields.publishAnnex?.[0]?.url,
        productPubAnnex: fields.productPubAnnex?.[0]?.url,
        copyrightStatement: fields1.copyrightStatement?.[0]?.url,
        enclosures: fields1.enclosures?.filter((item: any) => {
          return item.status === 'done'
        }).map((item: any) => {
          return item.url
        }),
        images: fields.images?.filter((item: any) => {
          return item.status === 'done'
        }).map((item: any) => {
          return item.url
        })
      }

      if (searchParams.get('id')) {
        updateCopyrightAction({
          id: searchParams.get('id'),
          ...params
        }, () => {
          router.navigate(`/preview-certificate?id=${ searchParams.get('id') }`)
        })
      } else {
        createCopyright(params)
      }
    }).catch((err: any) => {
      scrollToFirstError()
      console.error(err)
    })

  }

  const downloadTemplateFile = (e: any) => {
    e.stopPropagation()
    downloadTemplate().then((res: any) => {
      downloadFile(res, '权利证书模板', 'zip')
    }).catch((err: any) => {
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，'
      })
    })
  }

  const generatePopoverContent = (list: any[]) => {
    return <div className={ 'text' } style={ { width: 600 } }>
      {
        list.map(item => {
          return <div className={ Styles.popover_wrap } key={ item.key }>
            <span className={ Styles.popover_label }>{ item.label }:</span>
            <span className={ Styles.popover_desc }>{ item.description }</span>
          </div>
        })
      }
    </div>
  }

  const namingHelp = (<div style={ { width: 800 } }>
    <div className={ 'mb-2 d-flex' }>
      <div className={ 'font-weight-bold' } style={ { whiteSpace: 'nowrap' } }>音乐作品名称命名：</div>
      <div style={ { color: '#777777' } }>《XXXX》词曲，《XXXX》曲谱；</div>
    </div>
    <div className={ 'mb-2 d-flex' }>
      <div className={ 'font-weight-bold' } style={ { whiteSpace: 'nowrap' } }>美术作品名称命名：</div>
      <div style={ { color: '#777777' } }>“XXXX”标识图案 ，“XXXX”卡通形象 ，“XXXX”苗绣， “XXXX”刺绣， “XXXX”木雕， “XXXX”广告宣传画， “XXXX”漫画 ，“XXXX”封面设计 ，“XXXX”包装盒设计， “XXXX”表情包 ， “XXXX”室内（外）设计效果图；</div>
    </div>
    <div className={ 'mb-2 d-flex' }>
      <div className={ 'font-weight-bold' } style={ { whiteSpace: 'nowrap' } }>舞蹈作品名称命名：</div>
      <div style={ { color: '#777777' } }>“XXXX”舞蹈 ，“XXXX”舞；</div>
    </div>
    <div className={ 'mb-2 d-flex' }>
      <div className={ 'font-weight-bold' } style={ { whiteSpace: 'nowrap' } }>文字作品名称命名：</div>
      <div style={ { color: '#777777' } }>《XXXX》文， 《XXXX》电影剧本 ，《XXXX》话剧剧本， 《XXXX》戏剧剧本 ，《XXXX》小说 ，《XXXX》诗歌；</div>
    </div>
    <div className={ 'mb-2 d-flex' }>
      <div className={ 'font-weight-bold' } style={ { whiteSpace: 'nowrap' } }>摄影作品名称命名：</div>
      <div style={ { color: '#777777' } }>“XXXX”摄影；</div>
    </div>
    <div className={ 'mb-2 d-flex' }>
      <div className={ 'font-weight-bold' } style={ { whiteSpace: 'nowrap' } }>电影作品名称命名：</div>
      <div style={ { color: '#777777' } }>“XXXX”电影 ，“XXXX”电视剧 ，“XXXX”记录片， “XXXX”视频 ， “XXXX”教学视频 ， “XXXX”宣传片；</div>
    </div>
    <div className={ 'mb-2 d-flex' }>
      <div className={ 'font-weight-bold' } style={ { whiteSpace: 'nowrap' } }>工程设计图、产品设计图作品名称命名：</div>
      <div style={ { color: '#777777' } }>“XXXX”产品设计图 ， “XXXX”工程设计图；</div>
    </div>
    <div className={ 'mb-2 d-flex' }>
      <div className={ 'font-weight-bold' } style={ { whiteSpace: 'nowrap' } }>地图、示意图作品名称命名：</div>
      <div style={ { color: '#777777' } }>“XXXX”地图 ， “XXXX”示意图；</div>
    </div>
    <div>注：对系列作品的名称命名在其后加上数字大写即可；</div>
  </div>)

  const howIsCopyrightContent = (<div
    style={ { width: 300 } }>著作权人又称"著作权主体"，是指依法对文学、艺术和科学作品享有著作权的人。著作权人可分为原始著作权人和继受著作权人。原始著作权人指创作作品的公民和依照法律规定视为作者的法人或者非法人单位；继受著作权指通过继承、受让、受赠等法律许可的形式取得著作权财产权的公民、法人或者非法人单位。</div>)

  const copyrightContentDescription = (<>
    <div style={ { width: 300 } }>
      <div>1.著作权人最多只支持添加5个。</div>
      <div>2.非代理人本人认证信息不支持删除</div>
      <div>3.非代理人升级为代理人请联系客服。</div>
    </div>
  </>)

  const authTypeChange = () => {
    form1.setFieldValue('authors', [])
    form1.setFieldValue('copyRights', [])
  }

  return (
    <AppDarkPageContainer>
      <Spin spinning={ loading } fullscreen/>
      <div className={ `px-4  ${Styles.overflow_container}` }>
        <div className={ Styles.scroll_bar }>
          <div className={ 'app-card p-3' }>
            <div className={ 'app-card-title' }>作品信息填写</div>
            <div className={ 'pt-3' }>
              <Form
                form={ form }
                scrollToFirstError={true}
                initialValues={ {
                  createAddress: [],
                  createNature: '原创',
                  publishState: '未发表',
                  category: ['文字作品']
                } }
                labelCol={ {
                  span: 3
                } }
                wrapperCol={ {
                  span: 20
                } }
              >
                <Form.Item
                  label={ '登记作品文件上传' }
                  name={ 'productFile' }
                  rules={ [{ required: true, message: '登记作品文件不能为空' }] }
                >
                  <UploadImageFormItem
                    width={ 200 }
                    height={ 120 }
                    maxCount={ 6 }
                    fileMaxSize={ 500 * 1024 }
                    fileTypeList={ productFileType }
                    description={ '上传文件' }
                  >
                    <div className={ 'mt-2' }>
                      <div className={ 'font-weight-bold' }>温馨提示</div>
                      <div>1.支持png/jpg/jpeg/gif格式文件，单个文件最大不超过200MB。</div>
                      <div>2.系列作品最多只能上传6个文件，若不构成系列作品请分开登记</div>
                      <div>3.作品文件上传后，一旦提交成功将无法再进行修改，请仔细核对后上传。</div>
                    </div>
                  </UploadImageFormItem>
                </Form.Item>
                <Form.Item
                  label={ '作品名称' }
                  name={ 'name' }
                  rules={ [{ required: true, message: '作品名称不能为空' }] }
                >
                  <Input
                    placeholder={ '请输入作品名称' }
                    maxLength={ 30 }
                    showCount
                    suffix={
                      <Popover content={ namingHelp } title="命名方式小助手">
                        <div className={ 'text-blue' }>命名帮助？</div>
                      </Popover>
                    }
                  ></Input>
                </Form.Item>
                <Form.Item
                  label={ '作品描述' }
                  name={ 'description' }
                >
                  <Input.TextArea
                    placeholder={ '请输入创作说明' }
                    count={ {
                      max: 300,
                      show: true
                    } }
                    styles={ {
                      textarea: {
                        height: 100
                      }
                    } }
                  ></Input.TextArea>
                </Form.Item>
                <Form.Item
                  label={ '作品类别' }
                  name={ 'category' }
                  rules={ [{ required: true, message: '作品类别不能为空' }] }
                >
                  <AppRadioButton showTooltip={ true } options={ categoryOptions }></AppRadioButton>
                </Form.Item>
                <Form.Item
                  label={ '创作完成时间' }
                  name={ 'creationTime' }
                  rules={ [{ required: true, message: '创作完成时间不能为空' }] }
                >
                  <DatePicker className={ 'w-100' }/>
                </Form.Item>
                <Form.Item
                  label={ '创作完成地点' }
                  name={ 'createAddress' }
                  rules={ [{ required: true, message: '创作完成地点不能为空' }] }
                >
                  <AppRegionCascader
                    placeholder={ '请选择区域' }
                  ></AppRegionCascader>
                </Form.Item>
                <Form.Item
                  label={ '创作性质' }
                  name={ 'createNature' }
                  rules={ [{ required: true, message: '创作性质不能为空' }] }
                >
                  <AppRadioFormItem showTooltip={ true } options={ createNatureOptions }></AppRadioFormItem>
                </Form.Item>
                <Form.Item
                  label={ '发表状态' }
                  name={ 'publishState' }
                  rules={ [{ required: true, message: '发表状态不能为空' }] }
                >
                  <Radio.Group>
                    <Radio value={ '未发表' }>未发表</Radio>
                    <Radio value={ '已发表' }>已发表</Radio>
                  </Radio.Group>
                </Form.Item>
                <Form.Item
                  noStyle
                  shouldUpdate={ (prevValues, curValues) => {
                    return prevValues.publishState !== curValues.publishState
                  } }>
                  { ({ getFieldValue }) => {
                    return getFieldValue('publishState') === '已发表'
                      ? (
                        <>
                          <Form.Item
                            label={ '作品发表地点' }
                            name={ 'publishAddress' }
                          >
                            <Input placeholder={ '输入作品发表地点' }></Input>
                          </Form.Item>
                          <Form.Item
                            label={ '作品发表时间' }
                            name={ 'publishTime' }
                          >
                            <DatePicker className={ 'w-100' }/>
                          </Form.Item>
                          <Form.Item
                            label={ '作品发表佐证附件' }
                            name={ 'publishAnnex' }
                          >
                            <UploadImageFormItem
                              width={ 200 }
                              height={ 120 }
                              maxCount={ 1 }
                              fileMaxSize={ 20 * 1024 }
                              description={ '上传作品发表佐证附件' }/>
                          </Form.Item>
                        </>
                      )
                      : <></>
                  } }
                </Form.Item>

                <Form.Item
                  label={ '创作意图' }
                  name={ 'createIntention' }
                >
                  <Input.TextArea
                    placeholder={ '请输入创作说明' }
                    count={ {
                      max: 300,
                      show: true
                    } }
                    styles={ {
                      textarea: {
                        height: 130
                      }
                    } }
                  ></Input.TextArea>
                </Form.Item>
                <Form.Item
                  label={ '作品独创性' }
                  name={ 'productOriginal' }
                >
                  <Input.TextArea
                    placeholder={ '请输入作品独创性' }
                    count={ {
                      max: 300,
                      show: true
                    } }
                    styles={ {
                      textarea: {
                        height: 130
                      }
                    } }
                  ></Input.TextArea>
                </Form.Item>
                <Form.Item
                  label={ '作品展示图片' }
                  name={ 'images' }
                >
                  <UploadImageFormItem
                    width={ 200 }
                    height={ 120 }
                    maxCount={ 6 }
                    fileMaxSize={ 20 * 1024 }
                    description={ '添加作品展示图片' }>
                    <div className={ 'mt-2' }>
                      <div className={ 'font-weight-bold' }>温馨提示</div>
                      <div>1.若你未上传展示页图片，则无“作品展示页”。</div>
                      <div>2.上传的所有作品展示图片须是登记作品文件内的内容，否则此作品登记申请将被驳回。</div>
                      <div>3.最多支持上传6张图片，且单张图片大小不超过20M，支持png/jpg/jpeg格式。</div>
                    </div>
                  </UploadImageFormItem>
                </Form.Item>
                <Form.Item
                  label={ '作品发布佐证附件' }
                  name={ 'productPubAnnex' }
                >
                  <UploadImageFormItem
                    width={ 200 }
                    height={ 120 }
                    maxCount={ 1 }
                    fileMaxSize={ 20 * 1024 }
                    description={ '上传作品发布佐证附件' }/>
                </Form.Item>
              </Form>
            </div>
          </div>

          <div className={ 'app-card p-3 mt-3' }>
            <div className={ 'app-card-title' }>权属信息填写</div>
            <div className={ 'pt-3' }>
              <Form
                form={ form1 }
                scrollToFirstError={true}
                labelCol={ {
                  span: 3
                } }
                wrapperCol={ {
                  span: 20
                } }
                initialValues={{
                  authType: 1,
                  applyMethod: '由著作权人申请',
                  autBelMethod: '个人作品',
                  autHolds: ['发表权'],
                  autAcpMethod: '原始'
                }}
              >
                <Form.Item
                  label={ '申请方式' }
                  name={ 'applyMethod' }
                  rules={ [{ required: true, message: '申请方式不能为空' }] }
                >
                  <AppRadioFormItem showTooltip={ true } options={ applyMethodOptions }></AppRadioFormItem>
                </Form.Item>
                <Form.Item
                  label={ '权利归属方式' }
                  name={ 'autBelMethod' }
                  rules={ [{ required: true, message: '权利归属方式不能为空' }] }
                >
                  <AppRadioFormItem options={ autBelMethodOptions }>
                    <div className={ 'mt-2' }>
                      <span className={ 'font-weight-bold' }>温馨指示：</span>
                      <span>权利归属方式选择“个人作品”时，作者和“著作权人”只能选择1个人</span>
                    </div>
                  </AppRadioFormItem>
                </Form.Item>
                <Form.Item
                  label={ '权利归属方式说明' }
                  name={ 'autBelMethodExp' }
                >
                  <Input.TextArea
                    placeholder={ '个人作品：个人完成；\n' +
                      '合作作品：多人合作完成\n' +
                      '法人作品：法人名义完成；\n' +
                      '职务作品：工作范围中完成；\n' +
                      '委托作品：他人委托完成；\n' +
                      '示例：\n' +
                      '本作品为合作作品，由张XX，李XX共同协作完成。\n' }
                    count={ {
                      max: 100,
                      show: true
                    } }
                    styles={ {
                      textarea: {
                        height: 168
                      }
                    } }
                  ></Input.TextArea>
                </Form.Item>
                <Form.Item
                  label={ '权利拥有状况' }
                  name={ 'autHolds' }
                  rules={ [{ required: true, message: '至少选择一项权利' }] }
                >
                  <AppRadioButton
                    multiple={ true }
                    controlBarVisible={ true }
                    options={ autHoldsOptions }>
                    <Popover content={ generatePopoverContent(autHoldsOptions) } title="权利拥有情况帮助" trigger="hover">
                      <Button className={ 'px-0' } type={ 'link' }>权利拥有情况帮助？</Button>
                    </Popover>
                  </AppRadioButton>
                </Form.Item>
                <Form.Item
                  label={ '权利拥有状况说明' }
                  name={ 'autHoldsExp' }
                >
                  <Input.TextArea
                    placeholder={ '权利拥有情况（全部还是部分）\n示例：本人拥有该作品的发表权、署名权、修改权、发行权、出租权和摄制权。' }
                    count={ {
                      max: 100,
                      show: true
                    } }
                    styles={ {
                      textarea: {
                        height: 90
                      }
                    } }
                  ></Input.TextArea>
                </Form.Item>
                <Form.Item
                  label={ '权利获得方式' }
                  name={ 'autAcpMethod' }
                  rules={ [{ required: true, message: '权利获得方式不能为空' }] }
                >
                  <AppRadioFormItem showTooltip={ false } options={ autAcpMethodOptions }></AppRadioFormItem>
                </Form.Item>
                <Form.Item
                  label={ '权利获得方式说明' }
                  name={ 'autAcpMethodExp' }
                >
                  <Input.TextArea
                    placeholder={ '请输入权利获得方式说明' }
                    count={ {
                      max: 100,
                      show: true
                    } }
                    styles={ {
                      textarea: {
                        height: 90
                      }
                    } }
                  ></Input.TextArea>
                </Form.Item>
                <Form.Item
                  label={ '署名' }
                  name={ 'signature' }
                >
                  <Input placeholder={ '请输入署名' }></Input>
                </Form.Item>
                <Form.Item
                  label={ '认证方式' }
                  name={ 'authType' }
                >
                  <Radio.Group options={authTypeOptions} onChange={ authTypeChange }></Radio.Group>
                </Form.Item>
                <Form.Item shouldUpdate={(prevValues, curValues) => prevValues.authType !== curValues.authType} noStyle={true}>
                  {(data) => {
                    return (<>
                      <Form.Item
                        label={ '添加作者' }
                        name={ 'authors' }
                        rules={ [{ required: true, message: '作者不能为空' }] }
                      >
                        <SelectUserFormItem
                          modalTitle={ '选择作者' }
                          addText={ '添加作者' }
                          emptyText={ '暂无作者' }
                          authType={data.getFieldValue('authType')}
                        >
                          <div>
                            <div className={ 'font-weight-bold' }>温馨提示：</div>
                            <div>1.作者即指创作作品的人或组织</div>
                            <div>2.作者最多只能添加5人</div>
                          </div>
                        </SelectUserFormItem>
                      </Form.Item>
                      <Form.Item
                        label={ '添加著作权人' }
                        name={ 'copyRights' }
                        rules={ [{ required: true, message: '著作权人不能为空' }] }
                      >
                        <SelectUserFormItem
                          modalTitle={ '选择著作权人' }
                          addText={ '添加著作权人' }
                          emptyText={ '暂无著作权人' }
                          authType={data.getFieldValue('authType')}
                        >
                          <div>
                            <div>
                              <Popover placement={ 'right' } content={ howIsCopyrightContent } title="什么是著作权人？">
                                <Button className={ 'p-0' } type={ 'link' }>什么叫著作权人？</Button>
                              </Popover>
                            </div>
                            <div>
                              <Popover placement={ 'right' } content={ copyrightContentDescription } title="著作权人规则">
                                <Button className={ 'pl-0' } type={ 'link' }>著作权人添加、删除规则？</Button>
                              </Popover>
                            </div>
                          </div>
                        </SelectUserFormItem>
                      </Form.Item>
                    </>)
                  }}
                </Form.Item>
                <Form.Item
                  label={ '权利保证书' }
                  name={ 'copyrightStatement' }
                  rules={ [{ required: true, message: '权利证书未上传' }] }
                >
                  <UploadFileFormItem
                    fileTypeList={ attachmentFileType }
                    maxCount={ 1 }
                    content={ <>
                      <Button
                        type={ 'primary' }
                        ghost
                        className={ 'mr-2' }
                        icon={ <UploadOutlined/> }
                      >上传文件</Button>
                      <Button
                        type={ 'primary' }
                        ghost
                        icon={ <DownloadOutlined/> }
                        onClick={ downloadTemplateFile }
                      >下载权利保证书模板</Button>
                    </> }
                  >
                    <div>
                      <div className={ 'font-weight-bold' }>温馨提示：</div>
                      <div className={ 'text-danger' }>1.请打印权利保证书，并上传盖章/签字的原件（必填）。</div>
                      <div>2.支持{ getFileFormat(attachmentFileType) }格式</div>
                    </div>
                  </UploadFileFormItem>
                </Form.Item>
                <Form.Item
                  label={ '附件' }
                  name={ 'enclosures' }
                >
                  <UploadFileFormItem fileTypeList={ attachmentFileType }>
                    <div>
                      <div className={ 'font-weight-bold' }>温馨提示：</div>
                      <div>1.若有其他权属材料可以在此上传,如: 签署的协议，合同等。</div>
                      <div>2.支持{ getFileFormat(attachmentFileType) }格式</div>
                    </div>
                  </UploadFileFormItem>
                </Form.Item>
              </Form>
            </div>
          </div>
        </div>
        

        {/*<div className={ 'app-card p-3 mt-3' }>*/ }
        {/*  <div className={ 'd-flex align-items-center justify-content-between' }>*/ }
        {/*    <div className={ 'app-card-title' }>权属信息填写</div>*/ }
        {/*    <div>*/ }
        {/*      <span className={ 'font-weight-bold' }>温馨提示：</span>*/ }
        {/*      <span>在申请版权的时候，您可以同时选择申请和展示数字藏品，待版权申请成功后，系统将直接为您的作品生成数字藏品</span>*/ }
        {/*    </div>*/ }
        {/*  </div>*/ }
        {/*  <div className={ 'pt-3' }>*/ }
        {/*    <Form*/ }
        {/*      form={ form1 }*/ }
        {/*      labelCol={ {*/ }
        {/*        span: 3*/ }
        {/*      } }*/ }
        {/*      wrapperCol={ {*/ }
        {/*        span: 20*/ }
        {/*      } }*/ }
        {/*    >*/ }
        {/*      <Form.Item label={ '是否生产数字藏品' } name={ 'jjj' }>*/ }
        {/*        <Radio.Group>*/ }
        {/*          <Radio value={ 1 }>生产数字藏品</Radio>*/ }
        {/*          <Radio value={ 2 }>不需要生产数字藏品</Radio>*/ }
        {/*        </Radio.Group>*/ }
        {/*      </Form.Item>*/ }
        {/*      <Form.Item label={ '是否展示数字藏品' } name={ 'kkk' }>*/ }
        {/*        <Radio.Group>*/ }
        {/*          <Radio value={ 1 }>展示数字藏品</Radio>*/ }
        {/*          <Radio value={ 2 }>不需要展示数字藏品</Radio>*/ }
        {/*        </Radio.Group>*/ }
        {/*      </Form.Item>*/ }
        {/*    </Form>*/ }
        {/*  </div>*/ }
        {/*</div>*/ }

        
      </div>
      <div className={ 'my-3 text-center' }>
        <Button
          type={ 'primary' }
          shape={ 'round' }
          style={ { width: 180 } }
          onClick={ nextStep }
        >下一步</Button>
      </div>
    </AppDarkPageContainer>
  )
}

export default CopyrightApplication
