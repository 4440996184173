import { createSlice } from '@reduxjs/toolkit'

const dataTransform = (data: any) => {
  return data.map((item: any) => {
    return {
      label: item.name,
      value: item.code,
      id: item.id,
      isLeaf: !item.childList,
      children: item.childList ? dataTransform(item.childList) : undefined
    }
  })
}

export interface regionInfoStateType {
  regionList: any[],
  loadedRegion: string[]
}

export const regionListSlice = createSlice({
  name: 'region-list',
  initialState: {
    regionList: [],
    regionInfo: {},
    loadedRegion: []
  },
  reducers: {
    updateRegionList: (state, { payload }) => {
      state.regionList = payload.regionList
    },
    updateChildRegion: (state, { payload }) => {
      // @ts-ignore
      state.regionInfo[payload.regionCode] = payload.regionData
      // @ts-ignore
      state.loadedRegion.push(payload.regionCode)
    }
  }
})

export const { updateRegionList, updateChildRegion } = regionListSlice.actions

export default regionListSlice.reducer
