import { useSelector } from 'react-redux'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Button, Form, Input, Modal, notification, Radio, Select, Spin } from 'antd'
import IdCardImgUploadFormItem from '../IdCardImgUploadFormItem'
import NationList from '../NationList'
import { ReducerType } from '../../store'
import { getUid } from '../../plugins/units'
import { addIdentify, getIdentifyDetail, updateIdentify } from '../../common/request'
import { ResponseCode } from '../../common/response-code'
import EventBus from '../../plugins/events'

export interface ModalRefProps {
  showModal: (params?: any) => void;
}

type Props = {
  modalType: 'create' | 'edit'
  userVerifyInfo?: any
  verifySuccess?: () => void
}

const EditIdentifyModal = forwardRef<ModalRefProps, Props>((props: Props, ref) => {
  const [modalTitle, setModalTitle] = useState('')
  const [form] = Form.useForm()
  const [notify, contextHolder] = notification.useNotification()
  const nationList = useSelector((state: ReducerType) => state.nation.nationList)
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const idCardFileType = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf']

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      setModalTitle(props.modalType ? '添加作者' : '编辑作者')
    }
  }, [modalVisible])

  const showModal = (params?: any) => {
    if (params) {
      getIdentifyDetailAction(params)
    }
    setModalVisible(true)
  }

  const getIdentifyDetailAction = (params: any) => {
    setLoading(true)
    getIdentifyDetail({ id: params.id }).then((res: any) => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        form.setFieldsValue({
          id: res.data.id,
          type: 1,
          ...res.data,
          idCardImg: [
            {
              uid: getUid(),
              status: 'done',
              name: res.data.idCardFront,
              url: res.data.idCardFront
            },
            {
              uid: getUid(),
              status: 'done',
              name: res.data.idCardBack,
              url: res.data.idCardBack
            }
          ]
        })
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch((err: any) => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询作者实名认证信息失败'
      })
    })
  }

  const hideModal = () => {
    setModalVisible(false)
    form.resetFields()
  }

  const addIdentifyAction = (fields: any) => {
    const params = {
      ...fields,
      idCardFront: fields.idCardImg[0].url,
      idCardBack: fields.idCardImg[1].url
    }

    setLoading(true)
    handleResponse(addIdentify(params))
  }

  const updateIdentifyAction = (fields: any) => {
    const params = {
      ...fields,
      id: props.userVerifyInfo.id,
      idCardFront: fields.idCardImg[0].url,
      idCardBack: fields.idCardImg[1].url
    }

    handleResponse(updateIdentify(params))
  }

  const handleResponse = (fn: any) => {
    fn.then((res: any) => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        notify.success({
          message: '操作成功',
          description: '数据已更新'
        })
        EventBus.emit('updateUserInfo')
        props.verifySuccess?.()
        hideModal()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch((err: any) => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，实名认证失败'
      })
    })
  }

  const validateFormField = () => {
    form.validateFields().then(fields => {
      setLoading(true)
      if (props.modalType === 'edit') {
        updateIdentifyAction(fields)
      } else {
        addIdentifyAction(fields)
      }

    }).catch((err: any) => {
      console.error(err)
    })
  }

  return (<>
    { contextHolder }
    <Modal
      width={ 750 }
      title={ modalTitle }
      open={ modalVisible }
      onCancel={ hideModal }
      footer={ null }
      styles={ {
        header: { background: 'transparent' },
        content: {
          background: `
        linear-gradient(-45deg, rgb(211, 238, 255), #ffffff, transparent),
        linear-gradient(45deg, rgb(230, 210, 255), #ffffff, transparent),
        linear-gradient(135deg, rgb(218, 251, 255), #ffffff, transparent),
        linear-gradient(225deg, rgb(252, 233, 239), #ffffff, transparent)
        `,
          backgroundBlendMode: 'multiply'
        }
      } }
    >
      <Spin spinning={ loading }>
        <div className={ 'pt-3' }>
          <Form
            className={ 'mt-3' }
            form={ form }
            labelCol={ {
              span: 5
            } }
            wrapperCol={ {
              span: 16
            } }
            initialValues={{
              authType: 1,
              realName: '',
              gender: 1
            }}
          >
            <Form.Item
              name={ 'authType' }
              label={ '认证方式' }
              required
            >
              <Radio.Group>
                <Radio value={ 1 }>个人认证</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name={ 'realName' }
              label={ '姓名' }
              required
            >
              <Input placeholder={ '请输入您的名字' }></Input>
            </Form.Item>
            <Form.Item
              name={ 'idCard' }
              label={ '身份证号' }
              required
            >
              <Input placeholder={ '请输入身份证号' }></Input>
            </Form.Item>
            <Form.Item
              name={ 'gender' }
              label={ '性别' }
              required
            >
              <Radio.Group>
                <Radio value={ 1 }>男</Radio>
                <Radio value={ 2 }>女</Radio>
              </Radio.Group>
            </Form.Item>
            <Form.Item
              name={ 'nation' }
              label={ '民族' }
              required
            >
              <Select options={ nationList } placeholder={ '选择民族' }></Select>
            </Form.Item>
            <Form.Item
              name={ 'address' }
              label={ '地址' }
              required
            >
              <Input placeholder={ '输入地址' }></Input>
            </Form.Item>
            {/*<Form.Item*/ }
            {/*  name={'address'}*/ }
            {/*  label={'渠道ID'}*/ }
            {/*  required*/ }
            {/*>*/ }
            {/*  <Input placeholder={'输入渠道ID'}></Input>*/ }
            {/*</Form.Item>*/ }
            <Form.Item
              name={ 'phone' }
              label={ '手机号' }
              required
            >
              <Input placeholder={ '输入手机号' }></Input>
            </Form.Item>
            <Form.Item
              name={ 'idCardImg' }
              label={ '上传身份证' }
              required
            >
              <IdCardImgUploadFormItem
                fileTypeList={ idCardFileType }
              ></IdCardImgUploadFormItem>
            </Form.Item>
          </Form>

          <div className={ 'mt-4 text-center' }>
            <Button
              type={ 'primary' }
              shape="round"
              loading={ loading }
              onClick={ validateFormField }
            >确认提交</Button>
          </div>
        </div>
      </Spin>
    </Modal>

    <NationList></NationList>
  </>)
})

export default EditIdentifyModal
