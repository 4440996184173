import React, { useRef } from 'react'
import { useSelector } from 'react-redux'
import { Button, Divider, Image } from 'antd'

import router from '../../routers'
import Styles from './index.module.scss'
import PageContainer from '../../components/AppPageContainer'
import RegisterProcessImg from '../../assets/images/register-process.png'
import RealNameAuthenticationModal, { ModalRefProps } from '../../components/RealNameAuthenticationModal'
import SharedQrcode from '../../assets/images/share-qrcode.jpg'

interface UserInfoState {
  userInfo: {
    userInfo: any
    loginStatus: boolean
  }
}

function CopyrightService () {
  const realNameAuthenticationModalRef = useRef<ModalRefProps | null>(null)
  const userInfo = useSelector((state: UserInfoState) => state.userInfo.userInfo)

  const applyCopyrightAction = () => {
    if (!userInfo.verify) {
      realNameAuthenticationModalRef.current?.showModal()
    } else {
      navigatorTo('/copyright-application')
    }
  }

  const navigatorTo = (path: string) => {
    router.navigate(path)
  }

  return (
    <PageContainer>
      <div className={ Styles.copyright_service }>
        <div className={ Styles.page_title }>最快仅需①个工作日</div>
        <div className={ Styles.page_sub_title }>获得中国版权数字藏品官方证书</div>
        <div className={ Styles.page_description }>
          <div>接入中国版权局作品线上登记平台，打破传统线下登记弊端</div>
          <div>节约申请成本，提升登记效率，为作品登记提供便捷服务</div>
        </div>
        <div className={ Styles.process_title }>版权登记流程</div>
        <Image
          className={ Styles.register_process_img }
          preview={ false }
          src={ RegisterProcessImg }
        ></Image>

        <div className={ Styles.page_control }>
          <Button
            className={ 'mr-4 px-4' }
            type={ 'primary' }
            size={'large'}
            style={{ borderRadius: 0 }}
            onClick={ applyCopyrightAction }
          >开始版权申请</Button>
          {/*<Button*/}
          {/*  className={ 'px-4 celeste' }*/}
          {/*  type={ 'primary' }*/}
          {/*  onClick={ navigatorTo.bind(this, '/copyright-inspection') }*/}
          {/*>版权查验</Button>*/}
        </div>

        <Divider className={ 'mt-5 mb-4' }/>

        <div className={ Styles.page_footer }>
          <div className={ Styles.qr_code_img }>
            <Image src={ SharedQrcode }></Image>
          </div>
          <div className={ 'd-flex justify-content-center flex-column' }>
            <div className={ 'font-weight-bold' }>支持PC端、手机端 (微信公众号直接使用)</div>
            <div className={ 'text-gray-light' }>
              <div>
                <span>关注</span>
                <span className={ 'text-dark' }>创巢文化知识产权数藏平台</span>
                <span>微信公众号</span>
              </div>
              <div>开启手机端版权服务体验</div>
            </div>
          </div>
        </div>
      </div>

      <RealNameAuthenticationModal ref={realNameAuthenticationModalRef}></RealNameAuthenticationModal>
    </PageContainer>
  )
}

export default CopyrightService
