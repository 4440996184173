import { ReactElement, useEffect, useState } from 'react'
import { Button, Input, Modal, Space } from 'antd'

import Styles from './index.module.scss'
import { getVerifyCode } from '../../common/request'
import { ResponseCode } from '../../common/response-code'
import { LoadingOutlined } from '@ant-design/icons'

type Props = {
  phone: string
  value?: string
  onChange?: any
}

function VerifyCodeFormItem ({ value, phone, onChange }: Props): ReactElement {
  const [disabledInput, setDisabledInput] = useState(true)
  const [buttonStatus, setButtonStatus] = useState('default')
  const [countDown, setCountDown] = useState(120)
  const [countDownText, setCountDownText] = useState('')

  useEffect(() => {
    if (buttonStatus === 'countTime') {
      if (countDown === 0) {
        setCountDown(120)
        setButtonStatus('default')
      } else {
        setCountDownText(`${countDown}S`)
        setTimeout(() => {
          setCountDown(pre => {
            return pre - 1
          })
        }, 1000)
      }
    }
  }, [countDown, buttonStatus])

  const getVerifyCodeAction = () => {
    setButtonStatus('loading')
    setDisabledInput(false)

    getVerifyCode({ phone: phone }).then((res: any) => {
      if (res.code === ResponseCode.success) {
        setButtonStatus('countTime')
      } else {
        setButtonStatus('default')
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch((err: any) => {
      setButtonStatus('default')
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，获取验证码失败'
      })
    })
  }

  const handleInputChange = (e: any) => {
    onChange?.(e.target.value)
  }

  const getVerifyCodeText = (status: string, text: string): ReactElement | string => {
    let content
    switch (status) {
      case 'loading':
        content = (<>
          <LoadingOutlined />
          <span>加载中</span>
        </>)
        break
      case 'countTime':
        content = text
        break
      default:
        content = '获取验证码'
    }

    return content
  }

  return (
    <Space.Compact style={ { width: '100%' } }>
      <Input
        value={ value }
        disabled={ disabledInput }
        className={ Styles.login_input }
        placeholder="请输入验证码"
        onChange={ handleInputChange }/>
      <Button
        className={ Styles.login_sms_code_button }
        type={ 'primary' }
        onClick={ getVerifyCodeAction }
        disabled={ buttonStatus !== 'default' }
        ghost={ buttonStatus !== 'default' }
        style={{ minWidth: 120 }}
      >{ getVerifyCodeText(buttonStatus, countDownText) }</Button>
    </Space.Compact>
  )
}

export default VerifyCodeFormItem
