import { useEffect, useState } from 'react'
import { getRegionTree } from '../../common/request'
import { ResponseCode } from '../../common/response-code'
import { Modal } from 'antd'

type Props = {
  ids: string
}

function GetAddressByRegionIds ({ ids }: Props) {
  const [address, setAddress] = useState('')

  useEffect(() => {
    const idList = ids.split(',')
    if (idList[idList.length - 1]) {
      getAddress(idList[idList.length - 1])
    }
  }, [])

  const getAddress = (id: string) => {
    getRegionTree({ id: id }).then((res: any) => {
      if (res.code === ResponseCode.success) {
        setAddress(res.data[0].mergeName)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch((err: any) => {
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询地区名称失败'
      })
    })
  }

  return (<>{ address }</>)
}

export default GetAddressByRegionIds
