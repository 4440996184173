import { ReactNode } from 'react'

import router from '../../routers'
import AppHeader from '../AppHeader'
import Styles from './index.module.scss'

type Props = {
  children: ReactNode
}

function AppDarkPageContainer ({ children }: Props) {

  const menuList = [
    {
      label: '版权申请',
      path: '/copyright-application'
    }
    // {
    //   label: '版权查验',
    //   path: '/copyright-inspection'
    // }
  ]

  const handleBack = () => {
    router.navigate('/copyright-service')
  }

  return (
    <div className={ Styles.page_container }>
      <AppHeader menuList={ menuList } handleBack={ handleBack }></AppHeader>

      <div style={ {
        width: '1200px',
        margin: '0 auto'
      } }>
        { children }
      </div>
    </div>
  )
}

export default AppDarkPageContainer
