import { getAllPoster } from '../../common/request'
import { ResponseCode } from '../../common/response-code'
import { AxiosRes } from '../../plugins/axios'
import { forwardRef, useEffect, useImperativeHandle, useRef, useState } from 'react'
import { Button, Col, Modal, Row } from 'antd'
import { FILE_URL } from '../../common/constant'
// @ts-ignore
import QRCode from 'qrcode'
import { useSelector } from 'react-redux'
import { downloadBlob } from '../../plugins/units'

const ViewPosterModal = forwardRef((props, ref) => {
  const canvas = useRef<any>(null)
  const userInfo = useSelector((state: any) => state.userInfo.userInfo)
  const [posterList, setPosterList] = useState([])
  const [modalVisible, setModalVisible] = useState(false)
  const [currentPosterIndex, setCurrentPosterIndex] = useState(0)

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      getPosterList()
    }
  }, [modalVisible])

  useEffect(() => {
    if (posterList.length > 0) {
      generatePoster()
    }
  }, [posterList, currentPosterIndex])

  const generatePoster = () => {
    canvas.current.width = 540
    canvas.current.height = 750
    canvas.current.style.width = '540px'
    canvas.current.style.height = '750px'
    const ctx = canvas.current.getContext('2d')
    const currentPoster: any = posterList[currentPosterIndex]
    const image = new Image()
    image.crossOrigin = 'Anonymous'
    image.src = `${ FILE_URL }${ currentPoster.picUrl }`

    image.style.width = '0px'
    image.style.height = '0px'
    image.onload = async () => {
      ctx.drawImage(image, 0, 0, 540, 750)
      const qrcodeImage = new Image()
      qrcodeImage.src = await QRCode.toDataURL(`https://www.skyqx.cn/mobile/pages/index/index?code=${ userInfo.inviteCode }`)
      qrcodeImage.style.width = '0px'
      qrcodeImage.style.height = '0px'
      qrcodeImage.onload = () => {
        ctx.drawImage(qrcodeImage, currentPoster.xPosition, currentPoster.yPosition, currentPoster.qrCodeWidth, currentPoster.qrCodeHeight)
      }
      document.body.appendChild(qrcodeImage)
    }

    document.body.appendChild(image)
  }

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const getPosterList = () => {
    getAllPoster().then((res: AxiosRes) => {
      if (res.code === ResponseCode.success) {
        setPosterList(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，获取海报背景图失败'
      })
    })
  }

  const togglePosterAction = () => {
    setCurrentPosterIndex(pre => {
      if (pre >= posterList.length - 1) {
        return 0
      } else {
        return pre + 1
      }
    })
  }

  const downloadPoster = () => {
    const ctx = canvas.current.getContext('2d')
    ctx.canvas.toBlob((blob:any) => {
      downloadBlob(blob, `推广海报-${userInfo.userName}`, 'png')
    }, 'image/png')
  }

  return (<Modal
    width={ 700 }
    title={ '生成推广海报' }
    open={ modalVisible }
    onCancel={ hideModal }
    footer={ null }
    maskClosable={ false }
  >
    <div className={ 'd-flex justify-content-center' }>
      <canvas ref={ canvas } style={ { minWidth: 100, minHeight: 100 } }></canvas>
    </div>

    {
      posterList.length > 2
        ? <div className={ 'mt-3' }>
          <Button
            className={ 'w-100 ant-btn celeste' }
            type={ 'primary' }
            onClick={ togglePosterAction }
          >换一张</Button>
        </div>
        : <></>
    }

    <Row className={ 'mt-3' } gutter={ 16 }>
      <Col span={ 12 }>
        <Button className={ 'w-100' } type={ 'primary' } ghost onClick={ hideModal }>取消</Button>
      </Col>
      <Col span={ 12 }>
        <Button className={ 'w-100' } type={ 'primary' } onClick={ downloadPoster }>保存</Button>
      </Col>
    </Row>
  </Modal>)
})

export default ViewPosterModal
