import React, { useEffect, useState } from 'react'
import { Outlet } from 'react-router-dom'
import { useDispatch } from 'react-redux'

import PageStatus from '../../components/PageStatus'
import { getRegionTree, userInfo } from '../../common/request'
import { ResponseCode } from '../../common/response-code'
import { updateLoginStatus, updateUserInfo } from '../../store/slice/user-info-slice'
import { Modal } from 'antd'
import EventBus from '../../plugins/events'
import { updateRegionList } from '../../store/slice/region-info-slice'
import router from '../../routers'

function Preload () {
  const dispatch = useDispatch()
  const [loadingStatus, setLoadingStatus] = useState({ status: 'loading', text: '正在加载' })

  useEffect(() => {
    if (window.location.pathname === '/') {
      router.navigate('/login')
    }
    const token = localStorage.getItem('access_token')
    if (token) {
      dispatch(updateLoginStatus({
        loginStatus: true
      }))

      getInitData()
    }

    EventBus.addListener('updateUserInfo', listenerEvent)

    return () => {
      EventBus.removeListener('updateUserInfo', listenerEvent)
    }
  }, [])

  const listenerEvent = () => {
    getUserInfoAction()
  }

  const getInitData = () => {
    Promise.all([
      userInfo(),
      getRegionTree({ parentId: '100000' })
    ]).then(([userInfoRes, regionListRes]: any[]) => {
      setLoadingStatus({ status: '', text: '' })
      if (userInfoRes.code === ResponseCode.success) {
        dispatch(updateUserInfo({
          userInfo: userInfoRes.data
        }))
      } else {
        Modal.error({
          title: '错误',
          content: userInfoRes.msg
        })
      }

      if (regionListRes.code === ResponseCode.success) {
        dispatch(updateRegionList({
          regionList: regionListRes.data.map((item: any) => {
            return {
              ...item,
              label: item.shortName,
              value: String(item.id),
              isLeaf: false
            }
          })
        }))
      } else {
        Modal.error({
          title: '错误',
          content: regionListRes.msg
        })
      }
    }).catch((err: any) => {
      setLoadingStatus({ status: '', text: '' })
      console.error(err)
    })
  }

  const getUserInfoAction = () => {
    userInfo().then((res: any) => {
      if (res.code === ResponseCode.success) {
        dispatch(updateUserInfo({
          userInfo: res.data
        }))
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch((err: any) => {
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询用户信息失败'
      })
    })
  }

  return (<>
    <div>
      <PageStatus pageStatus={ loadingStatus.status } text={ loadingStatus.text }>
        <Outlet />
      </PageStatus>
    </div>
  </>)
}

export default Preload
