import { Form, Image, Modal, notification, Spin } from 'antd'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { updateAvatar } from '../../common/request'
import { ResponseCode } from '../../common/response-code'
import EventBus from '../../plugins/events'
import { FILE_URL } from '../../common/constant'

export interface UserAvatarModalModalRefProps {
  showModal: (url: string) => void
}

type Props = {}

const UpdateUserAvatarModal = forwardRef<UserAvatarModalModalRefProps>((props: Props, ref) => {
  const [form] = Form.useForm()
  const [modalVisible, setModalVisible] = useState(false)
  const [loading, setLoading] = useState(false)
  const [avatarUrl, setAvatarUrl] = useState('')

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
    }
  }, [modalVisible])

  const showModal = (url: string) => {
    setAvatarUrl(url)
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const onOk = () => {
    setLoading(true)
    updateAvatar({
      url: avatarUrl
    }).then((res: any) => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        notification.open({
          type: 'success',
          message: '操作成功',
          description: '修改头像成功'
        })
        hideModal()
        EventBus.emit('updateUserInfo')
      }
    }).catch((err: any) => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，修改头像失败'
      })
    })
  }

  return (<Modal
    title={ '修改头像' }
    open={ modalVisible }
    onCancel={ hideModal }
    onOk={ onOk }
    confirmLoading={ loading }
  >
    <div className={ 'mb-1 text-blue text-center' }>
      确定使用该图片作为头像吗？
    </div>
    <div className={ 'd-flex align-items-center justify-content-center' }>
      <Image src={ FILE_URL + avatarUrl } width={ 150 } height={ 150 }></Image>
    </div>

    <Spin spinning={ loading } tip={ '正在保存···' } fullscreen/>
  </Modal>)
})

export default UpdateUserAvatarModal
