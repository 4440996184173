import React, { useEffect, useState } from 'react'
import { Cascader, Modal } from 'antd'
import { useSelector } from 'react-redux'

import { getRegionTree } from '../../common/request'
import { ResponseCode } from '../../common/response-code'
import { ReducerType } from '../../store'

const dataTransform = (data: any) => {
  return data?.map((item: any) => {
    return {
      ...item,
      label: item.name,
      value: String(item.id),
      isLeaf: false
    }
  })
}

function AppRegionCascader (props: any) {
  const regionList = useSelector((state: ReducerType) => state.regionList.regionList)
  const [regionOptions, setRegionOptions] = useState<any[]>([])
  const [loadedRegion, setLoadedRegion] = useState<any[]>([])

  useEffect(() => {
    if (props.value && props.value.length > 0) {
      initRegionOptions(props.value, 0)
    }
  }, [props.value])

  useEffect(() => {
    setRegionOptions(regionList.map((item: any) => {
      return {
        label: item.label,
        value: String(item.value),
        id: item.id,
        isLeaf: false
      }
    }))
  }, [regionList])

  const handleRequest = (params: any, fn: any) => {
    setLoadedRegion(pre => {
      pre.push(params.parentId)
      return pre
    })
    getRegionTree(params).then((res: any) => {
      if (res.code === ResponseCode.success && res.data) {
        fn?.(res)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg,
          centered: true
        })
      }
    }).catch((err) => {
      console.error(err)
      Modal.error({
        title: '网络错误',
        content: '查询区域失败',
        centered: true
      })
    })
  }

  const editTreeItem = (list: any, data: any, codeList: any, currentIndex: number, targetIndex: number) => {
    list?.forEach((item: any) => {
      if (item.value === codeList[currentIndex]) {
        if (currentIndex === targetIndex) {
          item.children = data
        } else {
          editTreeItem(item.children, data, codeList, currentIndex + 1, targetIndex)
        }
      }
    })
  }

  const initRegionOptions = (regionIds: any[], targetIndex: number) => {
    if (!loadedRegion.includes(regionIds[targetIndex])) {
      handleRequest({ parentId: regionIds[targetIndex] }, (res: any) => {
        const regionData = res.data ? dataTransform(res.data) : []
        editTreeItem(regionOptions, regionData, regionIds, 0, targetIndex)

        if (regionIds.length > (targetIndex + 1)) {
          initRegionOptions(regionIds, targetIndex + 1)
        }

        setRegionOptions([...regionOptions])
      })
    }

  }

  const getRegionTreeAction = (targetOption: any) => {
    const params = { parentId: targetOption.value }
    handleRequest(params, (res: any) => {
      targetOption.loading = false
      const regionData = dataTransform(res.data)
      targetOption.children = regionData
      if (res.data.length === 0) {
        targetOption.isLeaf = true
      }

      setRegionOptions([...regionOptions])
    })
  }

  const loadRegionData = (selectedOptions: any) => {
    const targetOption = selectedOptions[selectedOptions.length - 1]
    targetOption.loading = true

    if (targetOption.children) {
      targetOption.loading = false
    } else {
      getRegionTreeAction(targetOption)
    }
  }

  return (
    <>
      <Cascader
        { ...props }
        changeOnSelect
        options={ regionOptions }
        loadData={ loadRegionData }
      ></Cascader>
    </>
  )
}

export default AppRegionCascader
