import { useSelector } from 'react-redux'

import { Image } from 'antd'

import { ReactComponent as Avatar } from '../../assets/icons/avatar-bg.svg'
import { FILE_URL } from '../../common/constant'
import AppIcon from '../AppIcon'
import Styles from './index.module.scss'

function AppUserAvatar () {
  const userInfo = useSelector((state: any) => state.userInfo.userInfo)

  return (<>
    {
      userInfo.avatar
        ? <Image src={ FILE_URL + userInfo.avatar } width={100} height={100}></Image>
        : (<div className={ Styles.init_user_avatar }>
          <AppIcon svg={ Avatar }></AppIcon>
        </div>)
    }
  </>)
}

export default AppUserAvatar
