import { forwardRef, useImperativeHandle, useState } from 'react'
import { Button, Image, Modal, Spin } from 'antd'
import { getCopyRightApplicationDetail } from '../../common/request'
import { ResponseCode } from '../../common/response-code'

export interface CertificateModalProps {
  showModal: (copyrightId: any) => void
}

const ViewCopyrightCertificateModal = forwardRef((props, ref) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [urlList, setUrlList] = useState<string[]>([])
  const [copyrightDetail, setCopyrightDetail] = useState<any>({})
  const [loading, setLoading] = useState(false)

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  const showModal = (id: any) => {
    getCopyrightDetail(id)
    setModalVisible(true)
  }

  const getCopyrightDetail = (id: any) => {
    setLoading(true)
    getCopyRightApplicationDetail({ id: id }).then((res: any) => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        setCopyrightDetail(res.data)
        setUrlList(res.data.certificateImgUrl.split(','))
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch((err: any) => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询版权详情失败'
      })
    })
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const downloadPdf = () => {
    let a = document.createElement('a')
    a.setAttribute('href', `${copyrightDetail.certificateUrl}?response-content-type=application/octet-stream`)
    a.setAttribute('download', `版权证书-${ copyrightDetail.registerId }`)
    a.setAttribute('target', '_blank')
    let clickEvent = document.createEvent('MouseEvents')
    clickEvent.initEvent('click', true, true)
    a.dispatchEvent(clickEvent)
  }

  return (
    <Modal
      width={ 800 }
      title={ '查看版权证书' }
      open={ modalVisible }
      onCancel={ hideModal }
      footer={ [
        <Button
          key={ 'close' }
          type={ 'primary' }
          onClick={ hideModal }
        >关闭</Button>
      ] }
    >
      <div className={ 'my-2 d-flex justify-content-end' }>
        <Button type={ 'primary' } onClick={ downloadPdf }>下载</Button>
      </div>
      <Spin spinning={loading}>
        <div style={{ minHeight: 100 }}>
          {
            urlList.map(item => {
              return (
                <div key={ item }>
                  <Image preview={ false } src={ item }></Image>
                </div>
              )
            })
          }
        </div>
      </Spin>
    </Modal>
  )
})

export default ViewCopyrightCertificateModal
