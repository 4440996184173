import { useEffect, useRef, useState } from 'react'
import { Button, Divider, Form, notification, Pagination, Spin, Switch, Empty, Image, Modal, Space } from 'antd'
import { CheckOutlined } from '@ant-design/icons'

import AppDarkPageContainer from '../../components/AppDarkPageContainer'
import AppRadioButton from '../../components/AppRadioButton'
import Styles from './index.module.scss'
import WechatPayQrCodeModal from '../../components/WechatPayQrCodeModal'
import { ModalRefProps } from '../../components/WechatPayQrCodeModal'
import { useSearchParams } from 'react-router-dom'
import { getCopyRightApplicationDetail, getCouponPage, getDistributorProductList, getOrderInfo, getPayChannel, getProductList } from '../../common/request'
import { ResponseCode } from '../../common/response-code'
import dayjs from 'dayjs'
import router from '../../routers'
import { useSelector } from 'react-redux'
import { ReducerType } from '../../store'
import WalletImage from '../../assets/images/wallet.png'
import WechatPayImage from '../../assets/images/wechat-pay.png'
import ViewWalletModal, { ViewWalletModalProps } from '../../components/ViewWalletModal/ViewWalletModal'

type CouponItem = {
  id: number
}

function PayCost () {
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  const viewWalletModalRef = useRef<ViewWalletModalProps>(null)
  const userInfo = useSelector((state: ReducerType) => state.userInfo.userInfo)
  const wechatPayQrCodeModalRef = useRef<ModalRefProps | null>(null)
  const [selectedProductKey, setSelectedProductKey] = useState<string>('')
  const [productList, setProductList] = useState([])
  const [activePayChannel, setActivePayChannel] = useState<number>(1)
  const [payChannelList, setPayChannelList] = useState([])
  const [orderInfo, setOrderInfo] = useState({})
  const [loading, setLoading] = useState(true)
  const [spinText, setSpinText] = useState('')
  const [useCoupon, setUseCoupon] = useState(false)
  const [couponInfo, setCouponInfo] = useState({
    records: [],
    total: 0
  })
  const [couponCurrentPage, setCouponCurrentPage] = useState(1)
  const [selectedCoupon, setSelectedCoupon] = useState<number[]>([])

  const payChannelMap: any = {
    1: {
      label: '微信支付',
      key: 'wechat',
      icon: <Image preview={ false } src={ WechatPayImage } style={ {
        width: 24,
        height: 20,
        marginRight: 10,
        marginBottom: 2
      } }></Image>
    },
    6: {
      label: '预付款结算',
      key: 'wallet',
      icon: <Image preview={ false } src={ WalletImage } style={ {
        width: 24,
        height: 20,
        marginRight: 10,
        marginBottom: 2
      } }></Image>
    }
  }

  useEffect(() => {
    if (id && userInfo.id) {
      getInitInfo()
    }

    return () => {}
  }, [userInfo])

  useEffect(() => {
    if (useCoupon) {
      getCouponPageAction()
    } else {
      setSelectedCoupon([])
    }
  }, [useCoupon, couponCurrentPage])

  const togglePayChannel = (payType: number) => {
    setActivePayChannel(payType)
  }

  const getCouponPageAction = () => {
    setSpinText('正在获取优惠券列表')
    setLoading(true)
    getCouponPage({
      current: couponCurrentPage,
      size: 999,
      couponStatus: 0
    }).then((res: any) => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        setCouponInfo(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch((err: any) => {
      console.error(err)
      setLoading(false)
      Modal.error({
        title: '错误',
        content: '网络错误，查询优惠券列表失败'
      })
    })
  }

  const getGoodsList = (copyRightDetail: any) => {
    if (copyRightDetail.applyMethod === '由代理人申请' && userInfo.isDistributor) {
      return getDistributorProductList()
    } else {
      return getProductList()
    }
  }

  const getInitInfo = () => {
    setSpinText('正在加载···')
    setLoading(true)
    getCopyRightApplicationDetail({ id: id }).then((res: any) => {
      if (res.code === ResponseCode.success) {
        Promise.all([
          getGoodsList(res.data),
          getPayChannel({ isProxyApply: res.data.applyMethod === '由代理人申请' && userInfo.isDistributor })
        ]).then(([productInfoRes, payChannelInfoRes]: any) => {
          setLoading(false)
          if (productInfoRes.code === ResponseCode.success) {
            setProductList(productInfoRes.data.map((item: any) => {
              return {
                value: item.id,
                label: item.productName,
                description: item.description,
                key: item.id,
                amount: item.amount
              }
            }))
            setSelectedProductKey(productInfoRes.data[0].id)
          } else {
            Modal.error({
              title: '错误',
              content: productInfoRes.msg
            })
          }

          if (payChannelInfoRes.code === ResponseCode.success) {
            setPayChannelList(payChannelInfoRes.data)
          } else {
            Modal.error({
              title: '错误',
              content: payChannelInfoRes.msg
            })
          }
        }).catch((err: any) => {
          setLoading(false)
          console.error(err)
        })
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    })
  }

  const beforeGenerateOrder = (payType: number) => {
    switch (payType) {
      case 1:
        getOrderInfoAction()
        break
      case 6:
        viewWalletModalRef.current?.showModal()
        break
    }
  }

  const getOrderInfoAction = () => {
    setLoading(true)
    setSpinText('正在创建订单')
    const params = {
      productId: selectedProductKey,
      copyrightId: id,
      payType: activePayChannel,
      systemType: 1,
      couponIds: selectedCoupon
    }

    getOrderInfo(params).then((res: any) => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        setOrderInfo(res.data)
        wechatPayQrCodeModalRef.current?.showModal()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch((err: any) => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询订单信息失败'
      })
    })
  }

  const selectProductChange = (selectedKeys: string[]) => {
    setSelectedProductKey(selectedKeys[0])
  }

  const getPayChannelClassName = (channel: number, channelKey: number) => {
    if (channel === channelKey) {
      return [Styles.pay_channel_item, Styles.is_active].join(' ')
    } else {
      return Styles.pay_channel_item
    }
  }

  const getCouponSum = (selectedKeys: number[], info: any) => {
    return selectedKeys.map(couponId => {
      return info.records.find((coupon: any) => {
        return coupon.id === couponId
      }).amount
    }).reduce((pre, cur) => {
      return pre + cur
    }, 0)
  }

  const getSelectedProductContent = (key: string, list: any[], couponList: any[]) => {
    const selectedItem = list.find(item => {
      return item.key === key
    })

    if (selectedItem) {
      return (
        <div>
          <div className={ 'mb-2 text-blue' }>{ selectedItem.description }</div>

          {
            couponList.length > 0
              ? (<>
                <div className={ 'd-flex align-items-baseline justify-content-end' }>
                  <div>应付金额：</div>
                  <div className={ 'font-size-large text-primary' }>¥ { selectedItem.amount }</div>
                </div>
                <div className={ 'd-flex align-items-baseline justify-content-end' }>
                  <div>优惠券抵扣：</div>
                  <div className={ 'font-size-large text-primary' }>- ¥ { getCouponSum(couponList, couponInfo) }</div>
                </div>
              </>)
              : <></>
          }

          <div className={ 'd-flex align-items-baseline justify-content-end' }>
            <div>实付金额：</div>
            <div className={ Styles.amount_text }>¥ { selectedItem.amount - getCouponSum(couponList, couponInfo) }</div>
          </div>
        </div>
      )
    } else {
      return <></>
    }
  }

  const useCouponChange = (val: boolean) => {
    setUseCoupon(val)
  }

  const selectedCouponChange = (couponItem: CouponItem) => {
    if (selectedCoupon.includes(couponItem.id)) {
      setSelectedCoupon(pre => {
        return pre.filter(item => {
          return item !== couponItem.id
        })
      })
    } else {
      if (selectedCoupon.length < 2) {
        setSelectedCoupon((pre) => {
          return pre.concat([couponItem.id])
        })
      } else {
        notification.open({
          type: 'warning',
          message: '错误',
          description: '最多可以选择使用两张优惠券'
        })
      }
    }
  }

  const getCouponItemClassName = (couponItem: CouponItem, selectedKeys: number[]) => {
    if (selectedKeys.includes(couponItem.id)) {
      return [Styles.coupon_item, Styles.is_selected].join(' ')
    } else {
      return Styles.coupon_item
    }
  }

  const paginationChange = (pageNum: number) => {
    setCouponCurrentPage(pageNum)
  }

  const generateCouponItem = (selectedKeys: number[], item: any) => {
    return (
      <div
        key={ item.id }
        className={ getCouponItemClassName(item, selectedKeys) }
        onClick={ selectedCouponChange.bind(this, item) }
      >
        <div className={ 'pl-4 d-flex justify-content-center flex-column' }>
          <div className={ 'font-weight-bold' }>抵扣券</div>
          <div className={ 'text-coupon font-weight-bold' }>ID:{ item.id }</div>
          <div className={ 'text-coupon' }>限平台内使用
          </div>
        </div>
        <div className={ 'd-flex align-items-center flex-column' }>
          <div>
            <span className={ Styles.coupon_amount_unit }>¥</span>
            <span className={ Styles.coupon_amount }>{ item.amount }</span>
          </div>
          <div className={ 'text-danger' }>{ dayjs(item.expiredTime).format('YYYY.MM.DD') }过期</div>
        </div>
        {
          selectedKeys.includes(item.id)
            ? <div className={ Styles.selected_icon }>
              <CheckOutlined/>
            </div>
            : <></>
        }
      </div>
    )
  }

  const generateCouponControl = (info: any, selectedKeys: number[]) => {
    return (
      <div className={ Styles.coupon_control }>
        {
          info.records.length > 0
            ? (<>
              <div className={ 'mb-1' }>温馨提醒：优惠券抵扣最多可选择两张</div>
              <div className={ Styles.coupon_wrap }>
                {
                  info.records?.map((item: any) => {
                    return generateCouponItem(selectedKeys, item)
                  })
                }
              </div>
            </>)
            : <Empty description={ '暂无可用优惠券' }></Empty>
        }
      </div>
    )
  }

  const goBack = () => {
    router.navigate(`/preview-certificate?id=${ id }`)
  }

  return (
    <AppDarkPageContainer>
      <Spin spinning={ loading } tip={ spinText } fullscreen></Spin>
      <div className="px-4">
        <div className="app-card p-3">
          <div className="app-card-title">订单支付</div>
          <div>
            <div>作品版权登记证书出证时间选择：</div>
            <AppRadioButton
              className={ 'my-2' }
              value={ [selectedProductKey] }
              options={ productList }
              onChange={ selectProductChange }
            ></AppRadioButton>
          </div>
        </div>

        <div className="app-card p-3 mt-3">
          <div className="app-card-title">支付渠道</div>
          <div className={ 'my-3' }>
            <Form>
              <Form.Item label={ '支付方式选择' }>
                <Space>
                  {
                    payChannelList.map((item: any) => {
                      return <div
                        key={ item.type }
                        className={ getPayChannelClassName(activePayChannel, item.type) }
                        onClick={ togglePayChannel.bind(this, item.type) }
                      >
                        { payChannelMap[item.type].icon }
                        <span>{ payChannelMap[item.type].label }</span>
                        {
                          activePayChannel === item.type
                            ? <CheckOutlined className={ Styles.pay_channel_item_icon }/>
                            : <></>
                        }
                      </div>
                    })
                  }
                </Space>

              </Form.Item>
              <Form.Item label={ '是否使用优惠券' }>
                <Switch checked={ useCoupon } onChange={ useCouponChange }/>
              </Form.Item>
            </Form>
            {
              useCoupon
                ? generateCouponControl(couponInfo, selectedCoupon)
                : <></>
            }
          </div>
          <Divider/>
          <div className={ 'd-flex align-items-end justify-content-between' }>
            <div>
              <span className={ 'font-weight-bold' }>特别提醒：</span>
              <span>一旦提交审核，平台将提供代办服务，不论最终版权局是否核发登记证书本平台均不予退款，请谨慎点击提交！</span>
            </div>
            { getSelectedProductContent(selectedProductKey, productList, selectedCoupon) }
          </div>
        </div>

        <div className={ 'my-4 text-center' }>
          <Button
            className={ 'mr-4' }
            type={ 'primary' }
            ghost
            shape={ 'round' }
            style={ { width: 180 } }
            onClick={ goBack }
          >上一步</Button>
          <Button
            type={ 'primary' }
            shape={ 'round' }
            style={ { width: 180 } }
            loading={ loading }
            onClick={ beforeGenerateOrder.bind(this, activePayChannel) }
          >确定支付</Button>
        </div>
      </div>

      <WechatPayQrCodeModal
        ref={ wechatPayQrCodeModalRef }
        orderInfo={ orderInfo }
      ></WechatPayQrCodeModal>

      <ViewWalletModal
        ref={ viewWalletModalRef }
        orderInfo={ orderInfo }
        selectedProductKey={ selectedProductKey }
        productList={ productList }
        selectCoupon={ selectedCoupon }
        couponList={ couponInfo.records }
      ></ViewWalletModal>
    </AppDarkPageContainer>
  )
}

export default PayCost
