import { Button, Form, Input, Modal } from 'antd'
import { LockOutlined, MobileOutlined } from '@ant-design/icons'

import Styles from '../../index.module.scss'
import router from '../../../../routers'
import { phoneValidate } from '../../../../plugins/validates'
import { login } from '../../../../common/request'
import { ResponseCode } from '../../../../common/response-code'
import { useDispatch } from 'react-redux'
import { updateLoginStatus } from '../../../../store/slice/user-info-slice'
import { useState } from 'react'

type FormFields = {
  phone: string
  pwd: string
}

function LoginByPassword () {
  const [form] = Form.useForm()
  const dispatch = useDispatch()
  const [loading, setLoading] = useState(false)

  const validateFormField = () => {
    form.validateFields().then((fields: FormFields) => {
      loginAction(fields)
    }).catch((err) => {
      console.error(err)
    })
  }
  const loginAction = (params: FormFields) => {
    setLoading(true)
    login(params).then((res: any) => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        localStorage.setItem('access_token', res.data.token)
        localStorage.setItem('token_header', res.data.tokenHead)
        dispatch(updateLoginStatus({
          loginStatus: true
        }))

        if (window.location.search.indexOf('redirect_url') > -1) {
          router.navigate(window.decodeURIComponent(window.location.search.split('redirect_url=')[1]))
        } else {
          router.navigate('/copyright-service')
        }
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch((err: any) => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，登录失败'
      })
    })
  }

  return (
    <div className={ Styles.form_wrap }>
      <Form
        className={ 'login-form' }
        form={ form }
      >
        <Form.Item name={ 'phone' } rules={ [{ validator: phoneValidate }] }>
          <Input
            className={ Styles.login_input }
            placeholder="请输入您的手机号"
            prefix={ <MobileOutlined/> }
          />
        </Form.Item>
        <Form.Item name={ 'pwd' } rules={ [{ required: true, message: '密码不能为空' }] }>
          <Input
            type={ 'password' }
            prefix={ <LockOutlined/> }
            className={ Styles.login_input }
            placeholder="请输入密码"
          />
        </Form.Item>

      </Form>

      <Button
        className={ Styles.form_button }
        type={ 'primary' }
        size={ 'large' }
        loading={ loading }
        onClick={ validateFormField }
      >登录</Button>
    </div>
  )
}

export default LoginByPassword
