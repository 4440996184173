import PageContainer from '../../components/AppPageContainer'

function Bulletin () {

  return (
    <PageContainer>
      <div>作品公告</div>
    </PageContainer>
  )
}

export default Bulletin
