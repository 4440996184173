import axios, { AxiosResponse } from 'axios'
import router from '../routers'
import { notification } from 'antd'

import { ResponseCode } from '../common/response-code'

const CancelToken = axios.CancelToken
const requestPending: any[] = []

export interface AxiosRes extends AxiosResponse {
  code?: number
  msg?: string
}

const removeRepeatedRequest = function (config: any) {
  let i = 0

  for (const req of requestPending) {
    if (req.id === config.url + '&' + config.method) {
      req.cancel()
      requestPending.splice(i, 1)
    }
    i++
  }
}

const removeAllRequest = function () {
  for (const req of requestPending) {
    req.cancel()
  }
}

const gotoLoginPage = function () {
  const replacePath = window.encodeURIComponent(router.state.location.pathname)

  if (replacePath.indexOf(`/login`) < 0) {
    router.navigate(`/login?redirect_url=${ replacePath }`)
  } else {
    router.navigate(`/login`)
  }
}

const setLogout = function () {
  localStorage.setItem('access_token', '')
  localStorage.setItem('token_header', '')
}

export const Axios = axios.create({
  baseURL: process.env.NODE_ENV === 'development' ? 'https://www.skyqx.cn' : process.env.REACT_APP_URL,
  timeout: 15000
})

/**
 * POST传参序列化(添加请求拦截器)
 * 在发送请求之前做某件事
 */
Axios.interceptors.request.use(config => {
  if (window.localStorage.getItem('access_token') && !config.headers.Authorization) {
    config.headers.Authorization = window.localStorage.getItem('access_token')
  }

  config.cancelToken = new CancelToken((c) => {
    requestPending.push({ id: config.url + '&' + config.method, cancel: c })
  })
  return config
}, error => {
  return console.error(error)
})

// 返回状态判断(添加响应拦截器)
Axios.interceptors.response.use(res => {
  if (process.env.NODE_ENV !== 'development') {
    removeRepeatedRequest(res.config)
  }

  // 对响应数据做些事，
  if (res.data && res.data.code === ResponseCode.authFailed) {
    setLogout()
    removeAllRequest()
    gotoLoginPage()
    notification.open({
      type: 'warning',
      message: '登录失效',
      description: 'Token过期，请重新登录'
    })
    return res.data || {}
  }
  return res.data || {}
}, error => {
  if (error.response) {
    if (error.response.status === ResponseCode.authFailed) {
      removeAllRequest()
      gotoLoginPage()
      notification.open({
        type: 'warning',
        message: '登录失效',
        description: 'Token过期，请重新登录'
      })
    } else {
      throw new Error('unknown response code')
    }
    // 返回 response 里的错误信息
    return Promise.reject(error.response.data)
  } else {
    // Cancel request
    return console.error(new Error('request canceled'))
  }
})

export default Axios
