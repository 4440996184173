import { useRef } from 'react'
import { Divider, Image, notification } from 'antd'
import Styles from './index.module.scss'

import promotionLeftImg from '../../../../assets/images/promotion-left.png'
import promotionRightImg from '../../../../assets/images/promotion-right.png'
import { useSelector } from 'react-redux'
import { CopyOutlined, PictureOutlined } from '@ant-design/icons'
import ViewPosterModal from '../../../../components/ViewPosterModal'

function Promotion () {
  const viewPosterModalRef = useRef<any>(null)
  const userInfo = useSelector((state: any) => state.userInfo.userInfo)

  const ViewGeneratePosterModal = () => {
    viewPosterModalRef.current?.showModal()
  }

  const copyPromotionUrl = () => {
    let textarea = document.createElement('textarea')
    textarea.style.position = 'fixed'
    textarea.style.opacity = '0'
    textarea.value = `https://www.skyqx.cn/mobile/pages/index/index?code=${ userInfo.inviteCode }`
    document.body.appendChild(textarea)
    textarea.select()
    document.execCommand('copy')
    document.body.removeChild(textarea)

    notification.open({
      type: 'success',
      message: '操作成功',
      description: '已复制邀请链接'
    })
  }

  return (
    <div>
      <div className={ 'font-weight-bold' }>推广</div>
      <Divider style={ { marginTop: 20, marginBottom: 15 } }></Divider>
      <div className={ 'd-flex' }>
        <div className={ [Styles.promotion_item, Styles.left].join(' ') }>
          <div className={ 'd-flex align-items-start' }>
            <Image preview={ false } src={ promotionLeftImg } width={ 110 } height={ 70 }></Image>
            <div className={ 'pl-3 pt-2' }>
              <div className={ Styles.promotion_title }>我的专属邀请码</div>
              <div className={ Styles.promotion_invite_code }>{ userInfo.inviteCode }</div>
            </div>
          </div>
          <div className={ Styles.copy_btn } onClick={ copyPromotionUrl }>
            <CopyOutlined style={ { fontSize: 20 } }/>
            <div>复制</div>
          </div>
        </div>
        <div className={ [Styles.promotion_item, Styles.right].join(' ') }>
          <div className={ 'd-flex align-items-center' }>
            <Image preview={ false } src={ promotionRightImg } width={ 110 } height={ 70 }></Image>
            <div className={ 'pl-3 pt-2 font-weight-bold' }>在线生成推广海报，快捷邀请好友</div>
          </div>
          <div className={ Styles.generate_poster } onClick={ ViewGeneratePosterModal }>
            <PictureOutlined style={ { fontSize: 20 } }/>
            <div>生成</div>
          </div>
        </div>
      </div>

      <ViewPosterModal ref={ viewPosterModalRef }></ViewPosterModal>
    </div>
  )
}

export default Promotion
