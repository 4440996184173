import React, { ReactNode, useEffect, useState } from 'react'
import { Checkbox, Col, Row, Tooltip } from 'antd'

import Styles from './index.module.scss'

type RadioOptionItems = {
  value: any,
  label: string | ReactNode
  key: string
  description?: string
}

interface Props {
  options: RadioOptionItems[]
  children?: ReactNode
  value?: string[]
  className?: string
  onChange?: any
  controlBarVisible?: boolean
  multiple?: boolean
  showTooltip?: boolean
}

const AppRadioButton: React.FC<Props> = (
  {
    value,
    className,
    multiple,
    controlBarVisible,
    options,
    children,
    showTooltip,
    onChange
  }
) => {
  const [checked, setChecked] = useState<boolean>(false)

  useEffect(() => {
    if (options.length === value?.length) {
      setChecked(true)
    } else {
      setChecked(false)
    }
  }, [value])

  const handleCheckboxChange = () => {
    if (checked) {
      onChange?.([])
    } else {
      onChange?.(options.map(option => {
        return option.key
      }))
    }
  }

  const handleSelect = (option: RadioOptionItems, selectedKeys: string[] | undefined) => {
    if (selectedKeys?.includes(option.key)) {
      if (multiple) {
        onChange?.(selectedKeys?.filter(key => {
          return key !== option.key
        }))
      }
    } else {
      if (multiple) {
        onChange?.(selectedKeys ? [...selectedKeys, option.key] : [option.key])
      } else {
        onChange?.([option.key])
      }

    }
  }

  const getClassName = (selectedKeys: string[] | undefined, value: string) => {
    if (selectedKeys?.includes(value)) {
      return [Styles.app_radio_item, Styles.is_active].join(' ')
    } else {
      return Styles.app_radio_item
    }
  }

  const generateRadioItem = (list: RadioOptionItems[], selectedKeys: string[] | undefined) => {
    return list?.map(option => {
      return (
        <Col
          key={ option.key }
          span={ 3 }>
          <Tooltip title={ showTooltip ? option.description : ''} color={'#ffffff'} overlayInnerStyle={{ color: '#222222' }}>
            <div
              className={ getClassName(selectedKeys, option.value) }
              onClick={ handleSelect.bind(this, option, selectedKeys) }
              style={ { marginTop: 10 } }>
              { option.label }
            </div>
          </Tooltip>
        </Col>
      )
    })
  }

  const generateControlBar = (visible: boolean | undefined, checkedStatus: boolean) => {
    if (visible) {
      return (
        <div className={ Styles.app_radio_checkbox_wrap }>
          <Checkbox checked={ checkedStatus } onChange={ handleCheckboxChange }>全选</Checkbox>
        </div>
      )
    } else {
      return <></>
    }
  }

  return (
    <div className={className}>
      { generateControlBar(controlBarVisible, checked) }

      <Row className={ Styles.app_radio } gutter={ 12 }>
        { generateRadioItem(options, value) }
      </Row>
      { children }
    </div>
  )
}

export default AppRadioButton
