import { configureStore } from '@reduxjs/toolkit'

import userInfoReducer, { userInfoStateType } from './slice/user-info-slice'
import nationInfoReducer, { nationInfoStateType } from './slice/nation-info-slice'
import regionInfoReducer, { regionInfoStateType } from './slice/region-info-slice'

export type ReducerType ={
  userInfo: userInfoStateType,
  nation: nationInfoStateType,
  regionList: regionInfoStateType
}

export default configureStore({
  reducer: {
    userInfo: userInfoReducer,
    nation: nationInfoReducer,
    regionList: regionInfoReducer
  }
})
