import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Divider, Image, Button, Modal, Spin } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'

import Styles from './index.module.scss'
import { getVerifyInfo } from '../../../../common/request'
import { ResponseCode } from '../../../../common/response-code'
import NoCertification from '../../../../assets/images/no-certification.png'
import CertificationSuccess from '../../../../assets/images/certification-success.png'
import RealNameAuthenticationModal, { ModalRefProps } from '../../../../components/RealNameAuthenticationModal'
import { strEncryption } from '../../../../plugins/units'

function Certification () {
  const certificationRef = useRef<ModalRefProps>(null)
  const userInfo = useSelector((state: any) => state.userInfo.userInfo)
  const [userVerifyInfo, setUserVerifyInfo] = useState<any>({})
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    getUserVerifyInfo()
  }, [])

  const getUserVerifyInfo = () => {
    setLoading(true)
    getVerifyInfo().then((res: any) => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        setUserVerifyInfo(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch((err: any) => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，'
      })
    })
  }

  const verifySuccess= () => {
    getUserVerifyInfo()
  }

  const showCertification = () => {
    certificationRef.current?.showModal()
  }

  const generateCertificationContent = (verify: boolean) => {
    if (verify) {
      return (<>
        <div className={ 'text-dark' }>
          <div>
            <span>认证类型：</span>
            <span>个人认证</span>
          </div>
          <div>
            <span>姓名：</span>
            <span>{ userVerifyInfo.realName }</span>
          </div>
          <div>
            <span>身份证号：</span>
            <span>{ strEncryption(userVerifyInfo.idCard, 5, 9) }</span>
          </div>
          <div>
            <span>联系电话：</span>
            <span>{ strEncryption(userVerifyInfo.phone, 3, 4) }</span>
          </div>
          <div className={ 'mt-5' }>
            <Button
              type={ 'primary' }
              shape={ 'round' }
              onClick={ showCertification }
            >修改</Button>
          </div>
        </div>
        <div>
          <Image src={ CertificationSuccess }></Image>
        </div>
      </>)
    } else {
      return (<>
        <div>
          <div>姓名：未认证</div>
          <div>身份证号：未认证</div>
          <div>联系电话：未认证</div>
          <div className={ 'mt-5' }>
            <Button
              type={ 'primary' }
              shape={ 'round' }
              onClick={ showCertification }
            >去认证</Button>
          </div>
        </div>
        <div>
          <Image src={ NoCertification }></Image>
        </div>
      </>)
    }
  }

  return (
    <div>
      <div className={ 'font-weight-bold' }>实名认证</div>
      <Divider style={ { marginTop: 10, marginBottom: 15 } }></Divider>
      <div className={ Styles.massage_info }>
        <div className={ 'd-flex align-items-baseline' }>
          <div className={ 'mx-2 mt-2' }>
            <InfoCircleOutlined className={ 'text-danger' }/>
          </div>
          <div>
            <div><span>应国家法律法规要求，您需要完成实名认证。</span>
              <span className={ 'text-danger' }>实名认证一经审核通过，非平台认可的法定事由不得修改。</span></div>
            <div className={ 'font-weight-bold' }>您所填写的信息仅用于实名认证，平台对于您的信息严格保密。</div>
          </div>
        </div>
      </div>
      <Spin spinning={loading}>
        <div>
          <div className={ Styles.certification_card }>
            { generateCertificationContent(userInfo.verify) }
          </div>
        </div>
      </Spin>

      <RealNameAuthenticationModal
        ref={ certificationRef }
        userVerifyInfo={ userVerifyInfo }
        verifySuccess={ verifySuccess }
      ></RealNameAuthenticationModal>
    </div>
  )
}

export default Certification
