import { useEffect, useState } from 'react'
import { Checkbox, Image, Button, Modal, Spin } from 'antd'

import AppDarkPageContainer from '../../components/AppDarkPageContainer'
import Styles from './index.module.scss'
import { ExclamationCircleOutlined } from '@ant-design/icons'
import router from '../../routers'
import { useSearchParams } from 'react-router-dom'
import { commitCopyRightAgain, getCopyRightApplicationDetail, getCopyRightPayStatus, getPreViewCertificateImg } from '../../common/request'
import { FILE_URL } from '../../common/constant'
import { ResponseCode } from '../../common/response-code'

function PreviewCertificate () {
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  const [isAgree, setIsAgree] = useState(false)
  const [bulletinVisible, setBulletinVisible] = useState(false)
  const [confirmModalVisible, setConfirmModalVisible] = useState(false)
  const [certificateInfo, setCertificateInfo] = useState<any>(null)
  const [loading, setLoading] = useState(false)
  const [payStatus, setPayStatus] = useState(0)
  const [copyRightDetail, setCopyRightDetail] = useState<any>({})

  useEffect(() => {
    if (!certificateInfo) {
      getCertificateImg()
    }
  }, [certificateInfo])

  useEffect(() => {
    getCopyRightApplicationDetailAction()
    getCopyRightPayStatusAction()
  }, [])

  const getCopyRightPayStatusAction = () => {
    setLoading(true)
    getCopyRightPayStatus({ id: id }).then((res: any) => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        setPayStatus(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.message
        })
      }
    }).catch((err: any) => {
      console.error(err)
      setLoading(false)
      Modal.error({
        title: '错误',
        content: '网络错误，查询申请状态失败'
      })
    })
  }

  const getCopyRightApplicationDetailAction = () => {
    setLoading(true)
    getCopyRightApplicationDetail({ id: searchParams.get('id') }).then((res: any) => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        setCopyRightDetail(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch((err: any) => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，版权申请失败'
      })
    })
  }

  const showConfirmModal = () => {
    if (isAgree) {
      setConfirmModalVisible(true)
    } else {
      setBulletinVisible(true)
    }
  }

  const showBulletin = () => {
    setBulletinVisible(true)
  }

  const commitCopyRightAgainAction =() => {
    commitCopyRightAgain({ id: id }).then((res: any) => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        router.navigate(`/pay-success?titleText=提交成功`)
      } else {
        Modal.error({
          title: '错误',
          content: res.message
        })
      }
    }).catch((err: any) => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，提交失败'
      })
    })
  }

  const getCertificateImg = () => {
    getPreViewCertificateImg({ id: searchParams.get('id') }).then((res: any) => {
      if (res.code === ResponseCode.success) {
        setCertificateInfo({
          cert: FILE_URL + res.data.cert,
          cover: FILE_URL + res.data.cover
        })
      }
    })
  }

  const nextStep = () => {
    if (payStatus === 1) {
      if (copyRightDetail.auditStatus === 3 || copyRightDetail.auditStatus === 4) {
        commitCopyRightAgainAction()
      } else {
        router.navigate(`/pay-success?titleText=提交成功`)
      }
    } else {
      router.navigate(`/pay-cost?id=${id}`)
    }
  }

  const handleBack = () => {
    router.navigate(`/copyright-application?id=${id}`)
  }

  return (
    <AppDarkPageContainer>
      <div className="px-4">
        <div className="app-card p-3">
          <div className="app-card-title">版权证书生成预览</div>
          {
            certificateInfo?.cert
              ? <div className={ 'mt-3 d-flex align-items-center justify-content-center' }>
                <Image src={ certificateInfo.cert }></Image>
              </div>
              : <></>
          }

          {
            certificateInfo?.cover
              ? <div className={ 'mt-3 d-flex align-items-center justify-content-center' }>
                <Image src={ certificateInfo.cover }></Image>
              </div>
              : <></>
          }
        </div>
        {/*<div className="app-card p-3 mt-3">*/}
        {/*  <div className="app-card-title">数字藏品证书生成预览</div>*/}
        {/*  <div className={ 'mt-3 d-flex align-items-center justify-content-center' }>*/}
        {/*    <Image src={ SampleCertificateImg3 }></Image>*/}
        {/*  </div>*/}
        {/*</div>*/}
        <div className="app-card p-3 mt-3">
          <Checkbox checked={ isAgree } onClick={ () => {
            setBulletinVisible(true)
          } }>
            <span>我已阅读</span>
            <Button type={ 'link' } onClick={ showBulletin }>《登记须知》</Button>
          </Checkbox>
          <div>
            <span className={ 'font-weight-bold' }>温馨提示：</span>
            <span>请仔细核对填写信息是否正确，以免对您造成不必要的麻烦</span>
          </div>
        </div>

        <div className={ 'my-4 text-center' }>
          <Button
            className={ 'mr-4' }
            type={ 'primary' }
            ghost
            shape={ 'round' }
            style={ { width: 180 } }
            onClick={ handleBack }
          >上一步</Button>
          <Button
            type={ 'primary' }
            shape={ 'round' }
            style={ { width: 180 } }
            onClick={ showConfirmModal }
          >{ payStatus === 1 ? '重新提交': '下一步' }</Button>
        </div>
      </div>

      <Modal
        width={ 1000 }
        title={ '登记须知' }
        open={ bulletinVisible }
        onCancel={ () => {
          setBulletinVisible(false)
        } }
        footer={ false }
        styles={
          {
            header: { background: 'transparent' },
            content: {
              background: `
            linear-gradient(-45deg, rgb(211, 238, 255), #ffffff, transparent),
            linear-gradient(45deg, rgb(230, 210, 255), #ffffff, transparent),
            linear-gradient(135deg, rgb(218, 251, 255), #ffffff, transparent),
            linear-gradient(225deg, rgb(252, 233, 239), #ffffff, transparent)
            `,
              backgroundBlendMode: 'multiply'
            }
          }
        }
      >
        <div className={ Styles.app_modal_content }>
          <div>一、公民、法人或者其他组织的作品，不论是否发表，依法享有著作权，即作品创作完成后即享有著作权，不需要履行任何登记手续。著作权人行使著作权时，只要不违反《著作权法》第四条“不得违反宪法和法律，不损害公共利益”的法律规定，因创作作品而获得的著作权受到《著作权法》的保护。作品登记是自原登记，作品登记不是著作权获得《著作权法保护》的必经程序。</div>
          <br/>
          <div>二、《作品登记证书》由作品登记机构核查申请材料后发给。《作品登记证书》是解决可能发生的著作权纠纷的初步证据，证书不是作品的学术价值和创新成果的证明材料，作品的表达方式、作品中所包含的方法、思想等不是著作权法保护的客体。</div>
          <br/>
          <div>三、作品自愿登记工作依照国家版权局《作品自愿登记试行办法》进行。贵州省版权登记平台承接贵州省内、外著作权人作品的自愿登记工作。</div>
          <br/>
          <div>四、作品登记申请者应是作者、其他享有著作权的公民、法人或非法人单位和专有权所有人及其代理人。</div>
          <br/>
          <div>五、申请作品登记应当提交下列文件:</div>
          <div>1、线上填写作品说明书(创作意图，作品独创性)；</div>
          <div>2、实名认证(个人上传身份证，企业上传营业执照其他著作权人提交组织身份证明)；</div>
          <div>3、上传清晰原件(各种材料载体均可，样本、手稿复印件等) ；</div>
          <div>4、附件上传其他证明作品权利归属的必要材料(权利归属证明材料、合作作者署名方式确认材料、代理人授权委托书等）</div>
          <br/>
          <div>六、属于下列情况之一的作品，不予登记：</div>
          <div>1、不受著作权法保护的作品;</div>
          <div>2、超过著作权保护期的作品;</div>
          <div>3、依法禁止出版、传播的作品。</div>
          <br/>
          <div>七、有下列情况的，撤销其登记：</div>
          <div>1、登记后发现有本须知第六条所指情况的；</div>
          <div>2、登记后发现与申请人提交的权利证明材料所证明的事实不相符的；</div>
          <div>3、申请人申请撤销原作品登记的；</div>
          <div>4、登记后发现是重复登记的。</div>
        </div>
        <div className={ 'mt-3 text-center' }>
          <Button
            type={ 'primary' }
            shape={ 'round' }
            style={ { width: 150 } }
            onClick={ () => {
              setBulletinVisible(false)
              setIsAgree(true)
            } }
          >我已阅读并完全理解</Button>
        </div>
      </Modal>

      <Modal
        open={ confirmModalVisible }
        onCancel={ () => {
          setConfirmModalVisible(false)
        } }
        footer={ false }
        styles={
          {
            header: { background: 'transparent' },
            content: {
              background: `
            linear-gradient(-45deg, rgb(211, 238, 255), #ffffff, transparent),
            linear-gradient(45deg, rgb(230, 210, 255), #ffffff, transparent),
            linear-gradient(135deg, rgb(218, 251, 255), #ffffff, transparent),
            linear-gradient(225deg, rgb(252, 233, 239), #ffffff, transparent)
            `,
              backgroundBlendMode: 'multiply'
            }
          }
        }
      >
        <div className={ 'text-center' }>
          <ExclamationCircleOutlined className={ 'text-primary mb-2' } style={ { fontSize: '36px' } }/>
          <div className={ 'mb-3 font-weight-bold' }>温馨提示</div>
          <div className={ 'mb-4' }>请核对填写信息填写是否正确、属实？</div>
        </div>
        <div className={ 'd-flex justify-content-center' }>
          <Button
            className={ 'mr-4' }
            type={ 'primary' }
            ghost
            shape={ 'round' }
            style={ { width: '180px' } }
            onClick={ () => {
              setConfirmModalVisible(false)
            } }
          >取消</Button>
          <Button
            type={ 'primary' }
            shape={ 'round' }
            style={ { width: '180px' } }
            onClick={ nextStep }
          >确定</Button>
        </div>
      </Modal>

      <Spin spinning={loading} fullscreen={true}></Spin>
    </AppDarkPageContainer>
  )
}

export default PreviewCertificate
