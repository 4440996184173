import { Image, Layout, Menu } from 'antd'
import React, { useEffect, useState, ReactNode } from 'react'
import { Link } from 'react-router-dom'
import { Content, Header } from 'antd/es/layout/layout'
import { MenuProps } from 'antd/es/menu'

import router from '../../routers'
import PageFooter from '../PageFooter'
import Styles from './index.module.scss'
import AppUserDrawer from '../AppUserDrawer'
import HeaderLogo from '../../assets/images/header-logo.png'
import SideBg from '../../assets/images/side-bg.jpg'

type Props = {
  children: ReactNode
  bgUrl?: string
}

function PageContainer ({ children, bgUrl = SideBg }: Props) {
  const [currentURLKey, setCurrentURLKey] = useState(window.location.pathname.slice(1))

  const menuList: MenuProps['items'] = [
    // {
    //   key: 'home',
    //   label: <Link to="/home">首页</Link>
    // },
    {
      key: 'copyright-service',
      label: <Link to="/copyright-service">版权服务</Link>
    },
    // {
    //   key: 'electric-copyright',
    //   label: <Link to="/electric-copyright">数字版权</Link>
    // },
    // {
    //   key: 'electric-collection',
    //   label: <Link to="/electric-collection">数字藏品</Link>
    // },
    // {
    //   key: 'bulletin',
    //   label: <Link to="/bulletin">作品公告</Link>
    // },
    {
      key: 'help-center',
      label: <Link to="/help-center">帮助中心</Link>
    }
  ]

  useEffect(() => {
    router.subscribe((e) => {
      setCurrentURLKey(e.location.pathname.slice(1))
    })
  }, [])

  return (
    <div className={ Styles.page_wrap }>
      <div className={ Styles.app_sider } style={{ backgroundImage: `url(${bgUrl})` }}>
        <div>
          <Image preview={ false } src={ HeaderLogo } width={220} height={100}></Image>
        </div>
        <div className={ Styles.app_sider_footer }>
          <PageFooter theme={ 'light' }></PageFooter>
        </div>
      </div>
      <Layout className={ Styles.app_container }>
        <Header className={ Styles.app_header }>
          <Menu
            className={ 'app-menu flex-grow-1' }
            mode="horizontal"
            selectedKeys={ [currentURLKey] }
            items={ menuList }
          />

          <AppUserDrawer></AppUserDrawer>
        </Header>
        <Content className={ Styles.app_body }>
          { children }
        </Content>
      </Layout>
    </div>
  )
}

export default PageContainer
