import React from 'react'
import PageContainer from '../../components/AppPageContainer'
import Styles from './index.module.scss'
import { Button, Image } from 'antd'
import ApplyProcessImg from '../../assets/images/apply-process.png'
import HelpCenterBg from '../../assets/images/help-center-bg.jpg'
import { FILE_URL } from '../../common/constant'

function HelpCenter () {
  const openHelpDocument = () => {
    window.open(`${FILE_URL}system/manu.pdf`)
  }

  return (
    <PageContainer bgUrl={ HelpCenterBg }>
      <div className={ Styles.page_content }>
        <div className={ Styles.page_title }>系统操作手册</div>
        <div className={ Styles.page_description }>
          您好！创巢文化知识产权数藏平台通过推动数字藏品“版权登记” ，即上链前的作品进行版权的登记，可以有效保护创作者的权益， 同时也能更好地界定作品归属，保障以唯一性、稀缺性为基础的数字作品权益与作品交易的权属，从源头预防侵权事件。 该手册为创巢文化知识产权数藏平台对于PC端所制定，希望您通过该手册能更好使用创巢文化知识产权数藏平台功能。
        </div>
        <div className={ Styles.help_document }>
          <Button className={ 'pl-0' } type={ 'link' } onClick={openHelpDocument}>著作权登记申请流程详情？(查看详情）</Button>
        </div>
        <div className={ Styles.process_title }>作品登记申请流程图</div>
        <div className={ 'p-3' }>
          <Image preview={ false } src={ ApplyProcessImg }></Image>
        </div>

        <div className={ Styles.page_footer }>
          <span>如您还有疑问，请致电平台联系电话或关注创巢文化知识产权数藏平台公众号：</span>
          <span className={ 'font-weight-bold' }>创巢文化知识产权数藏平台</span>
          <span>，并进行留言，平台工作人员将第一时间对您进行回复。</span>
        </div>
      </div>
    </PageContainer>
  )
}

export default HelpCenter
