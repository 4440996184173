import { useEffect, useState } from 'react'
import { getCouponPage } from '../../../../common/request'
import { ResponseCode } from '../../../../common/response-code'
import { Divider, Modal, Pagination, Spin, Empty, Form, Select, InputNumber } from 'antd'
import Styles from './index.module.scss'
import { InfoCircleOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import CouponImg from '../../../../assets/images/coupon-bg.png'
import CouponUsedImg from '../../../../assets/images/coupon-used.png'
import CouponExpiredImg from '../../../../assets/images/coupon-expired.png'
import CouponWillExpireImg from '../../../../assets/images/coupon-will-expire.png'
import { throttle } from '../../../../plugins/units'
import { numberValidate } from '../../../../plugins/validates'

function CouponManagement () {
  const [form] = Form.useForm()
  const [currentPage, setCurrentPage] = useState(1)
  const [loading, setLoading] = useState(false)
  const [tableInfo, setTableInfo] = useState({
    records: [],
    total: 0
  })

  const couponStatusOptions = [
    {
      label: '未使用',
      value: 0
    },
    {
      label: '已使用',
      value: 1
    },
    {
      label: '即将过期',
      value: 3
    },
    {
      label: '已过期',
      value: 4
    }
  ]

  useEffect(() => {
    getCouponListAction()
  }, [currentPage])

  const getCouponListAction = () => {
    form.validateFields().then(fields => {
      setLoading(true)
      getCouponPage({
        ...fields,
        current: currentPage,
        size: 9
      }).then((res: any) => {
        setLoading(false)
        if (res.code === ResponseCode.success) {
          setTableInfo(res.data)
        } else {
          Modal.error({
            title: '错误',
            content: res.msg
          })
        }
      }).catch((err: any) => {
        console.error(err)
        setLoading(false)
        Modal.error({
          title: '错误',
          content: '网络错误，查询优惠券列表失败'
        })
      })
    }).catch(err => {
      console.error(err)
    })
  }

  const getCouponImg = (status: number) => {
    switch (status) {
      case 0:
        return `url(${ CouponImg })`
      case 1:
        return `url(${ CouponUsedImg })`
      case 3:
        return `url(${ CouponWillExpireImg })`
      case 4:
        return `url(${ CouponExpiredImg })`
      default:
        return `url(${ CouponImg })`
    }
  }

  const generateCouponList = (list: any[]) => {
    return list?.length > 0
      ? list?.map(item => {
        return <div key={ item.id } className={ Styles.coupon_item } style={ { backgroundImage: getCouponImg(item.couponStatus) } }>
          <div className={ 'pl-4 d-flex justify-content-center flex-column' }>
            <div className={ 'font-weight-bold' }>抵扣券</div>
            <div className={ 'text-coupon font-weight-bold' }>ID:{ item.id }</div>
            <div className={ 'text-coupon' }>限平台内使用
            </div>
          </div>
          <div className={ 'd-flex align-items-center flex-column' }>
            <div>
              <span className={ Styles.coupon_amount_unit }>¥</span>
              <span className={ Styles.coupon_amount }>{ item.amount }</span>
            </div>
            <div className={ 'text-danger' }>{ dayjs(item.expiredTime).format('YYYY.MM.DD') }过期</div>
          </div>
        </div>
      })
      : <Empty className={ 'w-100' } description={ '暂无可用优惠券' }></Empty>
  }

  const paginationChange = (pageNum: number) => {
    setCurrentPage(pageNum)
  }

  return (
    <div>
      <div className={ 'font-weight-bold' }>优惠券管理</div>
      <Divider style={ { marginTop: 10, marginBottom: 15 } }></Divider>
      <div className={ Styles.massage_info }>
        <div className={ 'py-1 d-flex align-items-baseline' }>
          <div className={ 'mx-2' }>
            <InfoCircleOutlined className={ 'text-danger' }/>
          </div>
          <div>优惠券说明：可作为版权申请费用使用，一次性限用2张。平台优惠券最终解释权归平台所有。</div>
        </div>
      </div>

      <Spin spinning={ loading }>
        <Form
          className={ 'mt-2' }
          form={ form }
          layout={ 'inline' }
          initialValues={ {
            id: '',
            couponStatus: 0
          } }
          onValuesChange={ throttle(getCouponListAction, 1000) }
        >
          <Form.Item
            label={ '优惠券ID' }
            name={ 'id' }
            trigger={'onInput'}
            rules={[ { validator: numberValidate } ]}
          >
            <InputNumber
              controls={ false }
              placeholder={ '输入优惠券ID' }
              style={ { width: 200 } }
            ></InputNumber>
          </Form.Item>
          <Form.Item label={ '优惠券状态' } name={ 'couponStatus' }>
            <Select
              options={ couponStatusOptions }
              placeholder={ '选择优惠券状态' }
              style={ { width: 100 } }
            ></Select>
          </Form.Item>
        </Form>
        <div className={ 'py-3 d-flex justify-content-between flex-wrap' } style={ { minHeight: 100 } }>
          { generateCouponList(tableInfo.records) }
        </div>
      </Spin>
      <div className={ 'd-flex justify-content-end' }>
        <Pagination current={ currentPage } total={ tableInfo.total } onChange={ paginationChange }/>
      </div>
    </div>
  )
}

export default CouponManagement
