import { Divider, Form, Input, Button, DatePicker, Table, Modal, Select } from 'antd'
import { SearchOutlined } from '@ant-design/icons'
import React, { useEffect, useRef, useState } from 'react'
import { InfoCircleOutlined } from '@ant-design/icons'

import { getCopyrightPage } from '../../../../common/request'
import { ResponseCode } from '../../../../common/response-code'
import router from '../../../../routers'
import dayjs from 'dayjs'
import ViewCopyrightDetailModal, { ModalRefProps } from '../../../../components/ViewCopyrightDetailModal'
import ViewCopyrightCertificateModal, { CertificateModalProps } from '../../../../components/ViewCopyrightCertificateModal'
import { ColumnsType } from 'antd/es/table'
import ErrorModal from './ErrorModal'
import { ErrorRefModal } from './ErrorModal'

type SearchParams = {
  current: number
  size: number
  productName?: string
  applyStatus?: number
  payStatus?: number
  registerBuildStatus?: number
  startTime?: string
  endTime?: string
  timeRange?: any
}

interface DataType {
  key: React.Key;
  id: any;
  name: string;
  applyStatus: number;
  payStatus: number;
  registerBuildStatus: number;
  registerId: any;
  registerTime: string;
  auditTime: string;
  createTime: string;
  operation: any
}

interface ListType {
  records: DataType[];
  total: number;
}

function CopyrightRegistrationManagement () {
  const errorModalRef = useRef<ErrorRefModal>(null)
  const [form] = Form.useForm()
  const viewCopyrightCertificateModalRef = useRef<CertificateModalProps>(null)
  const viewCopyrightDetailModalRef = useRef<ModalRefProps>(null)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState(10)
  const [listInfo, setListInfo] = useState<ListType>({
    records: [],
    total: 0
  })
  const [loading, setLoading] = useState(false)

  const applyStatusOptions = [
    {
      label: '全部',
      value: ''
    },
    {
      label: '待系统提交审核',
      value: 0
    },
    {
      label: '审核中',
      value: 1
    },
    {
      label: '审核通过',
      value: 2
    },
    {
      label: '审核未通过',
      value: 3
    },
    {
      label: '申请失败',
      value: 4
    }
  ]

  const payStatusOptions = [
    {
      label: '全部',
      value: ''
    },
    {
      label: '订单退款',
      value: -3
    },
    {
      label: '过期未支付',
      value: -2
    },
    {
      label: '支付失败',
      value: -1
    },
    {
      label: '未支付',
      value: 0
    },
    {
      label: '已支付',
      value: 1
    }
  ]

  const registerBuildStatusOptions = [
    {
      label: '全部',
      value: ''
    },
    {
      label: '未生成',
      value: 0
    },
    {
      label: '已生成',
      value: 1
    },
    {
      label: '已撤销',
      value: 2
    }
  ]

  const columns: ColumnsType<DataType> = [
    {
      title: '申请ID',
      key: 'id',
      dataIndex: 'id',
      fixed: 'left'
    },
    {
      title: '作品名称',
      key: 'name',
      dataIndex: 'name',
      fixed: 'left'
    },
    {
      title: '审核状态',
      key: 'applyStatus',
      dataIndex: 'applyStatus',
      render: (record: number, row: any) => {
        return getApplyStatusText(record, row)
      }
    },
    {
      title: '支付状态',
      key: 'payStatus',
      dataIndex: 'payStatus',
      render: (record: number) => {
        return getPayStatusText(record)
      }
    },
    {
      title: '证书申请状态',
      key: 'registerBuildStatus',
      dataIndex: 'registerBuildStatus',
      render: (record: number) => {
        return getRegisterBuildStatusText(record)
      }
    },
    {
      title: '证书ID',
      key: 'registerId',
      dataIndex: 'registerId',
      render: (record: any) => {
        return record || '-'
      }
    },
    {
      title: '证书发放时间',
      key: 'registerTime',
      dataIndex: 'registerTime',
      render: (record: any) => {
        return record || '-'
      }
    },
    {
      title: '审核时间',
      key: 'auditTime',
      dataIndex: 'auditTime',
      render: (record: any) => {
        return record || '-'
      }
    },
    {
      title: '创建时间',
      key: 'createTime',
      dataIndex: 'createTime',
      render: (record: any) => {
        return record || '-'
      }
    },
    {
      title: '操作',
      key: 'operation',
      dataIndex: 'operation',
      fixed: 'right',
      render: (record: any, row: any) => {
        return (<>
          <div>
            <Button
              className={ 'p-1' }
              type={ 'link' }
              onClick={ showCopyrightDetailModal.bind(this, row) }
            >查看</Button>
            {
              (row.applyStatus !== 2 && row.applyStatus !== 1)
                ? <Button
                  className={ 'p-1' }
                  type={ 'link' }
                  onClick={ navigatorToDetail.bind(this, row) }
                >编辑</Button>
                : <></>
            }
            {
              row.applyStatus === 2
                ? <Button
                  className={ 'p-1' }
                  type={ 'link' }
                  onClick={ viewCopyrightCertificate.bind(this, row) }
                >查看证书</Button>
                : <></>
            }
          </div>
        </>)
      }
    }
  ]

  useEffect(() => {
    getCopyrightList()
  }, [pageSize, currentPage])

  const showErrorModal = (row: any) => {
    errorModalRef.current?.showModal({
      title: row.applyStatus === 3 ? '审核不通过' : '申请失败',
      content: row.applyStatus === 3 ? row.auditOpinion : row.applyErrorMsg,
    })
  }

  const getApplyStatusText = (key: number, row: any) => {
    let content

    switch (key) {
      case 0:
        content = <div className={ 'text-primary' }>待系统提交申请</div>
        break
      case 1:
        content = <div className={ 'text-primary' }>版权申请中</div>
        break
      case 2:
        content = <div className={ 'text-success' }>审核通过</div>
        break
      case 3:
        content = <div className={ 'text-danger' }>
          <span className={'mr-2'}>审核不通过</span>
          <InfoCircleOutlined className={'text-blue'} onClick={ showErrorModal.bind(this, row) }></InfoCircleOutlined>
        </div>
        break
      case 4:
        content = <div className={ 'text-danger' }>
          <span className={'mr-2'}>申请失败</span>
          <InfoCircleOutlined className={'text-blue'} onClick={ showErrorModal.bind(this, row) }></InfoCircleOutlined>
        </div>
        break
    }

    return content
  }

  const getPayStatusText = (key: number) => {
    let content

    switch (key) {
      case -3:
        content = <div className={ 'text-blue' }>已退款</div>
        break
      case -2:
        content = <div className={ 'text-danger' }>过期未支付</div>
        break
      case -1:
        content = <div className={ 'text-danger' }>支付失败</div>
        break
      case 0:
        content = <div className={ 'text-warning' }>未支付</div>
        break
      case 1:
        content = <div className={ 'text-success' }>已支付</div>
        break
      default:
        content = <div className={ 'text-warning' }>未支付</div>
    }

    return content
  }

  const getRegisterBuildStatusText = (key: number) => {
    let content

    switch (key) {
      case 0:
        content = <div className={ 'text-primary' }>未生成</div>
        break
      case 1:
        content = <div className={ 'text-success' }>已生成</div>
        break
      case 2:
        content = <div className={ 'text-danger' }>已撤销</div>
        break
      default:
        content = <div className={ 'text-primary' }>未生成</div>
    }

    return content
  }

  const viewCopyrightCertificate = (row: any) => {
    viewCopyrightCertificateModalRef.current?.showModal(row.id)
  }

  const navigatorToDetail = (row: any) => {
    router.navigate(`/copyright-application?id=${ row.id }`)
  }

  const showCopyrightDetailModal = (row: any) => {
    viewCopyrightDetailModalRef.current?.showModal(row.id)
  }

  const getParams = () => {
    let params: SearchParams = {
      current: currentPage,
      size: pageSize
    }
    const searchData = form.getFieldsValue()

    Object.keys(searchData).forEach((key) => {
      if (searchData[key]) {
        if (key === 'timeRange' && searchData['timeRange']) {
          params['startTime'] = searchData.timeRange.length > 0 ? dayjs(searchData.timeRange[0]).format('YYYY-MM-DD') : ''
          params['endTime'] = searchData.timeRange.length > 0 ? dayjs(searchData.timeRange[0]).format('YYYY-MM-DD') : ''
        } else {
          // @ts-ignore
          params[key] = searchData[key]
        }
      }
    })

    return params
  }

  const getCopyrightList = () => {
    const params = getParams()

    setLoading(true)
    getCopyrightPage(params).then((res: any) => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        setListInfo(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch((err: any) => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询版权列表失败'
      })
    })
  }

  const paginationChange = (current: number, size: number) => {
    setPageSize(size)
    if (size === pageSize) {
      setCurrentPage(current)
    } else {
      setCurrentPage(1)
    }
  }

  return (
    <div>
      <div className={ 'font-weight-bold' }>版权登记管理</div>
      <Divider style={ { marginTop: 20, marginBottom: 15 } }></Divider>
      <div>
        <Form
          form={ form }
          layout={ 'inline' }
          initialValues={ {
            applyStatus: '',
            payStatus: '',
            registerBuildStatus: '',
            timeRange: []
          } }
        >
          <Form.Item
            className={ 'mb-3' }
            label={ '作品名称' }
            name={ 'productName' }
          >
            <Input placeholder={ '输入作品名称' }></Input>
          </Form.Item>
          <Form.Item
            className={ 'mb-3' }
            label={ '审核状态' }
            name={ 'applyStatus' }
          >
            <Select options={ applyStatusOptions } style={ { width: 160 } }></Select>
          </Form.Item>
          <Form.Item
            className={ 'mb-3' }
            label={ '支付状态' }
            name={ 'payStatus' }
          >
            <Select options={ payStatusOptions } style={ { width: 160 } }></Select>
          </Form.Item>
          <Form.Item
            className={ 'mb-3' }
            label={ '证书申请状态' }
            name={ 'registerBuildStatus' }
          >
            <Select options={ registerBuildStatusOptions } style={ { width: 160 } }></Select>
          </Form.Item>
          <Form.Item
            className={ 'mb-3' }
            label={ '创建时间范围' }
            name={ 'timeRange' }
          >
            <DatePicker.RangePicker showTime/>
          </Form.Item>
          <Form.Item
            className={ 'mb-3' }
            label={ '' }
          >
            <Button
              type={ 'primary' }
              icon={ <SearchOutlined/> }
              onClick={ getCopyrightList }
            >搜索</Button>
          </Form.Item>
        </Form>

        <Table
          size={ 'small' }
          loading={ loading }
          columns={ columns }
          dataSource={ listInfo.records }
          scroll={{
            x: 1500,
            y: 400
          }}
          rowKey={ (record: DataType) => record.id }
          pagination={ {
            current: currentPage,
            size: 'default',
            total: listInfo.total,
            showTotal: (total) => `共${total}`,
            onChange: paginationChange
          } }
        ></Table>
      </div>

      <ViewCopyrightDetailModal ref={ viewCopyrightDetailModalRef }></ViewCopyrightDetailModal>
      <ViewCopyrightCertificateModal ref={ viewCopyrightCertificateModalRef }></ViewCopyrightCertificateModal>
      <ErrorModal ref={errorModalRef}></ErrorModal>
    </div>
  )
}

export default CopyrightRegistrationManagement
