import React, { createElement, ReactElement } from 'react'

type Props = {
  className?: string,
  svg?: any
  style?: object
}

const AppIcon = ({ className, svg, style }: Props): ReactElement => {
  return <span className="anticon">{ createElement(svg, {
    className: className,
    style: {
      fontSize: 'inherit',
      ...style
    }
  }) }</span>
}

export default AppIcon
