import { ReactNode, useEffect } from 'react'
import { Col, Image, Modal, Progress, Row, Spin, Upload } from 'antd'
import { CloseOutlined, UploadOutlined } from '@ant-design/icons'
import type { UploadChangeParam } from 'antd/es/upload'
import type { UploadFile, UploadProps } from 'antd/es/upload/interface'

import Styles from './index.module.scss'
import { BASE_URL, FILE_URL } from '../../common/constant'
import { UPLOAD_FILE_URL } from '../../common/request'

type Props = {
  value?: any
  onChange?: any
  fileTypeList?: string[]
}

function IdCardImgUploadFormItem ({ value = [], fileTypeList = [], onChange }: Props) {
  const beforeUpload = (file: File) => {
    if (fileTypeList.length > 0) {
      const fileTypeIsRight = fileTypeList.includes(file.type)

      if (!fileTypeIsRight) {
        Modal.error({
          title: '错误',
          content: `文件格式错误，仅支持 ${ fileTypeList.map(item => {
            return item.replace('image/', '').replace('application/', '').toLocaleUpperCase()
          }).join('/') } 格式`
        })
        return false
      }
    }

    if (file.size > 5 * 1024 * 1024) {
      Modal.error({
        title: '错误',
        content: '文件大小不符合标准，文件大小不能超过5MB'
      })
      return false
    }
  }

  const idCardFrontChange: UploadProps['onChange'] = async (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'done') {
      onChange?.([{
        uid: info.file.uid,
        name: info.file.response.data,
        url: info.file.response.data,
        status: 'done'
      }, value[1]])
    } else {
      onChange?.([info.file, value[1]])
    }
  }

  const idCardReverseChange: UploadProps['onChange'] = async (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'done') {
      onChange?.([value[0], {
        uid: info.file.uid,
        name: info.file.response.data,
        url: info.file.response.data,
        status: 'done'
      }])
    } else {
      onChange?.([value[0], info.file])
    }
  }

  const deleteIdCardFile = (type: string) => {
    if (type === 'front') {
      onChange?.([null, value[1]])
    } else {
      onChange?.([value[0], null])
    }
  }

  const getImageDescription = (type: string) => {
    if (type === 'front') {
      return '身份证人像面'
    } else {
      return '身份证国徽面'
    }
  }

  const generateFileImg = (fileList: UploadFile[], type: string): ReactNode => {
    const file = type === 'front' ? fileList[0] : fileList[1]

    if (file?.status === 'done') {
      return (
        <div className={ Styles.img_wrap } onClick={ (e) => {
          e.stopPropagation()
        } }>
          <Image height={ 130 } src={ `${ FILE_URL }/${ file.url }` }></Image>
          <div className={ Styles.close_icon } onClick={ deleteIdCardFile.bind(this, type) }>
            <CloseOutlined/>
          </div>
        </div>
      )
    } else {
      return (
        <Spin spinning={ file?.status === 'uploading' }>
          <div className={ Styles.img_wrap }>
            <UploadOutlined className={ Styles.upload_icon }/>
            <div className={ Styles.upload_text }>{ getImageDescription(type) }</div>
            <div className={ 'px-3' } style={ { width: '80%' } }>
              {
                file?.status === 'uploading'
                  ? <Progress
                    percent={ parseInt(file?.percent + '') }
                    showInfo={ false }
                  />
                  : <></>
              }
            </div>
          </div>
        </Spin>
      )
    }
  }

  return (
    <div>
      <Row gutter={ 20 }>
        <Col span={ 12 }>
          <Upload
            accept={ fileTypeList?.join(', ') }
            action={ `${ BASE_URL }${ UPLOAD_FILE_URL }` }
            headers={ {
              authorization: localStorage.getItem('access_token') || ''
            } }
            className={ 'app-upload' }
            fileList={ value[0] ? [value[0]] : [] }
            listType="picture"
            beforeUpload={ beforeUpload }
            showUploadList={ false }
            maxCount={ 1 }
            style={ { width: '100%' } }
            onChange={ idCardFrontChange }>
            { generateFileImg(value, 'front') }
          </Upload>
        </Col>
        <Col span={ 12 }>
          <Upload
            accept={ fileTypeList?.join(', ') }
            action={ `${ BASE_URL }${ UPLOAD_FILE_URL }` }
            headers={ {
              authorization: localStorage.getItem('access_token') || ''
            } }
            className={ 'app-upload' }
            fileList={ value[1] ? [value[1]] : [] }
            listType="picture"
            beforeUpload={ beforeUpload }
            showUploadList={ false }
            maxCount={ 1 }
            onChange={ idCardReverseChange }>
            { generateFileImg(value, 'reverse') }
          </Upload>
        </Col>
      </Row>

      <div className={ Styles.upload_description }>
        <div className={ 'text-dark font-weight-bold' }>温馨提示</div>
        <div>1.文件格式支持{ fileTypeList.map(item => {
          return item.replace('image/', '').replace('application/', '').toLocaleUpperCase()
        }).join('/') }格式，文件大小5M以内。
        </div>
        <div>2.上传图片需确保边角完整，文字、图片、证件号码等清晰可辨, 提交前请仔细核对。</div>
      </div>
    </div>
  )
}

export default IdCardImgUploadFormItem
