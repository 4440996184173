import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Button, Divider, Form, Input, Modal, Select, Upload } from 'antd'
import ImgCrop from 'antd-img-crop'

import { BASE_URL } from '../../../../common/constant'
import { ResponseCode } from '../../../../common/response-code'
import AppUserAvatar from '../../../../components/AppUserAvatar'
import { UploadFile, UploadProps } from 'antd/es/upload/interface'
import { getRoleList, UPLOAD_FILE_URL } from '../../../../common/request'
import UpdateUserPasswordModal, { ModalRefProps } from '../../../../components/UpdateUserPasswordModal'
import UpdateUserEmailModal, { UserEmailModalModalRefProps } from '../../../../components/UpdateUserEmailModal'
import UpdateUserAvatarModal, { UserAvatarModalModalRefProps } from '../../../../components/UpdateUserAvatarModal'

function SelfInfoSetting () {
  const [form] = Form.useForm()
  const updateUserPasswordModalRef = useRef<ModalRefProps>(null)
  const updateUserEmailModalRef = useRef<UserEmailModalModalRefProps>(null)
  const updateUserAvatarModalRef = useRef<UserAvatarModalModalRefProps>(null)
  const userInfo = useSelector((state: any) => state.userInfo.userInfo)
  const [roleList, setRoleList] = useState([])
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const fileTypeList = ['image/png', 'image/jpg', 'image/jpeg', 'image/gif']

  useEffect(() => {
    getRoleListAction()
  }, [])

  useEffect(() => {
    form.setFieldsValue({
      userName: userInfo.userName,
      id: userInfo.id,
      roleType: userInfo.roleType,
      phone: userInfo.phone
    })
  }, [userInfo])

  const getRoleListAction = () => {
    getRoleList().then((res: any) => {
      if (res.code === ResponseCode.success) {
        setRoleList(res.data.map((item: any) => {
          return {
            label: item.cnName,
            value: item.enName
          }
        }))
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch((err: any) => {
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，获取角色类型列表失败'
      })
    })
  }

  const showUpdatePasswordModal = () => {
    updateUserPasswordModalRef.current?.showModal()
  }

  const showUpdateEmailModal = () => {
    updateUserEmailModalRef.current?.showModal()
  }

  const beforeUpload = (file: File) => {
    if (fileTypeList.length > 0) {
      const fileTypeIsRight = fileTypeList.includes(file.type)

      if (!fileTypeIsRight) {
        Modal.error({
          title: '错误',
          content: `文件格式错误，仅支持 ${ fileTypeList.map(item => {
            return item.replace('image/', '').toLocaleUpperCase()
          }).join('/') } 格式`
        })
        return false
      }
    }

    if (file.size > 5 * 1024 * 1024) {
      Modal.error({
        title: '错误',
        content: '文件大小不符合标准，文件大小不能超过5MB'
      })
      return false
    }
  }

  const handleFileChange: UploadProps['onChange'] = ({ file, fileList }) => {
    if (file.status === 'error') {
      Modal.error({
        title: '错误',
        content: '上传失败'
      })
    }
    if (file.status === 'done') {
      updateUserAvatarModalRef.current?.showModal(file.response.data)
    }
    setFileList(fileList)
  }

  return (
    <div>
      <div className={ 'font-weight-bold' }>个人资料设置</div>
      <Divider style={ { marginTop: 20, marginBottom: 15 } }></Divider>
      <div>
        <Form
          form={ form }
          labelCol={ { span: 3 } }
        >
          <Form.Item
            className={ 'mb-3' }
            label={ '个人头像' }
            name={ 'avatar' }
          >
            <div className={ 'd-flex align-items-center' }>
              <AppUserAvatar></AppUserAvatar>
              <ImgCrop rotationSlider>
                <Upload
                  fileList={ fileList }
                  showUploadList={ false }
                  action={ `${ BASE_URL }${ UPLOAD_FILE_URL }` }
                  headers={ {
                    authorization: localStorage.getItem('access_token') || ''
                  } }
                  maxCount={ 1 }
                  beforeUpload={ beforeUpload }
                  onChange={ handleFileChange }
                >
                  <Button className={ 'ml-3' } type={ 'primary' } ghost>上传</Button>
                </Upload>
              </ImgCrop>
            </div>
          </Form.Item>
          <Form.Item
            className={ 'mb-3' }
            label={ '用户名' }
            name={ 'userName' }
          >
            <Input disabled placeholder={ '输入用户名' } style={ { width: 350 } }></Input>
          </Form.Item>
          <Form.Item
            className={ 'mb-3' }
            label={ '用户ID' }
            name={ 'id' }
          >
            <Input placeholder={ '输入用户ID' } disabled style={ { width: 350 } }></Input>
          </Form.Item>
          <Form.Item
            className={ 'mb-3' }
            label={ '手机号' }
            name={ 'phone' }
          >
            <Input disabled placeholder={ '输入邮箱' } style={ { width: 350 } }></Input>
          </Form.Item>
          <Form.Item
            className={ 'mb-3' }
            label={ '账号类型' }
            name={ 'roleType' }
          >
            <Select
              options={ roleList }
              placeholder={ '输入用户ID' }
              disabled
              style={ { width: 350 } }></Select>
          </Form.Item>
          <Form.Item
            className={ 'mb-3' }
            label={ '密码' }
          >
            <div className={ 'd-flex align-items-center' }>
              <Input
                value={ '*************' }
                placeholder={ '输入邮箱' }
                disabled
                style={ { width: 350 } }
              ></Input>
              <Button
                className={ 'ml-3' }
                type={ 'primary' }
                ghost
                onClick={ showUpdatePasswordModal }
              >修改密码</Button>
            </div>
          </Form.Item>
          <Form.Item
            className={ 'mb-3' }
            label={ '当前绑定邮箱' }
            name={ 'mail' }
          >
            <div className={ 'd-flex align-items-center' }>
              <Input
                value={ userInfo.mail }
                placeholder={ '未绑定' }
                disabled
                style={ { width: 350 } }
              ></Input>
              <Button
                className={ 'ml-3' }
                type={ 'primary' }
                ghost
                onClick={ showUpdateEmailModal }
              >修改邮箱</Button>
            </div>
          </Form.Item>
        </Form>
      </div>

      <UpdateUserPasswordModal ref={ updateUserPasswordModalRef }></UpdateUserPasswordModal>
      <UpdateUserEmailModal ref={ updateUserEmailModalRef }></UpdateUserEmailModal>
      <UpdateUserAvatarModal ref={ updateUserAvatarModalRef }></UpdateUserAvatarModal>
    </div>
  )
}

export default SelfInfoSetting
