import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Col, Image, Modal, Row, Spin, Divider, Button, Input, notification } from 'antd'
import { getOrderInfo, getPaySmsCode, getUserWallet } from '../../common/request'
import { AxiosRes } from '../../plugins/axios'
import { ResponseCode } from '../../common/response-code'

import Styles from './index.module.scss'
import { useSearchParams } from 'react-router-dom'
import WalletBgImg from '../../assets/images/wallet-bg.png'
import { useSelector } from 'react-redux'
import { ReducerType } from '../../store'
import router from '../../routers'

export type ViewWalletModalProps = {
  confirmPay: () => void
  showModal: () => void
}

type WalletInfoType = {
  prePayment?: number
}

const ViewWalletModal = forwardRef((props: any, ref) => {
  const countDownSeconds = 120
  const [searchParams] = useSearchParams()
  const id = searchParams.get('id')
  const userInfo = useSelector((state: ReducerType) => state.userInfo.userInfo)
  const [modalVisible, setModalVisible] = useState(false)
  const [walletInfo, setWalletInfo] = useState<WalletInfoType>({})
  const [loading, setLoading] = useState(false)
  const [spinText, setSpinText] = useState('')
  const [amountPayable, setAmountPayable] = useState(0)
  const [smsCode, setSmsCode] = useState('')
  const [smsCodeDisabled, setSmsCodeDisabled] = useState(false)
  const [countDown, setCountDown] = useState(countDownSeconds)
  const [countDownText, setCountDownText] = useState('')
  const [initLoadingSmsCode, setInitLoadingSmsCode] = useState(true)
  const [smsCodeLoading, setSmsCodeLoading] = useState(false)

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      getWalletDetail()
    } else {
      setSmsCodeDisabled(false)
      setCountDown(countDownSeconds)
      setInitLoadingSmsCode(true)
      setSmsCodeLoading(false)
    }
  }, [modalVisible])

  useEffect(() => {
    if (smsCodeDisabled) {
      if (countDown === 0) {
        setCountDown(countDownSeconds)
        setSmsCodeDisabled(false)
      } else {
        setCountDownText(`${ countDown }S`)
        setTimeout(() => {
          setCountDown(pre => {
            return pre - 1
          })
        }, 1000)
      }
    }
  }, [countDown, smsCodeDisabled])

  useEffect(() => {
    setAmountPayable(props.productList.find((item: any) => {
      return item.key === props.selectedProductKey
    })?.amount)
  }, [props.productList, props.selectedProductKey])

  const getWalletDetail = () => {
    getUserWallet().then((res: AxiosRes) => {
      if (res.code === ResponseCode.success) {
        setWalletInfo(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch((err) => {
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询预付款结算详情失败'
      })
    })
  }

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const onOk = () => {
    getOrderInfoAction()
  }

  const getCouponSum = (selectedKeys: number[], list: any) => {
    return selectedKeys.map(couponId => {
      return list.find((coupon: any) => {
        return coupon.id === couponId
      })?.amount
    }).reduce((pre, cur) => {
      return pre + cur
    }, 0)
  }

  const getOrderInfoAction = () => {
    setLoading(true)
    setSpinText('正在创建订单')
    const params = {
      productId: props.selectedProductKey,
      copyrightId: id,
      payType: 6,
      systemType: 1,
      couponIds: props.couponList?.map((item: any) => item.id),
      verifyCode: smsCode
    }

    getOrderInfo(params).then((res: any) => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        hideModal()
        notification.open({
          type: 'success',
          message: '支付成功'
        })

        router.navigate('/pay-success')
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch((err: any) => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询订单信息失败'
      })
    })
  }

  const beforeGetSmsCode = () => {
    if (((walletInfo.prePayment || 0) - (amountPayable - getCouponSum(props.selectCoupon, props.couponList))) > 0) {
      getSmsCode()
    } else {
      Modal.error({
        title: '错误',
        content: '余额不足，请到分销系统充值'
      })
    }
  }

  const getSmsCode = () => {
    setSmsCodeLoading(true)
    getPaySmsCode({ phone: userInfo.phone }).then((res: AxiosRes) => {
      setSmsCodeLoading(false)
      if (res.code === ResponseCode.success) {
        setInitLoadingSmsCode(false)
        setSmsCodeDisabled(true)
        Modal.success({
          title: '提示',
          content: '短信验证码已发送，请注意查收'
        })
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch((err: any) => {
      console.error(err)
      setSmsCodeLoading(false)
      Modal.error({
        title: '错误',
        content: '网络错误，获取验证码失败'
      })
    })
  }

  const smsCodeChange = (val: any) => {
    setSmsCode(val.target.value)
  }

  const beforeGenerateOrderAction = () => {
    if (!smsCode) {
      Modal.error({
        title: '错误',
        content: '请输入短信验证码'
      })
      return false
    }

    getOrderInfoAction()
  }

  return (<>
    <Modal
      width={ 670 }
      open={ modalVisible }
      title={ '预付款结算支付' }
      onOk={ onOk }
      onCancel={ hideModal }
      destroyOnClose={ true }
      maskClosable={ false }
      footer={ null }
      styles={ {
        header: { background: 'transparent' },
        content: {
          background: `
        linear-gradient(to bottom, #e6f6ff, #ffffff, #e8e5ff)
        `,
          backgroundBlendMode: 'multiply'
        }
      } }
    >

      <div className={ 'px-4' }>
        <div className={ Styles.wallet_balance_wrap }>
          <div>
            预付款结算余额：
            <span className={ Styles.wallet_balance }>¥{ String(walletInfo.prePayment) || '--' }</span>
          </div>
          <Image src={ WalletBgImg } preview={ false } width={ 45 }></Image>
        </div>

        <Row className={ 'mt-3' } gutter={ [0, 16] }>
          {
            props.selectCoupon.length > 0
              ? (<>
                <Col className={ Styles.pay_info_label } span={ 10 }>应付金额：</Col>
                <Col span={ 12 }>
                  <div className={ Styles.pay_info_value }>¥{ amountPayable?.toFixed(2) }</div>
                </Col>
                <Col className={ Styles.pay_info_label } span={ 10 }>优惠券抵扣：</Col>
                <Col span={ 12 }>
                  <div className={ Styles.pay_info_value }>¥{ getCouponSum(props.selectCoupon, props.couponList) }</div>
                </Col>
              </>)
              : <></>
          }
          <Col className={ [Styles.pay_info_label, 'font-weight-bold'].join(' ') } span={ 10 }>实付金额：</Col>
          <Col span={ 12 }>
            <div
              className={ [Styles.pay_info_value, 'font-weight-bold text-primary'].join(' ') }>¥{ (amountPayable - getCouponSum(props.selectCoupon, props.couponList))?.toFixed(2) }</div>
          </Col>
          <Divider className={ 'm-0' }></Divider>
          <Col className={ Styles.pay_info_label } span={ 10 }>手机号：</Col>
          <Col span={ 12 }>
            <div className={ Styles.phone_number }>
              { userInfo.phone }
              <span>
                {
                  !smsCodeDisabled
                    ? <Button
                      loading={ smsCodeLoading }
                      className={ 'ml-3' }
                      type={ 'primary' }
                      ghost
                      style={ { width: 130, borderRadius: 6 } }
                      onClick={ beforeGetSmsCode }
                    >获取验证码</Button>
                    : <Button
                      className={ 'ml-3' }
                      type={ 'primary' }
                      ghost
                      disabled
                      style={ { width: 130, borderRadius: 6 } }
                    >{ countDownText }</Button>
                }
              </span>

            </div>
          </Col>

          <Col className={ Styles.pay_info_label } span={ 10 }>输入短信验证码支付：</Col>
          <Col span={ 12 }>
            <Input
              value={ smsCode }
              placeholder={ '请输入验证码' }
              style={ { width: 280 } }
              disabled={ initLoadingSmsCode }
              onInput={ smsCodeChange }
            ></Input>
          </Col>
        </Row>
      </div>
      <div className={ 'mt-4 d-flex align-items-center justify-content-center' }>
        <Button
          className={ 'px-5' }
          type={ 'primary' }
          shape={ 'round' }
          size={ 'large' }
          disabled={ !smsCode }
          onClick={ beforeGenerateOrderAction }
        >确认支付</Button>
      </div>

    </Modal>
    <Spin spinning={ loading } tip={ spinText } fullscreen></Spin>
  </>)
})

export default ViewWalletModal
