import { Modal, Image, Button, Spin } from 'antd'
import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'

import { downloadFile, getCopyRightApplicationDetail } from '../../common/request'
import { ResponseCode } from '../../common/response-code'
import { FILE_URL } from '../../common/constant'
import Styles from './index.module.scss'
import GetAddressByRegionIds from '../GetAddressByRegionIds'
import { base64ToArrayBuffer, downloadFile as downloadByteFile, fileNameStrFormat } from '../../plugins/units'
import { AxiosRes } from '../../plugins/axios'

type Props = {}

type CopyrightDetail = {
  productFile?: string
  name?: string
  description?: string
  category?: string
  creationTime?: string
  createAddress?: string
  createNature?: string
  publishState?: string
  publishAddress?: string
  publishTime?: string
  publishAnnex?: string
  createIntention?: string
  productOriginal?: string
  coverPic?: string
  images?: string[]
  applyMethod?: string
  autBelMethod?: string
  autBelMethodExp?: string
  autHolds?: string
  autHoldsExp?: string
  autAcpMethod?: string
  autAcpMethodExp?: string
  signature?: string
  authors?: any[]
  copyRights?: any[]
  copyrightStatement?: any
  enclosures?: any
}

export interface ModalRefProps {
  showModal: (id: any) => void;
}

const ViewCopyrightDetailModal = forwardRef<ModalRefProps, Props>(({}: Props, ref) => {
  const [modalVisible, setModalVisible] = useState(false)
  const [currentCopyrightId, setCurrentCopyrightId] = useState(null)
  const [copyrightDetail, setCopyrightDetail] = useState<CopyrightDetail>({})
  const [loading, setLoading] = useState(false)
  const [loadingText, setLoadingText] = useState('正在加载')

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      getCopyrightDetailAction()
    }
  }, [modalVisible])

  const getCopyrightDetailAction = () => {
    setLoading(true)
    setLoadingText('正在加载')
    getCopyRightApplicationDetail({ id: currentCopyrightId }).then((res: any) => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        setCopyrightDetail(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch((err: any) => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询版权详情失败'
      })
    })
  }

  const showModal = (id: any) => {
    setCurrentCopyrightId(id)
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const DescriptionItem = ({ children, title }: any) => {
    return (
      <div className={ Styles.descriptions_item }>
        <div className={ Styles.descriptions_label }>{ title }</div>
        <div className={ Styles.descriptions_value }>
          { children || '无' }
        </div>
      </div>
    )
  }

  const ImgContent = ({ path, maxWidth }: any) => {
    const getContent = (content: any) => {
      if (!content) {
        return '暂无'
      }

      if (Array.isArray(content)) {
        return path.map((item: string) => {
          return <Image key={ item } src={ FILE_URL + item } style={ { maxWidth: maxWidth } }></Image>
        })
      } else {
        return (
          <Image src={ FILE_URL + path } style={ { maxWidth: maxWidth } }></Image>
        )
      }
    }

    return (<>
      { getContent(path) }
    </>)
  }

  const downloadFileAction = (item: any) => {
    setLoading(true)
    setLoadingText(`正在下载【${ item.label }】`)
    downloadFile({ fileName: item.value }).then((res: AxiosRes) => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        downloadByteFile(base64ToArrayBuffer(res.data), item.label.split('.')[0], item.label.split('.')[1])
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch(err => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，下载失败'
      })
    })
  }

  return (
    <Modal
      width={ 900 }
      title={ '版权详情' }
      open={ modalVisible }
      onCancel={ hideModal }
      footer={ [
        <Button key={ 'close' } type={ 'primary' } onClick={ hideModal }>关闭</Button>
      ] }
    >
      <Spin spinning={ loading } tip={ loadingText } fullscreen={ true }></Spin>
      <div className={ Styles.descriptions_title }>作品信息</div>
      <div>
        <DescriptionItem title={ '登记作品' }>
          <ImgContent path={ copyrightDetail?.productFile } maxWidth={ 200 }></ImgContent>
        </DescriptionItem>
        <DescriptionItem title={ '作品名称' }>
          { copyrightDetail.name }
        </DescriptionItem>
        <DescriptionItem title={ '作品描述' }>
          { copyrightDetail.description }
        </DescriptionItem>
        <DescriptionItem title={ '作品类别' }>
          { copyrightDetail.category }
        </DescriptionItem>
        <DescriptionItem title={ '创作完成时间' }>
          { copyrightDetail.creationTime }
        </DescriptionItem>
        <DescriptionItem title={ '创作完成地点' }>
          <GetAddressByRegionIds ids={ copyrightDetail.createAddress || '' }></GetAddressByRegionIds>
        </DescriptionItem>
        <DescriptionItem title={ '创作性质' }>
          { copyrightDetail.createNature }
        </DescriptionItem>
        <DescriptionItem title={ '发表状态' }>
          { copyrightDetail.publishState }
        </DescriptionItem>
        <DescriptionItem title={ '创作性质' }>
          { copyrightDetail.createNature }
        </DescriptionItem>

        {
          copyrightDetail.publishState === '已发表'
            ? (<>
              <DescriptionItem title={ '作品发表地点' }>
                { copyrightDetail.publishAddress }
              </DescriptionItem>
              <DescriptionItem title={ '作品发表时间' }>
                { copyrightDetail.publishTime }
              </DescriptionItem>
              <DescriptionItem title={ '作品发表佐证附件' }>
                <ImgContent path={ copyrightDetail.publishAnnex } maxWidth={ 200 }></ImgContent>
              </DescriptionItem>
            </>)
            : <></>
        }
        <DescriptionItem title={ '创作意图' }>
          { copyrightDetail.createIntention }
        </DescriptionItem>
        <DescriptionItem title={ '作品独创性' }>
          { copyrightDetail.productOriginal }
        </DescriptionItem>
        {/*<DescriptionItem title={ '作品封面' }>*/ }
        {/*  <ImgContent path={ copyrightDetail.coverPic } maxWidth={ 200 }></ImgContent>*/ }
        {/*</DescriptionItem>*/ }
        <DescriptionItem title={ '作品展示图片' }>
          <ImgContent path={ copyrightDetail.images } maxWidth={ 200 }></ImgContent>
        </DescriptionItem>

        <div className={ Styles.descriptions_title }>权属信息</div>
        <div>
          <DescriptionItem title={ '申请方式' }>
            { copyrightDetail.applyMethod }
          </DescriptionItem>
          <DescriptionItem title={ '权利归属方式' }>
            { copyrightDetail.autBelMethod }
          </DescriptionItem>
          <DescriptionItem title={ '权利归属方式说明' }>
            { copyrightDetail.autBelMethodExp }
          </DescriptionItem>
          <DescriptionItem title={ '权利拥有状况' }>
            { copyrightDetail.autHolds }
          </DescriptionItem>
          <DescriptionItem title={ '权利拥有状况说明' }>
            { copyrightDetail.autHoldsExp }
          </DescriptionItem>
          <DescriptionItem title={ '权利获得方式' }>
            { copyrightDetail.autAcpMethod }
          </DescriptionItem>
          <DescriptionItem title={ '权利获得方式说明' }>
            { copyrightDetail.autAcpMethodExp }
          </DescriptionItem>
          <DescriptionItem title={ '署名' }>
            { copyrightDetail.signature }
          </DescriptionItem>
          <DescriptionItem title={ '作者' }>
            { copyrightDetail.authors?.map(item => item.realName) }
          </DescriptionItem>
          <DescriptionItem title={ '著作权人' }>
            { copyrightDetail.copyRights?.map(item => item.realName) }
          </DescriptionItem>
          <DescriptionItem title={ '权利保证书' }>
            {
              fileNameStrFormat([copyrightDetail.copyrightStatement])?.map(item => {
                return <div
                  key={ item.value }
                  className={ Styles.linkText }
                  onClick={ downloadFileAction.bind(this, item) }
                >{ item.label }</div>
              })
            }
          </DescriptionItem>
          <DescriptionItem title={ '附件' }>
            {
              fileNameStrFormat(copyrightDetail.enclosures)?.map(item => {
                return <div
                  key={ item.value }
                  className={ Styles.linkText }
                  onClick={ downloadFileAction.bind(this, item) }
                >{ item.label }</div>
              })
            }
          </DescriptionItem>
        </div>
      </div>
    </Modal>
  )
})

export default ViewCopyrightDetailModal
