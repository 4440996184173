import { Image } from 'antd'
import { useEffect, useState } from 'react'
import { LeftCircleOutlined } from '@ant-design/icons'

import router from '../../routers'
import Styles from './index.module.scss'
import AppUserDrawer from '../AppUserDrawer'
import LoginHeaderLogoImg from '../../assets/images/header-logo.png'

type MenuListItem = {
  label: string
  path: string
}

type Props = {
  handleBack?: any
  menuList?: MenuListItem[]
}

function AppHeader ({ menuList, handleBack }: Props) {
  const [pathName, setPathName] = useState<string>(window.location.pathname)

  useEffect(() => {
    router.subscribe((event) => {
      setPathName(event.location.pathname)
    })
  }, [])

  const getMenuItemClass = (active: boolean) => {
    if (active) {
      return [Styles.menu_item, Styles.is_active].join(' ')
    } else {
      return Styles.menu_item
    }
  }

  const navigatorTo = (menu: MenuListItem) => {
    router.navigate(`${ menu.path }${window.location.search}`)
  }

  const generateMenuList = (path: string) => {
    return menuList?.map(menu => {
      return <div
        className={ getMenuItemClass(menu.path === path) }
        key={ menu.path }
        onClick={ navigatorTo.bind(this, menu) }
      >
        { menu.label }
      </div>
    })
  }

  return (
    <div className={ Styles.app_dark_header }>
      <Image preview={ false } width={ 220 } src={ LoginHeaderLogoImg }></Image>
      <div className={ Styles.app_header_control }>
        <div className={ Styles.app_header_back_button } onClick={ handleBack }>
          <LeftCircleOutlined className={ 'mr-2' }/>
          <span>返回</span>
        </div>
        { generateMenuList(pathName) }

        <AppUserDrawer></AppUserDrawer>
      </div>
    </div>
  )
}

export default AppHeader
