import { ReactNode, useState } from 'react'
import { Image, Modal, Progress, Spin, Upload } from 'antd'
import { CloseOutlined, LoadingOutlined, PlusOutlined, UpCircleOutlined } from '@ant-design/icons'
import { UploadChangeParam } from 'antd/es/upload'
import { UploadFile, UploadProps } from 'antd/es/upload/interface'

import Styles from './index.module.scss'
import ImageIconImg from '../../assets/images/image-icon.png'
import { BASE_URL, FILE_URL } from '../../common/constant'
import { UPLOAD_FILE_URL } from '../../common/request'

type Props = {
  value?: UploadFile[]
  fileTypeList?: string[]
  onChange?: any
  description?: string
  width?: string | number
  height?: string | number
  children?: ReactNode | null
  maxCount?: number
  fileMaxSize?: number,
  theme?: string
}

function UploadImageFormItem ({ value = [], fileTypeList = [], maxCount = 0, fileMaxSize = 0, onChange, description, width = '100%', height = 120, children, theme = 'dark' }: Props) {
  const [uploadLoading, setUploadLoading] = useState(false)

  const beforeUpload = (file: File) => {
    if (fileTypeList.length > 0) {
      const fileTypeIsRight = fileTypeList.includes(file.type)

      if (!fileTypeIsRight) {
        Modal.error({
          title: '错误',
          content: `文件格式错误，仅支持 ${ fileTypeList.map(item => {
            return item.replace('image/', '').toLocaleUpperCase()
          }).join('/') } 格式`
        })
        return false
      }
    }

    if (fileMaxSize && file.size > fileMaxSize * 1024) {
      Modal.error({
        title: '错误',
        content: '文件大小不符合标准，文件大小不能超过500MB'
      })
      return false
    }
  }

  const handleFileChange: UploadProps['onChange'] = (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'uploading') {
      setUploadLoading(true)
    }

    if (info.file.status === 'done') {
      setUploadLoading(false)
    }

    if (info.file.status === 'error') {
      Modal.error({
        title: '错误',
        content: `文件【${ info.file.name }】上传失败`
      })
      onChange?.(info.fileList.filter(item => {
        return item.uid !== info.file.uid
      }))
      setUploadLoading(false)
    } else {
      onChange?.(info.fileList.map(item => {
        return {
          status: item.status,
          uid: item.uid,
          name: item.name,
          url: item.url || item.response?.data,
          response: item.response,
          percent: item.percent
        }
      }))
    }
  }

  const deleteIdCardFile = (file: any) => {
    onChange?.(value.filter((item: UploadFile) => {
      return item.uid !== file.uid
    }))
  }

  const getImgWrapClass = (defaultClass: string) => {
    return theme === 'dark' ? defaultClass : `${ defaultClass } ${ Styles.light }`
  }

  const generateFileImg = (fileList: UploadFile[], loading: boolean): ReactNode => {
    let fileContentList = fileList.map((file: UploadFile) => {
      switch (file.status) {
        case 'uploading':
          return <div
            key={ file.uid }
            className={ getImgWrapClass(Styles.img_wrap) }
            style={ { width: width, height: height } }>
            {
              theme === 'dark'
                ? <Image preview={ false } src={ ImageIconImg } width={ 48 }></Image>
                : <LoadingOutlined className={'text-primary'} style={ { fontSize: 28 } } />
            }
            <div className={ 'px-3' } style={ { width: '80%' } }>
              <Progress
                percent={ parseInt(file?.percent + '', 10) }
                showInfo={ false }
              />
            </div>
            <div className={ Styles.upload_text }>{ description }</div>
          </div>
          break
        case 'done':
          return (
            <div
              key={ file.uid }
              className={ getImgWrapClass(Styles.img_wrap) }
              style={ { width: width, height: height } }
              onClick={ (e) => {
                e.stopPropagation()
              } }>
              <Image height={ '100%' } src={ `${ FILE_URL }${ file.url }` }></Image>
              <div className={ Styles.close_icon } onClick={ deleteIdCardFile.bind(this, file) }>
                <CloseOutlined/>
              </div>
            </div>
          )
          break
        case 'error':
          break
      }
      return
    })

    return fileContentList
  }

  return (
    <div>
      <div className={ 'd-flex align-items-center flex-wrap' } onClick={ (event) => {
        event.stopPropagation()
      } }>
        { generateFileImg(value, uploadLoading) }
        <Upload
          accept={ fileTypeList?.join(', ') }
          className={ 'app-upload no-bg no-border' }
          fileList={ value }
          // disabled={ uploadLoading }
          action={ `${ BASE_URL }${ UPLOAD_FILE_URL }` }
          headers={ {
            authorization: localStorage.getItem('access_token') || ''
          } }
          beforeUpload={ beforeUpload }
          showUploadList={ false }
          maxCount={ maxCount }
          onChange={ handleFileChange }>
          <div
            key={ 'default' }
            className={ getImgWrapClass(Styles.img_wrap) }
            style={ {
              width: width,
              height: height,
              opacity: (maxCount && maxCount > value.length) || !maxCount ? 1 : 0,
              cursor: (maxCount && maxCount > value.length) || !maxCount ? 'pointer' : 'auto'
          } }
            onClick={(event) => {
              if (maxCount && maxCount <= value.length) {
                event.stopPropagation()
              }
            }}
          >
            <Spin
              className={ 'w-100' }
              spinning={ false }
              indicator={ <LoadingOutlined style={ { fontSize: 30 } }/> }
            >
              <div className={ 'd-flex align-items-center flex-column' }>
                {
                  theme === 'dark'
                    ? <Image preview={ false } src={ ImageIconImg } width={ 48 }></Image>
                    : <PlusOutlined className={'text-primary'} style={ { fontSize: 28 } } />
                }
                <div className={ 'px-3' } style={ { width: '80%' } }>
                </div>
                <div className={ getImgWrapClass(Styles.upload_text) }>{ description }</div>
              </div>
            </Spin>
          </div>
        </Upload>
      </div>

      { children }
    </div>
  )
}

export default UploadImageFormItem
