import { Button, DatePicker, Divider, Form, Input, Table } from 'antd'
import { SearchOutlined } from '@ant-design/icons'

function ElectricCollectionManagement () {
  const [form] = Form.useForm()

  const columns = [
    {
      title: '数字藏品编号',
      key: 'id',
      dataIndex: 'id'
    },
    {
      title: '作品名称',
      key: 'name',
      dataIndex: 'name'
    },
    {
      title: '审核状态',
      key: 'status',
      dataIndex: 'status'
    },
    {
      title: '证书申请状态',
      key: 'copyrightStatus',
      dataIndex: 'copyrightStatus'
    },
    {
      title: '发行状态',
      key: 'copyrightStatus',
      dataIndex: 'copyrightStatus'
    },
    {
      title: '创建时间',
      key: 'createTime',
      dataIndex: 'createTime'
    },
    {
      title: '操作',
      key: 'operation',
      dataIndex: 'operation'
    }
  ]

  return (
    <div>
      <div className={'font-weight-bold'}>数字藏品管理</div>
      <Divider style={{ marginTop: 20, marginBottom: 15 }}></Divider>
      <div>
        <Form
          form={form}
          layout={'inline'}
        >
          <Form.Item className={'mb-3'} label={'数字藏品编号'}>
            <Input placeholder={'输入数字藏品编号'}></Input>
          </Form.Item>
          <Form.Item className={'mb-3'} label={'作品名称'}>
            <Input placeholder={'输入作品名称'}></Input>
          </Form.Item>
          <Form.Item className={'mb-3'} label={'审核状态'}>
            <Input placeholder={'输入审核状态'}></Input>
          </Form.Item>
          <Form.Item className={'mb-3'} label={'发行状态'}>
            <Input placeholder={'选择发行状态'}></Input>
          </Form.Item>
          <Form.Item className={'mb-3'} label={'创建时间'}>
            <DatePicker showTime />
          </Form.Item>
          <Form.Item className={'mb-3'} label={''}>
            <Button type={'primary'} icon={<SearchOutlined />}>搜索</Button>
          </Form.Item>
        </Form>

        <Table columns={columns}></Table>
      </div>
    </div>
  )
}

export default ElectricCollectionManagement
