import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Button, DatePicker, Form, Input, Modal, notification, Radio } from 'antd'
import IdCardImgUploadFormItem from '../../../../components/IdCardImgUploadFormItem'
import { ModalRefProps } from '../../../../components/EditIdentifyModal'
import { addIdentify, getIdentifyDetail, updateIdentify } from '../../../../common/request'
import { ResponseCode } from '../../../../common/response-code'
import { getUid } from '../../../../plugins/units'
import UploadImageFormItem from '../../../../components/UploadImageFormItem'
import EventBus from '../../../../plugins/events'
import dayjs from 'dayjs'

const idCardFileType = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf']

export interface EditCompanyIdentifyRefProps {
  showModal: (params?: any) => void;
}


type Props = {
  modalType: 'create' | 'edit'
  userVerifyInfo?: any
  verifySuccess?: () => void
}

const EditCompanyIdentifyModal = forwardRef<ModalRefProps, Props>((props, ref) => {
  const [form] = Form.useForm()
  const [notify, contextHolder] = notification.useNotification()
  const [modalVisible, setModalVisible] = useState(false)
  const [modalTitle, setModalTitle] = useState('')
  const [loading, setLoading] = useState(false)

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      setModalTitle(props.modalType ? '添加企业作者' : '编辑企业作者')

      if (props.modalType === 'edit') {

      }
    }
  }, [modalVisible])


  const showModal = (params: any) => {
    if (params) {
      getIdentifyDetailAction(params)
    }
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
    form.resetFields()
  }

  const getIdentifyDetailAction = (params: any) => {
    setLoading(true)
    getIdentifyDetail({ id: params.id }).then((res: any) => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        form.setFieldsValue({
          ...res.data,
          comLegalIdCardImg:
            res.data.comLegalFrontImg
              ? [{
                uid: getUid(),
                status: 'done',
                name: res.data.comLegalFrontImg,
                url: res.data.comLegalFrontImg
              },
                {
                  uid: getUid(),
                  status: 'done',
                  name: res.data.comLegalBackImg,
                  url: res.data.comLegalBackImg
                }]
              : [],
          comLegalHoldImg: res.data.comLegalHoldImg
            ? [{
              uid: getUid(),
              status: 'done',
              name: res.data.comLegalHoldImg,
              url: res.data.comLegalHoldImg
            }]
            : [],
          busLicImg: res.data.busLicImg
            ? [{
              uid: getUid(),
              status: 'done',
              name: res.data.busLicImg,
              url: res.data.busLicImg
            }]
            : [],
          comValidTime: res.data.comValidTime ? dayjs(res.data.comValidTime) : '',
          comCreateTime: res.data.comCreateTime ? dayjs(res.data.comCreateTime) : ''
        })
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch((err: any) => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询作者认证信息失败'
      })
    })
  }

  const addIdentifyAction = (fields: any) => {
    const params = {
      ...fields,
      comLegalFrontImg: fields.comLegalIdCardImg.length > 0 ? fields.comLegalIdCardImg[0].url : '',
      comLegalBackImg: fields.comLegalIdCardImg.length > 0 ? fields.comLegalIdCardImg[1].url : '',
      comLegalHoldImg: fields.comLegalHoldImg.length > 0 ? fields.comLegalHoldImg[0].url : '',
      busLicImg: fields.busLicImg.length > 0 ? fields.busLicImg[0].url : '',
      comValidTime: dayjs(fields.comValidTime).format('YYYY-MM-DD'),
      comCreateTime: dayjs(fields.comCreateTime).format('YYYY-MM-DD')
    }

    delete params.comLegalIdCardImg

    setLoading(true)
    handleResponse(addIdentify(params))
  }

  const updateIdentifyAction = (fields: any) => {
    const params = {
      ...fields,
      comLegalFrontImg: fields.comLegalIdCardImg.length > 0 ? fields.comLegalIdCardImg[0].url : '',
      comLegalBackImg: fields.comLegalIdCardImg.length > 0 ? fields.comLegalIdCardImg[1].url : '',
      comLegalHoldImg: fields.comLegalHoldImg.length > 0 ? fields.comLegalHoldImg[0].url : '',
      busLicImg: fields.busLicImg.length > 0 ? fields.busLicImg[0].url : '',
      comValidTime: dayjs(fields.comValidTime).format('YYYY-MM-DD'),
      comCreateTime: dayjs(fields.comCreateTime).format('YYYY-MM-DD')
    }

    delete params.comLegalIdCardImg

    handleResponse(updateIdentify(params))
  }

  const handleResponse = (fn: any) => {
    fn.then((res: any) => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        notify.success({
          message: '操作成功',
          description: '数据已更新'
        })
        EventBus.emit('updateUserInfo')
        props.verifySuccess?.()
        hideModal()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch((err: any) => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，实名认证失败'
      })
    })
  }

  const validateFormField = () => {
    form.validateFields().then(fields => {
      setLoading(true)
      if (props.modalType === 'edit') {
        updateIdentifyAction(fields)
      } else {
        addIdentifyAction(fields)
      }

    }).catch((err: any) => {
      console.error(err)
    })
  }

  return (<div>
    { contextHolder }
    <Modal
      title={ modalTitle }
      open={ modalVisible }
      width={ 750 }
      onCancel={ hideModal }
      footer={ null }
      styles={ {
        header: { background: 'transparent' },
        content: {
          background: `
        linear-gradient(-45deg, rgb(211, 238, 255), #ffffff, transparent),
        linear-gradient(45deg, rgb(230, 210, 255), #ffffff, transparent),
        linear-gradient(135deg, rgb(218, 251, 255), #ffffff, transparent),
        linear-gradient(225deg, rgb(252, 233, 239), #ffffff, transparent)
        `,
          backgroundBlendMode: 'multiply'
        }
      } }
    >
      <Form
        className={ 'mt-3' }
        form={ form }
        labelCol={ {
          span: 5
        } }
        wrapperCol={ {
          span: 16
        } }
        initialValues={ {
          authType: 2,
          id: '',
          comLegalIdCardImg: [],
          comLegalHoldImg: [],
          busLicImg: [],
          comValidTime: '',
          comCreateTime: '',
          comLegalName: '',
          comLegalIdcard: '',
          comAddress: '',
          phone: ''
        } }
      >
        <Form.Item
          name={ 'id' }
          hidden
        >
          <Input></Input>
        </Form.Item>
        <Form.Item
          name={ 'authType' }
          label={ '认证方式' }
          required
        >
          <Radio.Group>
            <Radio value={ 2 }>企业认证</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label={ '上传营业执照' }
          name={ 'busLicImg' }
          rules={ [{ required: true, message: '请上传营业执照' }] }
        >
          <UploadImageFormItem
            theme={ 'light' }
            width={ 200 }
            height={ 120 }
            maxCount={ 1 }
            fileMaxSize={ 20 * 1024 }
            description={ '营业执照' }
          >
            <div className={ 'mt-2 font-size-mini' }>
              <div className={ 'font-weight-bold' }>温馨提示</div>
              <div>1.文件格式支持png/jpeg/jpg/pdf，文件大小10M以内；</div>
              <div>2.上传的证件图片需确保四边角完整，文字、图片、证件号码等清晰可辨；</div>
              <div>3.若认证证件为新颁发的证件，证件信息可能尚未同步至核验系统中，请在证件颁发日期10个自然日后再提交实名认证。</div>
            </div>
          </UploadImageFormItem>
        </Form.Item>
        <Form.Item
          name={ 'busLicNum' }
          label={ '营业执照号' }
          rules={ [{ required: true, message: '请输入营业执照号' }] }
        >
          <Input
            placeholder={ '请输入营业执照号' }
            maxLength={ 18 }
            showCount
          ></Input>
        </Form.Item>
        <Form.Item
          name={ 'companyName' }
          label={ '公司名称' }
          rules={ [{ required: true, message: '请输入公司名称' }] }
        >
          <Input
            placeholder={ '请输入公司名称' }
            maxLength={ 50 }
            showCount
          ></Input>
        </Form.Item>
        <Form.Item
          name={ 'comLegalName' }
          label={ '法人名称' }
        >
          <Input placeholder={ '请输入法人名称' }></Input>
        </Form.Item>
        <Form.Item
          name={ 'phone' }
          label={ '手机号' }
          rules={ [{ required: true, message: '请输入手机号' }] }
        >
          <Input placeholder={ '输入联系电话' }></Input>
        </Form.Item>
        <Form.Item
          name={ 'comLegalIdcard' }
          label={ '法人身份证号' }
        >
          <Input placeholder={ '请输入法人身份证号' }></Input>
        </Form.Item>
        <Form.Item
          name={ 'comLegalIdCardImg' }
          label={ '法人身份证照片' }
        >
          <IdCardImgUploadFormItem
            fileTypeList={ idCardFileType }
          ></IdCardImgUploadFormItem>
        </Form.Item>
        <Form.Item
          label={ '法人持证照片' }
          name={ 'comLegalHoldImg' }
        >
          <UploadImageFormItem
            theme={ 'light' }
            width={ 200 }
            height={ 120 }
            maxCount={ 1 }
            fileMaxSize={ 20 * 1024 }
            description={ '上传法人手持身份证证照片' }
          >
          </UploadImageFormItem>
        </Form.Item>
        <Form.Item
          name={ 'comCreateTime' }
          label={ '公司成立日期' }
        >
          <DatePicker
            placeholder={ '输选择公司成立日期' }
            style={ { width: 220 } }
          ></DatePicker>
        </Form.Item>
        <Form.Item
          name={ 'comValidTime' }
          label={ '公司营业期限' }
        >
          <DatePicker
            placeholder={ '输选择公司营业期限' }
            style={ { width: 220 } }
          ></DatePicker>
        </Form.Item>
        <Form.Item
          name={ 'comAddress' }
          label={ '公司地址' }
          rules={ [{ required: true, message: '请输入公司地址' }] }
        >
          <Input placeholder={ '输入公司地址' }></Input>
        </Form.Item>
      </Form>

      <div className={ 'mt-4 text-center' }>
        <Button
          type={ 'primary' }
          shape="round"
          loading={ loading }
          onClick={ validateFormField }
        >确认提交</Button>
      </div>
    </Modal>
  </div>)
})

export default EditCompanyIdentifyModal
