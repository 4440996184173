import { Radio, Tooltip } from 'antd'
import React, { ReactNode } from 'react'

type RadioOptionItems = {
  value: any
  label: string | ReactNode
  key: string
  disabled?: boolean
  description?: string | ReactNode
}

type Props = {
  options: RadioOptionItems[]
  children?: ReactNode | null
  value?: any
  onChange?: any
  showTooltip?: boolean
}

const AppRadioFormItem = ({ options, value, onChange, showTooltip, children }: Props) => {
  return (<>
    <Radio.Group value={ value } onChange={ onChange } style={ { marginTop: '5px' } }>
      { options?.map((radio) => {
        return (
            <Tooltip key={ radio.key } title={ showTooltip ? radio.description : ''} color={'#ffffff'} overlayInnerStyle={{ color: '#222222' }}>
              <div style={{ display: 'inline-block' }}>
                <Radio
                value={ radio.value }
                disabled={ radio.disabled }
              >{ radio.label }</Radio>
              </div>
            </Tooltip>
          )
      }) }
    </Radio.Group>
    { children }
  </>)
}

export default AppRadioFormItem
