import { forwardRef, useEffect, useImperativeHandle, useState } from 'react'
import { Button, Form, Input, Modal, notification, Radio, Select } from 'antd'
import { useSelector } from 'react-redux'

import IdCardImgUploadFormItem from '../IdCardImgUploadFormItem'
import NationList from '../../components/NationList'
import { ResponseCode } from '../../common/response-code'
import { ReducerType } from '../../store'
import { certification, updateVerifyInfo } from '../../common/request'
import { getUid } from '../../plugins/units'
import EventBus from '../../plugins/events'

export interface ModalRefProps {
  showModal: () => void;
}

type Props = {
  userVerifyInfo?: any
  verifySuccess?: () => void
};

const RealNameAuthenticationModal = forwardRef<ModalRefProps, Props>((props, ref) => {
  const [form] = Form.useForm()
  const [notify, contextHolder] = notification.useNotification()
  const nationList = useSelector((state: ReducerType) => state.nation.nationList)
  const userInfo = useSelector((state: ReducerType) => state.userInfo.userInfo)
  const [modalVisible, setModalVisible] = useState(false)

  const idCardFileType = ['image/png', 'image/jpg', 'image/jpeg', 'application/pdf']

  useImperativeHandle(ref, () => {
    return {
      showModal
    }
  })

  useEffect(() => {
    if (modalVisible) {
      if (userInfo.verify) {
        form.setFieldsValue({
          type: 1,
          ...props.userVerifyInfo,
          idCardImg: [
            {
              uid: getUid(),
              status: 'done',
              name: props.userVerifyInfo.idCardFront,
              url: props.userVerifyInfo.idCardFront
            },
            {
              uid: getUid(),
              status: 'done',
              name: props.userVerifyInfo.idCardBack,
              url: props.userVerifyInfo.idCardBack
            }
          ]
        })
      } else {
        form.setFieldsValue({
          type: 1,
          realName: '',
          gender: 1
        })
      }
    }

  }, [modalVisible])


  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const certificationAction = (fields: any) => {
    const params = {
      realName: fields.realName,
      phone: fields.phone,
      gender: fields.gender,
      nation: fields.nation,
      address: fields.address,
      idCard: fields.idCard,
      idCardFront: fields.idCardImg[0].url,
      idCardBack: fields.idCardImg[1].url
    }

    handleResponse(certification(params))
  }

  const updateVerifyInfoAction = (fields: any) => {
    const params = {
      id: props.userVerifyInfo.id,
      realName: fields.realName,
      phone: fields.phone,
      gender: fields.gender,
      nation: fields.nation,
      address: fields.address,
      idCard: fields.idCard,
      idCardFront: fields.idCardImg[0].url,
      idCardBack: fields.idCardImg[1].url
    }

    handleResponse(updateVerifyInfo(params))
  }

  const handleResponse = (fn: any) => {
    fn.then((res: any) => {
      if (res.code === ResponseCode.success) {
        notify.success({
          message: '已实名认证'
        })
        EventBus.emit('updateUserInfo')
        props.verifySuccess?.()
        hideModal()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch((err: any) => {
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，实名认证失败'
      })
    })
  }

  const validateFormField = () => {
    form.validateFields().then(fields => {
      if (userInfo.verify) {
        updateVerifyInfoAction(fields)
      } else {
        certificationAction(fields)
      }

    }).catch((err: any) => {
      console.error(err)
    })
  }

  return (<div>
    { contextHolder }
    <Modal
      title={ '实名认证' }
      width={ 750 }
      open={ modalVisible }
      footer={ null }
      onCancel={ hideModal }
      styles={ {
        header: { background: 'transparent' },
        content: {
          background: `
        linear-gradient(to bottom, #e6f6ff, #ffffff, #e8e5ff)
        `,
          backgroundBlendMode: 'multiply'
        }
      } }
    >
      <div className={ 'pt-3' }>
        <div>
          <div>应国家法律法规要求，需要完成实名认证。</div>
          <div className={ 'text-danger' }>实名认证一经审核通过，非平台认可的法定事由不得修改。</div>
          <div className={ 'font-weight-bold' }>您所填写的信息仅用于实名认证，平台对于您的信息严格保密。</div>
        </div>

        <Form
          className={ 'mt-3' }
          form={ form }
          labelCol={ {
            span: 5
          } }
          wrapperCol={ {
            span: 16
          } }
        >
          <Form.Item
            name={ 'type' }
            label={ '认证方式' }
            required
          >
            <Radio.Group>
              <Radio value={ 1 }>个人认证</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name={ 'realName' }
            label={ '姓名' }
            required
          >
            <Input placeholder={ '请输入您的名字' }></Input>
          </Form.Item>
          <Form.Item
            name={ 'idCard' }
            label={ '身份证号' }
            required
          >
            <Input placeholder={ '请输入身份证号' }></Input>
          </Form.Item>
          <Form.Item
            name={ 'gender' }
            label={ '性别' }
            required
          >
            <Radio.Group>
              <Radio value={ 1 }>男</Radio>
              <Radio value={ 2 }>女</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            name={ 'nation' }
            label={ '民族' }
            required
          >
            <Select options={ nationList } placeholder={ '选择民族' }></Select>
          </Form.Item>
          <Form.Item
            name={ 'address' }
            label={ '地址' }
            required
          >
            <Input placeholder={ '输入地址' }></Input>
          </Form.Item>
          {/*<Form.Item*/ }
          {/*  name={'address'}*/ }
          {/*  label={'渠道ID'}*/ }
          {/*  required*/ }
          {/*>*/ }
          {/*  <Input placeholder={'输入渠道ID'}></Input>*/ }
          {/*</Form.Item>*/ }
          <Form.Item
            name={ 'phone' }
            label={ '手机号' }
            required
          >
            <Input placeholder={ '输入手机号' }></Input>
          </Form.Item>
          <Form.Item
            name={ 'idCardImg' }
            label={ '上传身份证' }
            required
          >
            <IdCardImgUploadFormItem
              fileTypeList={ idCardFileType }
            ></IdCardImgUploadFormItem>
          </Form.Item>
        </Form>

        <div className={ 'mt-4 text-center' }>
          <Button
            type={ 'primary' }
            shape="round"
            onClick={ validateFormField }
          >确认提交</Button>
        </div>
      </div>
    </Modal>

    <NationList></NationList>
  </div>)
})

export default RealNameAuthenticationModal
