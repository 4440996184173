import Axios from '../plugins/axios'

export const UPLOAD_FILE_URL = '/mall/upload/single'

export const login = (params: any) => {
  return Axios.post('/mall/login/pwd', params)
}

export const register = (params: any) => {
  return Axios.post('/mall/user/register', params)
}

export const getVerifyCode = (params: any) => {
  return Axios.post('/mall/login/sendCode', params)
}

export const resetPassword = (params: any) => {
  return Axios.post('/mall/login/findPwd', params)
}

export const userInfo = () => {
  return Axios.get('/mall/user/currentDetail')
}

export const uploadFile = (params: any) => {
  return Axios.get('/mall/upload/single', {
    responseType: 'blob',
    params
  })
}

export const authorList = (params: any) => {
  return Axios.post('/mall/author/page', params)
}

export const getNationList = () => {
  return Axios.get('/mall/copyRight/list/nation')
}

export const certification = (params: any) => {
  return Axios.post('/mall/user/idVerify', params)
}

export const getVerifyInfo = () => {
  return Axios.get('/mall/user/verifyInfo')
}

export const updateVerifyInfo = (params: any) => {
  return Axios.post('/mall/user/idVerifyUpdate', params)
}

export const getRoleList = () => {
  return Axios.get('/mall/user/roleTypeList')
}

export const identifyList = (params: any) => {
  return Axios.post('/mall/author/page', params)
}

export const addIdentify = (params: any) => {
  return Axios.post('/mall/author/insert', params)
}

export const updateIdentify = (params: any) => {
  return Axios.post('/mall/author/update', params)
}

export const getIdentifyDetail = (params: any) => {
  return Axios.post('/mall/author/detail', params)
}

export const deleteIdentify = (params: any) => {
  return Axios.post('/mall/author/delete ', params)
}

export const downloadTemplate = () => {
  return Axios.get('/mall/copyRight/download/guarantee', {
    responseType: 'blob'
  })
}

export const copyRightApplication = (params: any) => {
  return Axios.post('/mall/copyRight/insert', params)
}

export const updateCopyright = (params: any) => {
  return Axios.post('/mall/copyRight/update', params)
}

export const getCopyRightApplicationDetail = (params: any) => {
  return Axios.post('/mall/copyRight/detail', params)
}

export const getOrderInfo = (params: any) => {
  return Axios.post('/mall/order/createPayOrder', params)
}

export const getProductList = () => {
  return Axios.get('/mall/order/product/copyright')
}

export const getDistributorProductList = () => {
  return Axios.get('/mall/order/product/distributor')
}

export const getPayChannel = (params: any) => {
  return Axios.post('/mall/order/payTypes', params)
}

export const getOrderStatus = (params: any) => {
  return Axios.post('/mall/order/getOrderStatus ', params)
}

export const getCopyrightPage = (params: any) => {
  return Axios.post('/mall/copyRight/page', params)
}

export const updatePassword = (params: any) => {
  return Axios.post('/mall/user/update/pwd', params)
}

export const updateEmail = (params: any) => {
  return Axios.post('/mall/user/update/mail', params)
}

export const updateAvatar = (params: any) => {
  return Axios.post('/mall/user/update/avatar', params)
}

export const getCouponPage = (params: any) => {
  return Axios.post('/mall/user/coupon', params)
}

export const getRegionTree = (params: any) => {
  return Axios.post(`/mall/copyRight/region?regionId=${params.id || params.parentId}`, params)
}

export const commitCopyRightAgain = (params: any) => {
  return Axios.post('/mall/copyRight/register/update', params)
}

export const getPreViewCertificateImg = (params: any) => {
  return Axios.post('/mall/copyRight/preview/pic', params)
}

export const getCopyRightPayStatus = (params: any) => {
  return Axios.post('/mall/copyRight/apply/payStatus', params)
}

export const getAllPoster = () => {
  return Axios.get('/mall/user/poster/system')
}

export const downloadFile = (params: any) => {
  return Axios.post('/mall/user/download/file', params, {
    timeout: 30 * 60 * 1000
  })
}

export const getUserWallet = () => {
  return Axios.get('/mall/user/prePayment')
}

export const getPaySmsCode = (params: any) => {
  return Axios.post('/mall/login/sendWalletSmsCode', params)
}
