import { createSlice } from '@reduxjs/toolkit'

export interface nationInfoStateType {
  nationList: any[]
}

export const nationInfoSlice = createSlice({
  name: 'user-info',
  initialState: {
    nationList: []
  },
  reducers: {
    updateNationInfo: (state, { payload }) => {
      state.nationList = payload.nationList
    },

  }
})

export const { updateNationInfo } = nationInfoSlice.actions

export default nationInfoSlice.reducer
