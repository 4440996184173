import { Button, Space } from 'antd'

import AppDarkPageContainer from '../../components/AppDarkPageContainer'

import Styles from './index.module.scss'
import router from '../../routers'
import { useSearchParams } from 'react-router-dom'

function PaySuccess () {
  const [searchParams] = useSearchParams()
  const titleText = searchParams.get('titleText')

  const navigatorToSelfCenter = () => {
    router.navigate('/self-center/copyright-registration-management')
  }

  const handleBack = () => {
    router.navigate('/copyright-service')
  }

  return (
    <AppDarkPageContainer>
      <div className={ 'd-flex align-items-center justify-content-center' }>
        <div className={ Styles.pay_success_wrap }>
          <div className={ Styles.pay_success_title }>{ titleText || '支付成功' }</div>
          <div className={ Styles.pay_success_description }>可在个人中心下查询具体申请进度</div>
          <Space>
            <Button
              className={'mt-4'}
              type={'primary'}
              shape={'round'}
              onClick={ navigatorToSelfCenter }
            >个人中心</Button>
            <Button
              className={'mt-4'}
              type={'primary'}
              shape={'round'}
              ghost
              onClick={ handleBack }
            >返回首页</Button>
          </Space>
        </div>
      </div>
    </AppDarkPageContainer>
  )
}

export default PaySuccess
