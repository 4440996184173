import { ReactElement, ReactNode, useState } from 'react'
import { Button, Col, Image, Modal, Progress, Row, Upload } from 'antd'
import { UploadFile, UploadProps } from 'antd/es/upload/interface'
import { DownloadOutlined, EyeOutlined, FileTextOutlined, UploadOutlined } from '@ant-design/icons'

import { ReactComponent as Delete } from '../../assets/icons/delete.svg'

import Styles from './index.module.scss'
import { UploadChangeParam } from 'antd/es/upload'
import { BASE_URL, FILE_URL } from '../../common/constant'
import { UPLOAD_FILE_URL } from '../../common/request'
import { getFileFormat } from '../../plugins/units'

type Props = {
  value?: UploadFile[] | undefined
  fileTypeList?: string[]
  children?: ReactNode
  maxCount?: number
  onChange?: any
  content?: any
}

function UploadFileFormItem ({ value, content, maxCount, fileTypeList = [], children, onChange }: Props) {
  const fileSuffixValidate = (list: string[], fileNameSuffix: string) => {
    return list.map(item => {
      return item.replace('image/', '')
        .replace('application/', '')
        .replace('x-', '')
        .replace('-compressed', '')
    }).includes(fileNameSuffix)
  }

  const beforeUpload = (file: File) => {
    if (fileTypeList.length > 0) {
      const fileTypeIsRight = fileTypeList.includes(file.type)
      const arr = file.name.split('.')
      const fileNameSuffix = arr[arr.length - 1]

      if (!fileTypeIsRight && !fileSuffixValidate(fileTypeList, fileNameSuffix)) {
        Modal.error({
          title: '错误',
          content: `文件格式错误，仅支持 ${ getFileFormat(fileTypeList) } 格式`
        })
        return false
      }
    }

    if (file.size > 500 * 1024 * 1024) {
      Modal.error({
        title: '错误',
        content: '文件大小不符合标准，文件大小不能超过500MB'
      })
      return false
    }

    return file
  }

  const handleFileChange: UploadProps['onChange'] = async (info: UploadChangeParam<UploadFile>) => {
    if (info.file.status === 'error') {
      onChange?.(info.fileList.filter(item => {
        return item.uid !== info.file.uid
      }))
      Modal.error({
        title: '错误',
        content: `文件【${ info.file.name }】上传失败`
      })
    } else {
      onChange?.(info.fileList.map(item => {
        return {
          status: item.status,
          uid: item.uid,
          name: item.name,
          url: item.response?.data,
          response: item.response
        }
      }))
    }
  }

  const handleDelete = (file: UploadFile) => {
    onChange?.(value?.filter(fileItem => {
      return fileItem.uid !== file.uid
    }))
  }

  const generateFileViewAble = (type: string, fileUrl: string | undefined) => {
    let html: ReactNode = ''
    switch (type) {
      case 'png':
      case 'jpg':
      case 'jpeg':
        html = <PreviewPicture imgUrl={ fileUrl ? `${ FILE_URL }${ fileUrl }` : '' }></PreviewPicture>
        break
      case 'pdf':
      case 'zip':
      case 'rar':
      case '7z':
        html = <DownloadOutlined
          style={ { fontSize: 14, width: 16, height: 16 } }
          onClick={ () => {
            window.open(`${ FILE_URL }${ fileUrl }`)
          } }
        />
        break
      default:
    }

    return html
  }

  const PreviewPicture = ({ imgUrl }: { imgUrl: string }) => {
    const [visible, setVisible] = useState(false)

    return (<>
      <EyeOutlined
        style={ { fontSize: 14, width: 16, height: 16 } }
        onClick={ () => setVisible(true) }
      />
      <Image
        style={ {
          display: 'none'
        } }
        src=""
        preview={ {
          visible,
          src: imgUrl,
          onVisibleChange: (value) => {
            setVisible(value)
          }
        } }
      />
    </>)
  }

  const generateFileItem = (file: UploadFile): ReactNode => {
    let content

    switch (file.status) {
      case 'uploading':
        content = (
          <div className={ [Styles.file_list_item, Styles.uploading].join(' ') }>
            <div className={ 'd-flex align-items-center flex-grow-1' }>
              <FileTextOutlined className={ 'mr-2' }/>
              <span>{ file.name }</span>
              <Progress className={ 'm-0 pl-2 pr-4' } percent={ file.percent } showInfo={ false }/>
            </div>
            <Button type={ 'link' } onClick={ handleDelete.bind(this, file) }>
              <Delete/>
            </Button>
          </div>
        )
        break
      case 'done':
        const lastDotIndex = file.name.lastIndexOf('.')
        const fileType = file.name.slice(lastDotIndex + 1)

        content = (
          <div className={ [Styles.file_list_item, Styles.success].join(' ') }>
            <div className={ 'd-flex align-items-center' }>
              <FileTextOutlined className={ 'mr-2' }/>
              <span className={ 'text-blue mr-2' }>{ file.name }</span>
              {
                generateFileViewAble(fileType, file.url)
              }
            </div>
            <Button type={ 'link' } onClick={ handleDelete.bind(this, file) }>
              <Delete/>
            </Button>
          </div>
        )
        break
      case 'error':
        content = (
          <div className={ [Styles.file_list_item, Styles.error].join(' ') }>
            <div className={ 'd-flex align-items-center' }>
              <FileTextOutlined className={ 'mr-2' }/>
              <span className={ 'text-danger' }>{ file.name }</span>
            </div>
            <Button type={ 'link' } onClick={ handleDelete.bind(this, file) }>
              <Delete/>
            </Button>
          </div>
        )
        break

    }

    return content
  }

  return (
    <Row gutter={ 30 }>
      <Col span={ 12 }>
        <Upload
          accept={ fileTypeList?.join(', ') }
          fileList={ value }
          action={ `${ BASE_URL }${ UPLOAD_FILE_URL }` }
          headers={ {
            authorization: localStorage.getItem('access_token') || ''
          } }
          maxCount={ maxCount }
          beforeUpload={ beforeUpload }
          itemRender={ (originNode: ReactElement, file: UploadFile): ReactNode => {
            return generateFileItem(file)
          } }
          onChange={ handleFileChange }
        >
          {
            content
              ? content
              : <Button type={ 'primary' } ghost className={ 'mr-2' } icon={ <UploadOutlined/> }>上传文件</Button>
          }
        </Upload>
      </Col>
      <Col span={ 12 }>
        { children }
      </Col>
    </Row>
  )
}

export default UploadFileFormItem
