import AppDarkPageContainer from '../../components/AppDarkPageContainer'

function CopyrightInspection () {


  return (<AppDarkPageContainer>
    <div className={ 'px-4' }>

    </div>
  </AppDarkPageContainer>)
}

export default CopyrightInspection
