import { Image, Tabs } from 'antd'
import type { TabsProps } from 'antd'
import { useState } from 'react'
import Styles from './index.module.scss'
import LoginTabs from './components/LoginTabs'
import RegisterTabs from './components/RegisterTabs'
import RetrievePassword from './components/RetrievePassword'
import PageFooter from '../../components/PageFooter'
import HeaderLogo from '../../assets/images/header-logo.png'

function Index () {
  const [activeKey, setActiveKey] = useState('login')
  const handleSetActive = (key: string)=>{
    setActiveKey(key)
  }
  const items: TabsProps['items'] = [
    {
      key: 'login',
      label: '登录',
      children: (<>
        <LoginTabs></LoginTabs>
      </>)
    },
    {
      key: 'register',
      label: '注册',
      children: (<>
        <RegisterTabs></RegisterTabs>
      </>)
    },
    {
      key: 'retrievePassword',
      label: '找回密码',
      children: (<RetrievePassword onSetActive={handleSetActive}></RetrievePassword>)
    }
  ]

  return (
    <div className={ Styles.login_page }>
      <div className={ Styles.login_bg_container }>
        <Image className={'ml-5 mt-3'} preview={ false } src={ HeaderLogo } width={ 220 } height={100}/>
        <div className={ Styles.login_footer_logo }>
          <PageFooter theme={ 'dark' }></PageFooter>
        </div>
      </div>
      <div className={ Styles.login_control_container }>
        <div>
          <div>
            <div className={ Styles.welcome_text_1 }>HI</div>
            <div className={ Styles.welcome_text_2 }>欢迎您登录</div>
          </div>
          <div className={ Styles.login_wrap }>
            <Tabs
              className={ 'login-tabs' }
              tabBarGutter={ 90 }
              activeKey={ activeKey }
              onChange={ (key) => setActiveKey(key) }
              size={ 'large' }
              items={ items }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default Index
