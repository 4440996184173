import { useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Divider, Modal, notification, Space, Table, Tabs } from 'antd'

import { deleteIdentify, identifyList } from '../../../../common/request'
import { ResponseCode } from '../../../../common/response-code'
import EditIdentifyModal, { ModalRefProps } from '../../../../components/EditIdentifyModal'
import EditCompanyIdentifyModal, { EditCompanyIdentifyRefProps } from './EditCompanyIdentifyModal'

type ModalType = 'create' | 'edit'

function IdentityList ({ authType = '1' }) {
  const editIdentifyModalRef = useRef<ModalRefProps>(null)
  const editCompanyIdentifyModalRef = useRef<EditCompanyIdentifyRefProps>(null)
  const [currentPage, setCurrentPage] = useState<number>(1)
  const [pageSize, setPageSize] = useState<number>(10)
  const userInfo = useSelector((state: any) => state.userInfo.userInfo)
  const [identifyInfo, setIdentifyInfo] = useState<any>({ records: [] })
  const [modalType, setModalType] = useState<ModalType>('create')
  const [currentEditItem, setCurrentEditItem] = useState<any>(null)
  const [loading, setLoading] = useState(false)

  const columns = [
    {
      title: '姓名',
      key: 'realName',
      dataIndex: 'realName'
    },
    {
      title: '身份证',
      key: 'idCard',
      dataIndex: 'idCard'
    },
    {
      title: '公司名称',
      key: 'companyName',
      dataIndex: 'companyName'
    },
    {
      title: '营业执照号',
      key: 'busLicNum',
      dataIndex: 'busLicNum'
    },
    {
      title: '用户ID',
      key: 'id',
      dataIndex: 'id'
    },
    {
      width: 140,
      title: '操作',
      key: 'operation',
      dataIndex: 'operation',
      render: (record: any, row: any) => {
        return (<Space className={ 'd-flex justify-content-end' }>
          <Button
            type={ 'link' }
            onClick={ showEditIdentifyModal.bind(this, row) }
          >编辑</Button>
          <Button
            type={ 'link' }
            danger
            onClick={ showDeleteIdentifyModal.bind(this, row) }
          >删除</Button>
        </Space>)
      }
    }
  ]

  useEffect(() => {
    getIdentifyListAction()
  }, [currentPage, pageSize])

  const getIdentifyListAction = () => {
    const params = {
      current: currentPage,
      size: pageSize,
      userId: userInfo.id,
      authType: authType
    }
    setLoading(true)

    identifyList(params).then((res: any) => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        setIdentifyInfo(res.data)
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch((err: any) => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，查询作者列表失败'
      })
    })
  }

  const showEditIdentifyModal = (editItem: any) => {
    setModalType('edit')
    setCurrentEditItem(editItem)
    if (authType === '1') {
      editIdentifyModalRef.current?.showModal(editItem)
    } else {
      editCompanyIdentifyModalRef.current?.showModal(editItem)
    }
  }

  const showIdentifyModal = () => {
    setModalType('create')
    if (authType === '1') {
      editIdentifyModalRef.current?.showModal()
    } else {
      editCompanyIdentifyModalRef.current?.showModal()
    }
  }

  const showDeleteIdentifyModal = (row: any) => {
    Modal.warning({
      title: '删除作者',
      content: '确认要删除作者吗？',
      onOk: () => {
        deleteIdentifyModal(row)
      }
    })
  }

  const deleteIdentifyModal = (row: any) => {
    setLoading(true)
    deleteIdentify({ id: row.id }).then((res: any) => {
      setLoading(false)
      if (res.code === ResponseCode.success) {
        getIdentifyListAction()
        notification.open({
          type: 'success',
          message: '操作成功',
          description: `已删除作者${ row.realName }`
        })
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch((err: any) => {
      setLoading(false)
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，删除作者失败'
      })
    })
  }

  const paginationChange = (pageNum: number, pageSize: number) => {
    setCurrentPage(pageNum)
    setPageSize(pageSize)
  }

  const getColumns = (type: string) => {
    if (type === '1') {
      return columns.filter(item => {
        return item.key !== 'companyName' && item.key !== 'busLicNum'
      })
    } else {
      return columns.filter(item => {
        return item.key !== 'idCard' && item.key !== 'realName'
      })
    }
  }

  return (<div>
    <div>
      <div className={ 'mb-2 d-flex justify-content-end' }>
        <Button
          type={ 'primary' }
          icon={ <PlusOutlined/> }
          onClick={ showIdentifyModal }
        >添加作者</Button>
      </div>
      <Table
        loading={ loading }
        dataSource={ identifyInfo.records }
        columns={ getColumns(authType) }
        rowKey={ record => record.id }
        pagination={ {
          current: currentPage,
          total: identifyInfo.total,
          onChange: paginationChange
        } }
      ></Table>
    </div>

    <EditIdentifyModal
      ref={ editIdentifyModalRef }
      modalType={ modalType }
      userVerifyInfo={ currentEditItem }
      verifySuccess={ getIdentifyListAction }
    ></EditIdentifyModal>
    <EditCompanyIdentifyModal
      ref={ editCompanyIdentifyModalRef }
      modalType={ modalType }
      userVerifyInfo={ currentEditItem }
      verifySuccess={ getIdentifyListAction }
    ></EditCompanyIdentifyModal>
  </div>)
}

export default IdentityList
