import { createBrowserRouter } from 'react-router-dom'

import NetworkError from '../views/NetworkError'
import Login from '../views/Login'
import Home from '../views/Home'
import Preload from '../views/Preload'
import ElectricCopyright from '../views/ElectricCopyright'
import CopyrightService from '../views/CopyrightService'
import ElectricCollection from '../views/ElectricCollection'
import Bulletin from '../views/Bulletin'
import HelpCenter from '../views/HelpCenter'
import CopyrightApplication from '../views/CopyrightApplication'
import PreviewCertificate from '../views/PreviewCertificate'
import PayCost from '../views/PayCost'
import SelfCenter from '../views/SelfCenter'
import CopyrightRegistrationManagement from '../views/SelfCenter/tabs/CopyrightRegistrationManagement'
import ElectricCollectionManagement from '../views/SelfCenter/tabs/ElectricCollectionManagement'
import SelfInfoSetting from '../views/SelfCenter/tabs/SelfInfoSetting'
import CouponManagement from '../views/SelfCenter/tabs/CouponManagement'
import CopyrightInspection from '../views/CopyrightInspection'
import IdentityManagement from '../views/SelfCenter/tabs/IdentityManagement'
import Certification from '../views/SelfCenter/tabs/Certification'
import PaySuccess from '../views/PaySuccess'
import Promotion from '../views/SelfCenter/tabs/Promotion'

const router = createBrowserRouter([
  {
    path: '/',
    element: <Preload />,
    children: [
      {
        path: 'home',
        element: <Home />
      },
      {
        path: 'copyright-service',
        element: <CopyrightService />
      },
      {
        path: 'electric-copyright',
        element: <ElectricCopyright />
      },
      {
        path: 'electric-collection',
        element: <ElectricCollection />
      },
      {
        path: 'bulletin',
        element: <Bulletin />
      },
      {
        path: 'help-center',
        element: <HelpCenter />
      },
      {
        path: 'copyright-application',
        element: <CopyrightApplication />
      },
      {
        path: 'copyright-inspection',
        element: <CopyrightInspection />
      },
      {
        path: 'preview-certificate',
        element: <PreviewCertificate />
      },
      {
        path: 'pay-cost',
        element: <PayCost />
      },
      {
        path: 'pay-success',
        element: <PaySuccess />
      },
      {
        path: 'self-center',
        element: <SelfCenter />,
        children: [
          {
            path: 'copyright-registration-management',
            element: <CopyrightRegistrationManagement />
          },
          {
            path: 'electric-collection-management',
            element: <ElectricCollectionManagement />
          },
          {
            path: 'coupon-management',
            element: <CouponManagement />
          },
          {
            path: 'self-info-setting',
            element: <SelfInfoSetting />
          },
          {
            path: 'identity-management',
            element: <IdentityManagement />
          },
          {
            path: 'certification',
            element: <Certification />
          },
          {
            path: 'promotion',
            element: <Promotion />
          }
        ]
      }
    ]
  },
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/network-error',
    element: <NetworkError />
  }
])

export default router
