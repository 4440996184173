import Styles from '../CopyrightService/index.module.scss'
import { Button, Divider, Image } from 'antd'
import RegisterProcessImg from '../../assets/images/register-process.png'
import PageContainer from '../../components/AppPageContainer'

function ElectricCopyright () {


  return (
    <PageContainer>
      <div className={ Styles.copyright_service }>
        <div className={ Styles.page_title }>版权数字藏品服务平台</div>
        <div className={ Styles.page_sub_title }>保护少数民族文化权益</div>
        <div className={ Styles.page_description }>
          <div>接入中国版权局作品线上登记平台，打破传统线下登记弊端</div>
          <div>节约申请成本，提升登记效率，为作品登记提供便捷服务</div>
        </div>
        <div className={ Styles.process_title }>版权登记流程</div>
        <Image className={ Styles.register_process_img } preview={ false } src={ RegisterProcessImg }></Image>

        <div className={ Styles.page_control }>
          <Button className={ 'mr-4 px-4' } type={ 'primary' }>开始版权申请</Button>
          <Button className={ 'px-4 celeste' } type={ 'primary' }>版权查验</Button>
        </div>

        <Divider className={ 'mt-5 mb-4' }/>

        <div className={ Styles.page_footer }>
          <div className={ Styles.qr_code_img }></div>
          <div className={ 'd-flex justify-content-center flex-column' }>
            <div className={ 'font-weight-bold' }>支持PC端、手机端 (微信公众号直接使用)</div>
            <div className={ 'text-gray-light' }>
              <div>
                <span>关注</span>
                <span className={ 'text-dark' }>中国版权数字藏品平台</span>
                <span>微信公众号</span>
              </div>
              <div>开启手机端版权服务体验</div>
            </div>
          </div>
        </div>
      </div>
    </PageContainer>

  )
}

export default ElectricCopyright
