import { Button, Checkbox, Form, Input, Modal, Space } from 'antd'
import { KeyOutlined, LockOutlined, MobileOutlined, UserOutlined } from '@ant-design/icons'

import { confirmPasswordValidate, passwordValidate, phoneRegExp } from '../../../../plugins/validates'
import VerifyCodeFormItem from '../../../../components/VerifyCodeFormItem'
import Styles from '../../index.module.scss'
import { useEffect, useState } from 'react'
import { register } from '../../../../common/request'
import { ResponseCode } from '../../../../common/response-code'
import { useSearchParams } from 'react-router-dom'

type FormFields = {
  phone: string
  verifyCode: string
  pwd: string
  passwordAgain: string
  userName: string
  inviteCode: string
}

function RegisterTabs () {
  const [form] = Form.useForm()
  const [urlParams] = useSearchParams()
  const [isAgree, setIsAgree] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)

  useEffect(() => {
    if (urlParams.get('inviteCode')) {
      form.setFieldsValue({
        inviteCode: urlParams.get('inviteCode')
      })
    }
  }, [])

  const isAgreeChange = () => {
    setIsAgree(pre => {
      return !pre
    })
  }

  const viewRegisterModal = () => {
    showModal()
  }

  const showModal = () => {
    setModalVisible(true)
  }

  const hideModal = () => {
    setModalVisible(false)
  }

  const agreeProtocol = () => {
    setIsAgree(true)
    hideModal()
  }

  const validateFields = () => {
    if (!isAgree) {
      showModal()
      return
    }

    form.validateFields().then(fields => {
      registerAction(fields)
    }).catch(err => {
      console.error(err)
    })
  }

  const registerAction = (fields: FormFields) => {
    register(fields).then((res: any) => {
      if (res.code === ResponseCode.success) {
        Modal.success({
          title: '注册成功',
          content: '账号注册成功！'
        })
        form.resetFields()
      } else {
        Modal.error({
          title: '错误',
          content: res.msg
        })
      }
    }).catch((err: any) => {
      console.error(err)
      Modal.error({
        title: '错误',
        content: '网络错误，注册账号失败'
      })
    })
  }

  return (
    <div className={ Styles.form_wrap }>
      <Form
        className={ 'login-form' }
        form={ form }
      >
        <Form.Item name={ 'phone' } label={'手机号码'} rules={ [{ required: true, message: '手机号不能为空' }] }>
          <Input
            className={ Styles.login_input }
            placeholder="请输入您的手机号(必填)"
            prefix={ <MobileOutlined/> }
          />
        </Form.Item>
        <Form.Item
          noStyle
          shouldUpdate={ (prevValues, currentValues) => prevValues.phone !== currentValues.phone }
        >
          {
            ({ getFieldValue }) => {
              const phone = getFieldValue('phone')
              return phoneRegExp.test(phone) ? (
                <Form.Item name={ 'verifyCode' } label={'验证码'} rules={ [{ required: true, message: '验证码不能为空' }] }>
                  <VerifyCodeFormItem phone={ phone }></VerifyCodeFormItem>
                </Form.Item>
              ) : (
                <Form.Item name={ 'verifyCode' } label={'验证码'} rules={ [{ required: true, message: '验证码不能为空' }] }>
                  <Space.Compact style={ { width: '100%' } }>
                    <Input
                      disabled
                      className={ Styles.login_input }
                      placeholder="请输入验证码(必填)"
                    />
                    <Button className={ Styles.login_sms_code_button } disabled type={ 'primary' }>获取验证码</Button>
                  </Space.Compact>
                </Form.Item>
              )
            }
          }
        </Form.Item>
        <Form.Item name={ 'pwd' } label={'设置密码'} rules={ [{ required: true, message: '密码不能为空' }, { validator: passwordValidate }] }>
          <Input
            type={ 'password' }
            className={ Styles.login_input }
            prefix={ <LockOutlined/> }
            placeholder="请输入密码(必填)"
          />
        </Form.Item>
        <Form.Item
          name={ 'passwordAgain' }
          label={'确认密码'}
          dependencies={ ['pwd'] }
          rules={ [
            { required: true, message: '密码不能为空' },
            confirmPasswordValidate.bind(this, 'pwd')
          ] }
        >
          <Input
            type={ 'password' }
            prefix={ <LockOutlined/> }
            className={ Styles.login_input }
            placeholder="请再次输入密码(必填)"
          />
        </Form.Item>
        <Form.Item name={ 'userName' } label={ '真实姓名' } rules={ [{ required: true, message: '姓名不能为空' }] }>
          <Input
            className={ Styles.login_input }
            placeholder="请输入真实姓名(必填)"
            prefix={ <UserOutlined/> }
          />
        </Form.Item>
        <Form.Item name={ 'inviteCode' } label={'推荐人ID'}>
          <Input
            className={ Styles.login_input }
            placeholder="请输入推荐码(选填)"
            prefix={ <KeyOutlined/> }
          />
        </Form.Item>
      </Form>

      <div>
        <Checkbox checked={ isAgree } onChange={ isAgreeChange }>
          <span>我已阅读</span>
          <Button type={ 'link' } onClick={ viewRegisterModal }>《注册协议》</Button>
        </Checkbox>
      </div>

      <Button
        className={ Styles.form_button }
        block
        type={ 'primary' }
        size={ 'large' }
        onClick={ validateFields }
      >注册</Button>

      <Modal
        width={ 800 }
        open={ modalVisible }
        title={ '注册协议' }
        onCancel={ hideModal }
        footer={ [] }
      >
        <div className={ 'mb-2' } style={ { height: 600, overflowY: 'scroll' } }>
          <h2 className={ 'text-center' }>创巢文化知识产权数藏平台用户注册协议</h2>

          <h4 className={ 'mb-1' }>特别提示：</h4>
          <div>您在使用创巢文化知识产权数藏平台（以下简称“平台”）服务之前，应当认真阅读《版权登记及数藏平台用户注册协议》（以下简称“协议”）。请您务必审慎阅读、充分理解各条款内容，特别是免除或者限制责任的条款、法律适用和争议解决条款。如您对协议有任何疑问，可向本平台客服咨询。
            <strong>当您点击“同意”或实际使用平台服务，即表示您已充分阅读、理解并接受本协议的全部内容，并与本平台达成一致，遵守本协议的内容并同意接受本协议条款的约束。阅读本协议的过程中，如果您不同意本协议或其中任何条款约定，您应立即停止使用服务。</strong>
            本协议的全部权利义务以及本协议的最终解释权归“创巢文化知识产权数藏平台”授权运营方--贵州省在铭企业管理咨询有限责任公司。
          </div>
          <h4 className={'mb-1'}>1. 总则</h4>
          <div>1.1. 您点击同意本协议或实际使用平台服务，即视为您同意所有注册协议条款，并成为本平台的正式用户，同时视为您确认自己具有享受本平台服务相应的权利能力和行为能力，能够独立承担法律责任。</div>
          <div>1.2. 如果您在18周岁以下或者是限制（无）行为能力人，您只能在父母或监护人的监护参与下才能使用本平台。如果您代表依法设立并有效存续的单位(包括企业法人和其他组织)，您应当取得该单位合法有效的授权。</div>
          <div>1.3. 本协议内容包括协议正文及所有平台已经发布的或将来可能发布的使用规则，本平台有权单方修订更改本协议的内容，修订更改的“条款”经在本平台公布后生效，您在登录、使用本平台时应当及时关注。如您不接受修订更改后的“条款”，请立即停止使用服务，否则视为您已同意并接受。</div>
          <div><strong>1.4. 您知情并同意，本协议提供的服务内容，可能由与服务提供方合作的第三方提供。您使用第三方服务时，需了解第三方服务的协议内容并遵守相关规定；若您不同意相关服务，可拒绝使用第三方服务。若您继续使用第三方服务，则视为您接受并遵守第三方服务的协议内容。</strong></div>
          <h4 className={'mb-1'}>2. 服务内容</h4>
          <div>2.1. 本平台通过互联网依法为您提供作品存证、版权登记、交易、监测、维权等服务，您在同意本协议及平台其他相关规定的情况下，方有权使用平台的相关服务。</div>
          <div><strong>2.2. 本平台会对您申报上传的作品存证数据及产生的数字藏品进行保管，数据保管期限为三年，保管期限届满后，平台将不再另行通知您续费。如因您逾期未对服务进行续费，则视为您放弃所提交的数据文件，平台有权单方面行使处分行为。（但不得泄漏用户数据）。</strong></div>
          <div>2.3. 您在本平台进行的作品自愿登记工作依照《中华人民共和国著作权法》《中华人民共和国著作权法实施条例》、国家版权局《作品自愿登记试行办法》等有关法律法规进行。</div>
          <div>2.4. 当本平台通过现有的技术手段监测到您作品的被侵权行为，本平台会将监测到的侵权情况告知您。您有权在得知侵权事件后选择是否委托本平台启动维权服务。若您选择不启动本平台提供的维权服务，则本平台此前为您的作品提供的版权存证和可以作为您自行维权时证明权属的佐证；若您选择授权委托本平台对侵害您著作权及与著作权相关权利的行为进行维权，则视为同意平台的委托维权相关规则，并接受本平台就您作品的被侵权进行的各种维权行为及其结果（包括但不限于在本平台见证下您与侵权人签订和解协议，使侵权人以付费使用方式合法使用作品）。</div>
          <div>2.5 本平台提供的版权维权服务，包括但不限于代为发送侵权通知、代为处理侵权链接、代为保存证据、代为签订和解协议等维权处理方式。本平台有权在上述授权范围内自行判断采取何种维权方式，有权将该委托授权转委托于律师或其他有资质的第三方进行。</div>
          <h4 className={'mb-1'}>3. 用户注册及账户管理</h4>
          <div><strong>3.1. 您应遵循诚实、信用原则，在本平台注册和实名认证过程中提供真实、准确、有效及完整的资料。如有变动，应及时更新。如您提供的资料不真实、不准确或不完整的，本平台有权要求您补充，如您不能及时配合，本平台有权中止或终止向您提供部分或全部服务，就此产生的损失本平台不承担任何责任。</strong></div>
          <div>3.2. 注册成功后，平台将根据账号和密码来确认您的身份，您应妥善保管您的账号和密码，以及对通过您的账户实施的行为承担全部责任，因您的授权、默许、过失而取得该账号使用权的人通过该账号所实施的行为均视为您的行为。</div>
          <div>3.3. 如您发现帐号遭他人非法使用，应立即通知本平台。平台有权在接到您的通知时，采取相应措施避免您的损失，前述措施包括但不限于暂时冻结您的账号。因黑客行为或用户的保管疏忽导致账号、密码遭他人非法使用，本平台不承担责任。</div>
          <div><strong>3.4 您可以按照平台规定的流程申请注销您的账号。当您请求注销账号时，您的账号应当不存在任何未了结的义务。账号注销后，本协议自动终止，本平台有权选择不可恢复的清除账号内全部数据或保留账号内的相关信息，任何基于已清除的账号内数据而产生的未了结的义务与本平台无关。</strong></div>
          <div><strong>3.5.您知悉并确认，在提供申请、开通、使用等服务过程中，平台可引入实名认证或身份资质核验（含身份信息核验、企业三要素核验等技术服务）等配套第三方安全服务，并依据自身及第三方服务提供的结果，有权终止或中止部分或全部服务。</strong></div>
          <h4 className={'mb-1'}>4. 用户行为规范</h4>
          <div>4.1.您同意严格遵守法律法规和规章规定，依法遵守以下义务：</div>
          <div>4.1.1不得利用本平台提供的服务进行记录、存储、传输、发布、传播含有如下内容的违法信息：反对宪法所确定的基本原则，煽动抗拒、破坏宪法和法律法规实施的；危害国家安全，泄露国家秘密，颠覆国家政权，破坏国家统一的，煽动推翻社会主义制度的；损害国家荣誉和利益的；歪曲、丑化、亵渎、否定英雄烈士事迹和精神，侵害英雄烈士的姓名、肖像、名誉、荣誉的；宣扬或煽动实施恐怖主义、极端主义及其活动的；煽动民族仇恨、民族歧视、破坏民族团结的言论；破坏国家宗教政策，宣扬邪教和封建迷信的；散布谣言，扰乱经济秩序和社会秩序的；散布淫秽、色情、暴力或者教唆犯罪的；侮辱或者诽谤他人，侵害他人名誉、隐私和其他合法权益的；法律、行政法规禁止的其他内容。</div>
          <div>4.1.2防范和抵制制作、复制、发布含有如下内容的不良信息：侵犯未成年人合法权益的；不当评述自然灾害、重大事故等灾难的；宣扬低俗、庸俗、媚俗内容的；煽动人群歧视、地域歧视等的；标题严重夸张，发表内容与标题严重不符的；违反社会公德行为的；其他对网络生态造成不良影响的内容。</div>
          <div>4.2本协议依据国家相关法律法规规章制定，您亦同意严格遵守以下义务：</div>
          <div>4.2.1不得利用本平台从事窃取商业秘密、窃取个人信息等违法犯罪活动；</div>
          <div>4.2.2不得干扰本平台的正常运转，不得侵入本平台及计算机信息系统；</div>
          <div>4.2.3不得传输或发表任何违法犯罪的、骚扰性的、中伤他人的、辱骂性的、恐吓性的、伤害性的、庸俗的、不文明的等信息资料；</div>
          <div>4.2.4不得教唆他人从事违法违规或本协议、平台规则所禁止的行为；</div>
          <div>4.2.5不得利用在本平台注册的账号进行牟利性经营活动；</div>
          <div>4.2.6不得发布任何侵犯他人个人信息、著作权、商标权等知识产权或合法权利的内容。</div>
          <div>4.3. 您同时承诺不得为他人实施上述内容提供任何便利。</div>
          <div><strong>4.4. 您须对自己在网上的言论和行为承担法律责任，您若在本平台上散布和传播反动、色情或其它违反国家法律的信息，本平台有权终止向您提供服务，并不退还任何款项。本平台的系统记录有可能作为您违反法律的证据。</strong></div>
          <h4>5. 隐私保护</h4>
          <div>5.1. 本平台确保其已采取合理有效的技术措施保护您的账户信息、资料、交易活动的安全。</div>
          <div>5.1.1采用符合业界标准的安全防护措施保护您提供的个人信息，防止数据遭到未经授权访问、公开披露、使用、修改、损坏或丢失。平台会采取一切合理可行的措施，保护您的个人信息。</div>
          <div>5.1.2网络服务采取了多种加密技术，例如：通过网络安全层软件（SSL）进行加密传输、重要信息加密存储、严格限制数据中心的访问。</div>
          <div>5.1.3采取合理可行的措施，确保未收集无关的个人信息。平台只会在达成本政策所述目的所需的期限内保留您的个人信息，除非需要延长保留期或受到法律的允许。</div>
          <div>5.1.4互联网环境并非百分之百安全，平台将尽力确保或担保您发送给平台的任何信息的安全性。如果本平台的物理、技术、或管理防护设施遭到破坏，导致信息被非授权访问、公开披露、篡改、或毁坏，导致您的合法权益受损，我们将承担相应的法律责任。</div>
          <div>5.1.5在不幸发生个人信息安全事件后，本平台将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。平台将及时将事件的情况以邮件、信函、电话、推送通知等方式告知您。同时，平台还将按照监管部门要求，主动上报个人信息安全事件的处置情况。</div>
          <div>5.2. 本平台承诺对您的账户信息、资料、交易活动负有保密义务，但下列情况除外：</div>
          <div>5.2.1. 事先获得您的明确授权；</div>
          <div>5.2.2. 根据有关的法律法规要求；</div>
          <div>5.2.3. 根据司法机关或政府主管部门的要求；</div>
          <div>5.2.4. 为提供您所要求的产品和服务，而必须和第三方分享您的个人信息；</div>
          <div>5.2.5为维护社会公众的利益。</div>
          <div>5.3 您理解并认可，鉴于区块链技术自身属性，您的上链数据将会同步存储于相关区块链各个节点，相关区块链上任何节点对您的上链数据的存储不构成任何违反信息安全或保密义务的情形。</div>
          <h4>6. 知识产权及数字藏品保护</h4>
          <div>6.1. 本平台中包含的任何文字、图片、音频、视频等信息或材料均受著作权法、商标法和其他法律法规的保护，未经相关权利人书面同意，您不得以任何方式使用该信息或材料。</div>
          <div>6.2. 您应保证对通过本平台进行版权登记的一切作品均合法享有完整的知识产权，或者已经得到相关权利人的合法授权，因违反本条规定而造成第三方争议、索赔、赔偿、诉讼的，由您自行承担责任，与本平台无涉。因违反本条规定而造成第三方向本平台索赔的，您应承担本平台因此造成的一切损失（包括但不限于损失赔偿、诉讼代理费以及其他相关合理支出）。</div>
          <div>6.3. 当第三方认为您进行著作权授权的作品侵犯其权利，并根据《信息网络传播权保护条例》或者相关法律规定向本站发送权利通知书时，您同意本平台可以自行判断决定屏蔽、删除涉嫌侵权作品，除非您提交书面证据材料排除侵权的可能性，否则不会自动恢复上述删除内容。</div>
          <h4>7. 协议的终止</h4>
          <div>7.1. 您有权通过以下任一方式终止本协议：</div>
          <div>7.1.1. 在满足本平台公示的账户注销条件时您按平台规定的流程申请注销您的账户的；</div>
          <div>7.1.2. 变更事项生效前您停止使用并明示不愿接受变更事项的；</div>
          <div>7.1.3. 您已明示不愿继续使用本平台服务，且符合本平台规定的终止条件的。</div>
          <div>7.2. 出现以下情况时，本平台可以通知您终止本协议：</div>
          <div>7.2.1. 您违反本协议约定，依据违约条款终止本协议的；</div>
          <div>7.2.2. 您盗用他人账户、发布违禁信息、骗取他人财物、采取不正当手段谋利等行为，依据本平台规则对您的账户予以查封的；</div>
          <div>7.2.3. 除上述情形外，因您多次违反本平台的相关规定且情节严重，本平台对您的账户予以查封的；</div>
          <div>7.2.4. 您的账户依据本协议回收的；</div>
          <div>7.2.5. 其它应当终止服务的情况。</div>
          <h4>8.免责声明</h4>
          <div>8.1. 除非另有法律规定或明确的书面说明，本平台不对在本平台所发布的内容等作任何形式的、明示或默示的声明或担保。</div>
          <div>8.2. 如因不可抗力（包括但不限于地震、台风、火灾、水灾、战争、瘟疫、社会动乱等因素）或其它本平台无法控制的原因使本平台系统崩溃或无法正常使用导致网上交易无法完成或丢失有关的信息、记录等，本平台不承担任何责任，但本平台会合理地尽力协助处理善后事宜。</div>
          <div>8.3. 本平台对您在本平台中所使用的所有个人信息尽最大限度地保密义务，对于超出本平台管控能力限度之外的泄露个人信息的事件或行为，本平台不承担责任。</div>
          <div>8.4. 如您在本平台中获得的利益需要依法缴税的，您应自行向相关部门申报缴纳，如未依法申报缴纳的，本平台保留代扣代缴的权利。</div>
          <div>8.5. 因黑客行为或您的保管疏忽导致用户账户被非法使用的，本平台不承担任何责任。</div>
          <div>8.6. 为更好的提供服务，平台会定期或不定期的对平台进行功能升级并提前通过公告形式告知您，在此情形下，平台出现短时间服务中断，平台不承担责任。由于互联网上的通路阻塞或由第三方造成的（如网络大面积中断）平台无法提供服务，平台亦不承担责任。</div>
          <div>8.7. 本平台竭力为您提供良好的侵权监测服务，但受限于现有技术手段及客观条件，本平台不保证能够监测到所有对您作品侵权的行为，发生本平台未监测到的对您作品侵权的行为，本平台不承担任何责任。</div>
          <div>8.8. 本平台只对用户使用存证服务的内容（电子数据HASH值、用户主体信息、时间等）做客观记录，并确保电子数据存入后不可篡改并得到相关区块链节点的多方见证。平台及相关区块链各个节点不对存证内容的真实性、合法性、原创性等作出任何确认、认定和保证。</div>
          <h4>9. 违约处理</h4>
          <div>9.1. 如您在本平台发布的作品构成违约，本平台可根据相应规则立即对相应作品进行删除、屏蔽处理或对相关作品进行下架、监管。</div>
          <div>9.2. 如您在本平台实施的相关行为，或虽未在本平台实施但对本平台及本平台用户产生影响的相关行为构成违约的，本平台可依据相应规则对您执行账户扣分、中止向您提供部分或全部服务、划扣违约金等处理措施。如您的行为构成根本违约的，本平台可终止向您提供服务。</div>
          <h4>10. 通知与送达</h4>
          <div>10.1. 您在注册成为本平台用户，并接受本平台服务时，应该向本平台提供真实有效的联系方式（包括您的电子邮件地址、联系电话、联系地址等），对于联系方式发生变更的，您有义务及时更新有关信息，并保持可被联系的状态。</div>
          <div>10.2. 您在注册本平台时生成的用于登录本平台接收站内信、系统消息的会员账号（包括子账号），也作为您的有效联系方式。</div>
          <h4>11. 赔偿责任</h4>
          <div>11.1. 如您的行为使本平台或其关联公司遭受损失（包括自身的直接经济损失、商誉损失及对外支付的赔偿金、和解款、律师费、诉讼费等间接经济损失），您应赔偿本平台或其关联公司的上述全部损失。</div>
          <div>11.2. 如您的行为使本平台或其关联公司遭受第三人主张权利，本平台或其关联公司可在对第三人承担金钱给付等义务后就全部损失向您追偿</div>
          <h4>12. 法律适用及争议解决</h4>
          <div>12.1. 本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用中华人民共和国大陆地区法律；如法律无相关规定的，参照商业惯例或行业惯例。</div>
          <div>12.2. 您因使用本平台服务所产生及与本平台平台服务有关的争议，由本平台与您协商解决。协商不成时，任何一方均可向深圳市福田法院提起诉讼。</div>
        </div>
        <div className={ 'text-center' }>
          <Button
            className={ 'px-4' }
            type={ 'primary' }
            shape={ 'round' }
            onClick={ agreeProtocol }
          >确定</Button>
        </div>
      </Modal>
    </div>
  )
}

export default RegisterTabs
